import { Paper } from "@mui/material";
import React from "react";

export const PaperSection = ({ children, className = "", ...props }) => {
  return (
    <Paper
      elevation={4}
      className={`p-2 !rounded-lg overflow-hidden max-w-full ${className}`}
      {...props}
    >
      {children}
    </Paper>
  );
};
