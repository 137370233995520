import { Link } from "react-router-dom";
import { useContext } from "react";
import { userContext } from "../../../services/userContext";

const DemoRequestFormPhysical = ({
  handleInputChange,
  handleSubmit,
  formDataPhysical,
  checkedTerms,
  setCheckedTerms,
  checkedPolicy,
  setCheckedPolicy,
}) => {
  const { lang } = useContext(userContext);

  const handleInputTypeFileTrigger = () => {
    let fileChosen = false;
    const fileUpload = document.getElementById("file-upload");
    fileUpload.addEventListener("change", function (event) {
      fileChosen = true;
    });
    document.getElementById("file-upload").click();
  };

  return (
    <div className={`2xl:mt-[2rem]   mx-auto 3xl:mt-[5rem] w-full text-center`}>
      <h1 className={`text-center uppercase font-bold text-[19px] underline`}>
        Zahtev za DEMO nalog -{" "}
        <span className={`text-[#aa1d23]`}> Fizičko lice</span>
      </h1>
      <p className={`mt-[40px] font-medium text-base text-black`}>
        Ukoliko ste zainteresovani za besplatnu demo verziju portala, potrebno
        je da popunite sledeći formular:
      </p>
      <div
        className={`mt-[13px] 3xl:mt-[30px] mx-auto px-6 rounded-2xl bg-[#232f3e] pb-8 w-[80%] 2xl:w-[64%]`}
      >
        <h1 className={`text-white pt-4 underline text-base text-center`}>
          Opšte informacije o podnosiocu zahteva:
        </h1>
        <div
          className={`mt-[30px] grid grid-cols-2 gap-x-[5rem] 3xl:gap-x-[7rem]`}
        >
          <div
            className={` col-span-1 separate3 relative grid grid-cols-2 2xl:gap-y-1 3xl:gap-y-5 gap-x-[2rem] 3xl:gap-x-[3.25rem]`}
          >
            <div className={`col-span-2  text-left w-full gap-2`}>
              <label
                htmlFor={`name`}
                className={`text-xs 3xl:text-base text-white font-medium`}
              >
                Ime i prezime podnosioca zahteva:
              </label>
              <input
                type={`text`}
                id={`name`}
                value={formDataPhysical.name}
                name={`name`}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                className={`rounded-xl focus:border-[#aa1d23] focus:outline-0 focus:ring-0 ml-2 bg-white/30 text-white h-[34px] 3xl:h-[40px] 3xl:text-base text-xs px-2 w-full`}
              />
            </div>
            <div className={`col-span-2 text-left w-full gap-2`}>
              <label
                htmlFor={`email`}
                className={`text-xs 3xl:text-base text-white font-medium`}
              >
                E-mail adresa:
              </label>
              <input
                name={`email`}
                type={`text`}
                value={formDataPhysical.email}
                id={`email`}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                className={`rounded-xl focus:border-[#aa1d23] focus:outline-0 focus:ring-0 ml-2 bg-white/30 text-white h-[34px] 3xl:h-[40px] 3xl:text-base text-xs px-2 w-full`}
              />
            </div>
            <div className={`col-span-1  text-left w-full gap-2`}>
              <label
                htmlFor={`city`}
                className={`text-xs 3xl:text-base text-white font-medium`}
              >
                Grad:{" "}
              </label>
              <input
                name={`city_name`}
                type={`text`}
                value={formDataPhysical.city_name}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                id={`city`}
                className={`rounded-xl focus:border-[#aa1d23] focus:outline-0 focus:ring-0 ml-2 bg-white/30 text-white h-[34px] 3xl:h-[40px] 3xl:text-base text-xs px-2 w-full`}
              />
            </div>
            <div className={`col-span-1  text-left w-full gap-2`}>
              <label
                htmlFor={`zipcode`}
                className={`text-xs 3xl:text-base text-white font-medium`}
              >
                Poštanski broj:{" "}
              </label>
              <input
                name={`postal_code`}
                type={`text`}
                value={formDataPhysical.postal_code}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                id={`zipcode`}
                className={`rounded-xl focus:border-[#aa1d23] focus:outline-0 focus:ring-0 ml-2 bg-white/30 text-white h-[34px] 3xl:h-[40px] 3xl:text-base text-xs px-2 w-full`}
              />
            </div>
            <div className={`col-span-1  text-left w-full gap-2`}>
              <label
                htmlFor={`zipcode`}
                className={`text-xs 3xl:text-base text-white font-medium`}
              >
                Izbor verzije demo naloga:{" "}
              </label>
              <select
                id={`type`}
                value={formDataPhysical.demo_account}
                name={`demo_account`}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                className={`rounded-xl text-sm focus:border-[#aa1d23] focus:outline-0 focus:ring-0 ml-2 bg-white/30 text-white h-[34px] 3xl:h-[40px] 3xl:text-base text-xs px-2 w-full`}
              >
                <option className={`text-black`} value={`none`}>
                  Izaberi verziju
                </option>
                <option className={`text-black`} value={`1`}>
                  Verzija 1
                </option>
              </select>
            </div>
          </div>
          <div
            className={`col-span-1 place-items-start grid grid-cols-2 gap-y-5 gap-x-[3.25rem]`}
          >
            <div className={`col-span-1  text-left w-full gap-2`}>
              <label
                htmlFor={`birthday`}
                className={`text-xs 3xl:text-base text-white font-medium`}
              >
                Datum rođenja:
              </label>
              <input
                type={`date`}
                value={formDataPhysical.birthday}
                name={`birthday`}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                id={`birthday`}
                className={`rounded-xl focus:border-[#aa1d23] focus:outline-0 focus:ring-0 ml-2 bg-white/30 text-white h-[34px] 3xl:h-[40px] 3xl:text-base text-xs px-2 w-full`}
              />
            </div>
            <div className={`col-span-1  text-left w-full gap-2`}>
              <label
                htmlFor={`gender`}
                className={`text-xs 3xl:text-base text-white font-medium`}
              >
                Pol:{" "}
              </label>
              <select
                name={`gender`}
                value={formDataPhysical?.gender}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                id={`gender`}
                className={`rounded-xl text-sm focus:border-[#aa1d23] focus:outline-0 focus:ring-0 ml-2 bg-white/30 text-white h-[34px] 3xl:h-[40px] 3xl:text-base text-xs px-2 w-full`}
              >
                <option className={`text-black`} value={`none`}>
                  Izaberi
                </option>
                <option className={`text-black`} value={`1`}>
                  Muški
                </option>
                <option className={`text-black`} value={`2`}>
                  Ženski
                </option>
              </select>
            </div>
            <div className={`col-span-1  text-left w-full gap-2`}>
              <label
                htmlFor={`jmbg`}
                className={`text-xs 3xl:text-base text-white font-medium`}
              >
                JMBG:{" "}
              </label>
              <input
                type={`text`}
                value={formDataPhysical.jmbg}
                name={`jmbg`}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                id={`jmbg`}
                className={`rounded-xl focus:border-[#aa1d23] focus:outline-0 focus:ring-0 ml-2 bg-white/30 text-white h-[34px] 3xl:h-[40px] 3xl:text-base text-xs px-2 w-full`}
              />
            </div>
            <div className={`col-span-1  text-left w-full gap-2`}>
              <label
                htmlFor={`mobile`}
                name={`mobile`}
                className={`text-xs 3xl:text-base text-white font-medium`}
              >
                Mobilni telefon:{" "}
              </label>
              <input
                type={`text`}
                value={formDataPhysical.mobile}
                id={`mobile`}
                name={`mobile`}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                className={`rounded-xl focus:border-[#aa1d23] focus:outline-0 focus:ring-0 ml-2 bg-white/30 text-white h-[34px] 3xl:h-[40px] 3xl:text-base text-xs px-2 w-full`}
              />
            </div>
            <div className={`col-span-1  text-left w-full gap-2`}>
              <label
                htmlFor={`documentnumber`}
                className={`text-xs 3xl:text-base text-white font-medium`}
              >
                Broj dokumenta:{" "}
              </label>
              <input
                type={`text`}
                name={`document_number`}
                value={formDataPhysical.document_number}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                id={`documentnumber`}
                className={`rounded-xl focus:border-[#aa1d23] focus:outline-0 focus:ring-0 ml-2 bg-white/30 text-white h-[34px] 3xl:h-[40px] 3xl:text-base text-xs px-2 w-full`}
              />
            </div>
            <div className={`col-span-1  text-left w-full gap-2`}>
              <label
                htmlFor={`documentnumber`}
                className={`text-xs 3xl:text-base text-white font-medium`}
              >
                Priloži dokument:{" "}
              </label>
              <div className={`relative`}>
                <input
                  type={`file`}
                  name={`document`}
                  id={`file-upload`}
                  className={`rounded-xl focus:border-[#aa1d23] invisible z-0 focus:outline-0 focus:ring-0 ml-2 bg-white/30 text-white h-[34px] 3xl:h-[40px] 3xl:text-base text-xs px-2 w-full file:text-[0rem] relative`}
                />
                <div
                  onClick={() => {
                    handleInputTypeFileTrigger();
                  }}
                  className={`absolute bg-white/30 rounded-xl h-[34px] 3xl:h-[40px] 3xl:text-base text-xs top-0 right-0 ml-2 left-0 bottom-0 w-full z-10 flex items-center justify-center`}
                >
                  <p
                    className={`text-white cursor-pointer  w-full text-left pl-3`}
                  >
                    Odaberi dokument
                  </p>
                </div>
              </div>
            </div>
            <div className={`col-span-1 invisible  text-left w-full gap-2`}>
              <label
                htmlFor={`documentnumber`}
                className={`text-xs 3xl:text-base text-white font-medium`}
              >
                Broj dokumenta:{" "}
              </label>
              <input
                type={`text`}
                name={`document_number`}
                value={formDataPhysical.document_number}
                id={`documentnumber`}
                className={`rounded-xl focus:border-[#aa1d23] focus:outline-0 focus:ring-0 ml-2 bg-white/30 text-white h-[34px] 3xl:h-[40px] 3xl:text-base text-xs px-2 w-full`}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col items-center justify-between max-w-[1220px] mx-auto mt-5`}
      >
        <div className={`flex items-center gap-20`}>
          <div className={`flex items-center gap-2`}>
            <input
              checked={checkedTerms}
              type={`checkbox`}
              id={`accept_terms`}
              value={formDataPhysical.accept_terms}
              name={`accept_terms`}
              onClick={() => setCheckedTerms(!checkedTerms)}
              className={`rounded focus:border-[#aa1d23] focus:outline-0 focus:ring-0 ml-2 bg-black/30 border-0 text-[#aa1d23] h-[20px] px-2 w-[20px]`}
            />
            <label
              htmlFor={`accept_terms`}
              className={`text-black underline text-xs 3xl:text-sm`}
            >
              Saglasan/na sam sa opštim uslovima{" "}
            </label>
          </div>
          <div className={`flex items-center gap-2`}>
            <input
              type={`checkbox`}
              checked={checkedPolicy}
              id={`policy`}
              value={formDataPhysical.accept_policy}
              name={`accept_policy`}
              onClick={() => setCheckedPolicy(!checkedPolicy)}
              className={`rounded focus:border-[#aa1d23] focus:outline-0 focus:ring-0 ml-2 bg-black/30 border-0 text-[#aa1d23] h-[20px] px-2 w-[20px]`}
            />
            <label
              htmlFor={`policy`}
              className={`text-black underline text-xs 3xl:text-sm`}
            >
              Saglasan/na sam sa politikom obrade ličnih podataka
            </label>
          </div>
        </div>
        <button
          name={`submit`}
          type={`submit`}
          onClick={(e) => {
            handleSubmit.mutate(formDataPhysical);
          }}
          className={`bg-[#aa1d23] text-white rounded-xl px-24 py-2.5 mt-5 text-sm 3xl:text-base 3xl:mt-9 hover:bg-opacity-90`}
        >
          Podnesi zahtev
        </button>{" "}
        <div
          className={`mt-[1.5%] 3xl:mt-[5%] w-full relative separate2 flex justify-center items-center text-black text-base font-normal`}
        >
          <Link className={`relative hover:underline`} to={`/kontakt`}>
            Kontakt
          </Link>
          <Link
            className={`relative hover:underline`}
            to={`/politika-privatnosti`}
          >
            Politika privatnosti
          </Link>
          <Link className={`relative hover:underline`} to={`/o-nama`}>
            O nama
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DemoRequestFormPhysical;
