import React, { useState } from "react";
import CustomModal from "../../../CustomModal/ui/CustomModal";
import { IconButton } from "@mui/material";
import { CloudDownload } from "@material-ui/icons";

const SingleNewsGallerySlide = ({ index, image }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <img
        onClick={() => setIsOpen(true)}
        src={image}
        className="w-[85%] h-44 rounded-md object-contain shadow-xl"
        alt=""
      />
      <CustomModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={`Image ${index + 1}`}
        TopAction={
          <IconButton
            sx={{
              position: "absolute",
              right: 50,
              top: 8,
            }}
            className="!text-gray-500"
            href={image}
            download={image}
          >
            <CloudDownload />
          </IconButton>
        }
      >
        <img src={image} alt="" />
      </CustomModal>
    </>
  );
};

export default SingleNewsGallerySlide;
