import { Mail } from "@material-ui/icons";
import {
  Badge,
  IconButton,
  Menu,
  Divider,
  Grid,
  MenuItem,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { post } from "../../services/api";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import { useNotifications } from "../../services/notificationsCountContext";

const HeaderNotificationButton = ({ translations }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const { count, markAsSeen } = useNotifications();

  const { data: notifications, refetch: refetchNotifications } = useQuery({
    queryKey: ["lastNotifications"],
    queryFn: async () =>
      await post(`portal/notifications/list/latest/list`, { limit: 5 }).then(
        (res) => res.payload
      ),
  });

  const handleOpenNotificationsList = (event) =>
    setAnchorEl(event.currentTarget);

  const handleCloseNotificationsList = () => setAnchorEl(null);

  const handleSeeNotification = async (notificationId) => {
    await markAsSeen(notificationId);
    refetchNotifications();
  };

  return (
    <>
      <IconButton onClick={handleOpenNotificationsList}>
        <Badge
          badgeContent={count}
          color="primary"
          sx={{
            "& .MuiBadge-badge": {
              top: 4,
              right: 4,
            },
          }}
        >
          <Mail className="!fill-white !h-12 !w-12" />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseNotificationsList}
        slotProps={{
          paper: {
            sx: {
              width: 350,
            },
          },
        }}
        MenuListProps={{
          sx: {
            paddingTop: "0 !important",
          },
        }}
      >
        {notifications?.items?.length > 0 ? (
          <Box>
            {notifications?.items?.map((notification, index) => {
              const seen = notification.status === "Pregledano";

              const sanitizedContent = DOMPurify.sanitize(
                notification.content,
                {
                  ALLOWED_TAGS: [
                    "p",
                    "br",
                    "strong",
                    "b",
                    "em",
                    "i",
                    "span",
                    "a",
                  ],
                  ALLOWED_ATTR: ["href", "target", "rel"],
                }
              );

              const parser = new DOMParser();
              const doc = parser.parseFromString(sanitizedContent, "text/html");
              const firstElement = doc.querySelector("p, span");
              const extractedContent = firstElement
                ? firstElement.outerHTML
                : sanitizedContent;

              return (
                <Grid key={index}>
                  <MenuItem
                    onClick={async () => {
                      if (!seen) await handleSeeNotification(notification.id);
                      navigate(`/account/notifications/${notification.id}`);
                      handleCloseNotificationsList();
                    }}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      backgroundColor: seen ? "rgba(0, 0, 0, 0.04)" : "white",
                      position: "relative",
                      pl: 3,
                      pr: 1,
                    }}
                  >
                    <Typography
                      variant="body1"
                      component="strong"
                      sx={{
                        fontWeight: seen ? 500 : 700,
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                        width: "100%",
                        display: "block",
                      }}
                    >
                      {notification.title}
                    </Typography>
                    <Grid
                      sx={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        overflow: "hidden",
                        color: seen ? "gray" : "black",
                        whiteSpace: "normal",
                        width: "100%",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: extractedContent.replace(/\n/g, " ").trim(),
                      }}
                    />
                    <Badge
                      color="error"
                      variant="dot"
                      invisible={seen}
                      sx={{
                        position: "absolute",
                        left: 12,
                        top: "50%",
                      }}
                    />
                  </MenuItem>
                  <Divider
                    sx={{
                      margin: "0 !important",
                    }}
                  />
                </Grid>
              );
            })}
            <Grid container sx={{ px: 2, pt: 1 }}>
              <Button
                sx={{ width: "100%" }}
                variant={`contained`}
                onClick={() => {
                  navigate("/account/notifications");
                  handleCloseNotificationsList();
                }}
              >
                {translations?.header?.notifications_list?.notifications
                  ?.show_all_button?.label || "Prikaži sve"}
              </Button>
            </Grid>
          </Box>
        ) : (
          <Typography sx={{ textAlign: `center`, pt: 2, pb: 1 }}>
            {notifications?.message}
          </Typography>
        )}
      </Menu>
    </>
  );
};

export default HeaderNotificationButton;
