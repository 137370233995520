import React, { useState } from "react";
import CustomTable from "../../../CustomTable/ui/CustomTable";
import columns from "../data/singleNewsDocumentsTableColumns.json";
import { useTranslate } from "../../../../services/hooks";
import SingleNewsDocumentDialog from "./SingleNewsDocumentDialog";

const SingleNewsDocumentsTable = ({ data }) => {
  const [dialog, setDialog] = useState({
    isOpen: false,
    document: null,
  });

  const { data: translations } = useTranslate("news-details", "default") || {};

  const {
    "news-details": { default: { table: tableTranslations } = {} } = {},
  } = translations || {};

  const handleSelectRow = (rowData) => {
    setDialog({ isOpen: true, document: rowData });
  };

  const handleCloseDocumentDialog = () => {
    setDialog({ isOpen: false, document: null });
  };

  return (
    <>
      <CustomTable
        name={tableTranslations?.heading.title}
        data={data}
        columns={columns}
        omitProperties={["subtitle", "description", "short_description"]}
        translations={tableTranslations}
        onSelectRow={handleSelectRow}
      />
      {dialog.isOpen && (
        <SingleNewsDocumentDialog
          onClose={handleCloseDocumentDialog}
          isOpen={dialog.isOpen}
          document={dialog.document}
        />
      )}
    </>
  );
};

export default SingleNewsDocumentsTable;
