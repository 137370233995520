const handleIsReset = (type) => {
  switch (type) {
    case "text":
      return "one-time-code";
    case "password":
      return "new-password";
    default:
      return "off";
  }
};

const handleIsNotReset = (type) => {
  switch (type) {
    case "email":
      return "username";
    case "password":
      return "current-password";
    default:
      return "off";
  }
};

export const handleAutocomplete = (isReset, type) => {
    switch (isReset) {
        case true:
            return handleIsReset(type);
        case false:
            return handleIsNotReset(type);
    }
};
