import { Link } from "react-router-dom";
import { useContext } from "react";
import { userContext } from "../../../services/userContext";

const DemoRequestFormForeign = ({
  handleInputChange,
  handleSubmit,
  formDataForeign,
  checkedTerms,
  setCheckedTerms,
  checkedPolicy,
  setCheckedPolicy,
}) => {
  const { lang } = useContext(userContext);

  return (
    <div
      className={`2xl:mt-[2rem] container mx-auto 3xl:mt-[5rem] w-full text-center`}
    >
      <h1 className={`text-center uppercase font-bold text-[19px] underline`}>
        Zahtev za DEMO nalog -{" "}
        <span className={`text-[#aa1d23]`}> Strano pravno lice</span>
      </h1>
      <p className={`mt-[40px] font-medium text-base text-black`}>
        Ukoliko ste zainteresovani za besplatnu demo verziju portala, potrebno
        je da popunite sledeći formular:
      </p>
      <div
        className={`mt-[13px] 3xl:mt-[30px] mx-auto px-6 rounded-2xl bg-[#232f3e] pb-8 w-[80%] 2xl:w-[64%]`}
      >
        <div
          className={`mt-[30px] grid grid-cols-2 gap-x-[5rem] 3xl:gap-x-[7rem]`}
        >
          <div
            className={` col-span-1 separate3 relative grid grid-cols-2 2xl:gap-y-1 3xl:gap-y-5 gap-x-[2rem] 3xl:gap-x-[3.25rem]`}
          >
            <h1
              className={`text-white col-span-2  pt-4 underline text-base text-center`}
            >
              Opšte informacije o pravnom licu:
            </h1>
            <div className={`col-span-2  text-left w-full gap-2`}>
              <label
                htmlFor={`name`}
                className={`text-xs 3xl:text-base text-white font-medium`}
              >
                Naziv kompanije{" "}
              </label>
              <input
                type={`text`}
                id={`name`}
                value={formDataForeign.name}
                name={`name`}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                className={`rounded-xl focus:border-[#aa1d23] focus:outline-0 focus:ring-0 ml-2 bg-white/30 text-white h-[34px] 3xl:h-[40px] 3xl:text-base text-xs px-2 w-full`}
              />
            </div>
            <div className={`col-span-2 text-left w-full gap-2`}>
              <label
                htmlFor={`address`}
                className={`text-xs 3xl:text-base text-white font-medium`}
              >
                Adresa:
              </label>
              <input
                name={`address`}
                type={`text`}
                value={formDataForeign.address}
                id={`address`}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                className={`rounded-xl focus:border-[#aa1d23] focus:outline-0 focus:ring-0 ml-2 bg-white/30 text-white h-[34px] 3xl:h-[40px] 3xl:text-base text-xs px-2 w-full`}
              />
            </div>
            <div className={`col-span-1  text-left w-full gap-2`}>
              <label
                htmlFor={`city`}
                className={`text-xs 3xl:text-base text-white font-medium`}
              >
                Grad:{" "}
              </label>
              <input
                name={`city_name`}
                type={`text`}
                value={formDataForeign.city_name}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                id={`city`}
                className={`rounded-xl focus:border-[#aa1d23] focus:outline-0 focus:ring-0 ml-2 bg-white/30 text-white h-[34px] 3xl:h-[40px] 3xl:text-base text-xs px-2 w-full`}
              />
            </div>
            <div className={`col-span-1  text-left w-full gap-2`}>
              <label
                htmlFor={`zipcode`}
                className={`text-xs 3xl:text-base text-white font-medium`}
              >
                Poštanski broj:{" "}
              </label>
              <input
                name={`postal_code`}
                type={`text`}
                value={formDataForeign.postal_code}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                id={`zipcode`}
                className={`rounded-xl focus:border-[#aa1d23] focus:outline-0 focus:ring-0 ml-2 bg-white/30 text-white h-[34px] 3xl:h-[40px] 3xl:text-base text-xs px-2 w-full`}
              />
            </div>
            <div className={`col-span-1  text-left w-full gap-2`}>
              <label
                htmlFor={`pib`}
                className={`text-xs 3xl:text-base text-white font-medium`}
              >
                PIB:
              </label>
              <input
                id={`pib`}
                type={`text`}
                value={formDataForeign.pib}
                name={`pib`}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                className={`rounded-xl text-sm focus:border-[#aa1d23] focus:outline-0 focus:ring-0 ml-2 bg-white/30 text-white h-[34px] 3xl:h-[40px] 3xl:text-base text-xs px-2 w-full`}
              ></input>
            </div>
            <div className={`col-span-1  text-left w-full gap-2`}>
              <label
                htmlFor={`maticni_broj`}
                className={`text-xs 3xl:text-base text-white font-medium`}
              >
                Matični broj:
              </label>
              <input
                id={`maticni_broj`}
                type={`text`}
                value={formDataForeign.maticni_broj}
                name={`maticni_broj`}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                className={`rounded-xl text-sm focus:border-[#aa1d23] focus:outline-0 focus:ring-0 ml-2 bg-white/30 text-white h-[34px] 3xl:h-[40px] 3xl:text-base text-xs px-2 w-full`}
              ></input>
            </div>
          </div>
          <div
            className={`col-span-1 place-items-center grid grid-cols-2 gap-y-5 gap-x-[3.25rem]`}
          >
            <h1
              className={`text-white col-span-2  pt-4 underline text-base text-center`}
            >
              Opšte informacije o predstavniku firme:
            </h1>
            <div className={`col-span-2  text-left w-full gap-2`}>
              <label
                htmlFor={`company_contact_name`}
                className={`text-xs 3xl:text-base text-white font-medium`}
              >
                Ime i prezime podnosioca zahteva:
              </label>
              <input
                type={`text`}
                value={formDataForeign.company_contact_name}
                name={`company_contact_name`}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                id={`company_contact_name`}
                className={`rounded-xl focus:border-[#aa1d23] focus:outline-0 focus:ring-0 ml-2 bg-white/30 text-white h-[34px] 3xl:h-[40px] 3xl:text-base text-xs px-2 w-full`}
              />
            </div>
            <div className={`col-span-2  text-left w-full gap-2`}>
              <label
                htmlFor={`email`}
                className={`text-xs 3xl:text-base text-white font-medium`}
              >
                E-mail adresa
              </label>
              <input
                name={`email`}
                type={`text`}
                value={formDataForeign?.email}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                id={`email`}
                className={`rounded-xl text-sm focus:border-[#aa1d23] focus:outline-0 focus:ring-0 ml-2 bg-white/30 text-white h-[34px] 3xl:h-[40px] 3xl:text-base text-xs px-2 w-full`}
              ></input>
            </div>

            <div className={`col-span-1  text-left w-full gap-2`}>
              <label
                htmlFor={`mobile`}
                name={`mobile`}
                className={`text-xs 3xl:text-base text-white font-medium`}
              >
                Mobilni telefon:{" "}
              </label>
              <input
                type={`text`}
                value={formDataForeign.mobile}
                id={`mobile`}
                name={`mobile`}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                className={`rounded-xl focus:border-[#aa1d23] focus:outline-0 focus:ring-0 ml-2 bg-white/30 text-white h-[34px] 3xl:h-[40px] 3xl:text-base text-xs px-2 w-full`}
              />
            </div>
            <div className={`col-span-1  text-left w-full gap-2`}>
              <label
                htmlFor={`phone`}
                className={`text-xs 3xl:text-base text-white font-medium`}
              >
                Fiksni telefon{" "}
              </label>
              <input
                type={`text`}
                name={`phone`}
                value={formDataForeign.phone}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                id={`phone`}
                className={`rounded-xl focus:border-[#aa1d23] focus:outline-0 focus:ring-0 ml-2 bg-white/30 text-white h-[34px] 3xl:h-[40px] 3xl:text-base text-xs px-2 w-full`}
              />
            </div>
            <div className={`col-span-1  text-left w-full gap-2`}>
              <label
                htmlFor={`company_work_position`}
                className={`text-xs 3xl:text-base text-white font-medium`}
              >
                Vaša funkcija u firmi:
              </label>
              <div className={`relative`}>
                <select
                  name={`company_work_position`}
                  id={`company_work_position`}
                  className={`rounded-xl focus:border-[#aa1d23]  z-0 focus:outline-0 focus:ring-0 ml-2 bg-white/30 text-white h-[34px] 3xl:h-[40px] 3xl:text-base text-xs px-2 w-full file:text-[0rem] relative`}
                >
                  <option value={`0`}>Izaberite poziciju</option>
                  <option value={`direktor`} className={`text-black`}>
                    Direktor
                  </option>
                </select>
              </div>
            </div>
            <div className={`col-span-1 invisible  text-left w-full gap-2`}>
              <label
                htmlFor={`documentnumber`}
                className={`text-xs 3xl:text-base text-white font-medium`}
              >
                Broj dokumenta:{" "}
              </label>
              <input
                type={`text`}
                name={`document_number`}
                value={formDataForeign.document_number}
                id={`documentnumber`}
                className={`rounded-xl focus:border-[#aa1d23] focus:outline-0 focus:ring-0 ml-2 bg-white/30 text-white h-[34px] 3xl:h-[40px] 3xl:text-base text-xs px-2 w-full`}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col items-center justify-between max-w-[1220px] mx-auto mt-5`}
      >
        <div className={`flex items-center gap-20`}>
          <div className={`flex items-center gap-2`}>
            <input
              checked={checkedTerms}
              type={`checkbox`}
              id={`accept_terms`}
              value={formDataForeign.accept_terms}
              name={`accept_terms`}
              onClick={() => setCheckedTerms(!checkedTerms)}
              className={`rounded focus:border-[#aa1d23] focus:outline-0 focus:ring-0 ml-2 bg-black/30 border-0 text-[#aa1d23] h-[20px] px-2 w-[20px]`}
            />
            <label
              htmlFor={`accept_terms`}
              className={`text-black underline text-xs 3xl:text-sm`}
            >
              Saglasan/na sam sa opštim uslovima{" "}
            </label>
          </div>
          <div className={`flex items-center gap-2`}>
            <input
              type={`checkbox`}
              checked={checkedPolicy}
              id={`policy`}
              value={formDataForeign.accept_policy}
              name={`accept_policy`}
              onClick={() => setCheckedPolicy(!checkedPolicy)}
              className={`rounded focus:border-[#aa1d23] focus:outline-0 focus:ring-0 ml-2 bg-black/30 border-0 text-[#aa1d23] h-[20px] px-2 w-[20px]`}
            />
            <label
              htmlFor={`policy`}
              className={`text-black underline text-xs 3xl:text-sm`}
            >
              Saglasan/na sam sa politikom obrade ličnih podataka
            </label>
          </div>
        </div>
        <button
          name={`submit`}
          type={`submit`}
          onClick={(e) => {
            handleSubmit.mutate(formDataForeign);
          }}
          className={`bg-[#aa1d23] text-white rounded-xl px-24 py-2.5 mt-5 text-sm 3xl:text-base 3xl:mt-9 hover:bg-opacity-90`}
        >
          Podnesi zahtev
        </button>{" "}
        <div
          className={`mt-[1.5%] 3xl:mt-[5%] w-full relative separate2 flex justify-center items-center text-black text-base font-normal`}
        >
          <Link className={`relative hover:underline`} to={`/kontakt`}>
            Kontakt
          </Link>
          <Link
            className={`relative hover:underline`}
            to={`/politika-privatnosti`}
          >
            Politika privatnosti
          </Link>
          <Link className={`relative hover:underline`} to={`/o-nama`}>
            O nama
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DemoRequestFormForeign;
