import { useQuery } from "@tanstack/react-query";
import React from "react";
import { get } from "../../services/api";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Section } from "../../components/shared/section";
import { useOutletContext } from "react-router-dom";

const Limits = () => {
  const { translate_data_profile } = useOutletContext();

  const { data: limits = [] } = useQuery({
    queryKey: ["limits_profile"],
    queryFn: async () => {
      return await get(`/portal/profile/limits`)?.then((res) => {
        if (res?.payload) {
          return res.payload;
        }
      });
    },
  });

  const renderTable = (title, limit) => (
    <TableContainer
      component={Paper}
      sx={{
        marginBottom: 4,
        borderRadius: 2,
        boxShadow: 3,
        overflow: "hidden",
      }}
    >
      <Box sx={{ backgroundColor: "#ed545c", color: "white", padding: 1 }}>
        <p>{title}</p>
      </Box>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
            <TableCell sx={{ fontWeight: "bold", width: "33.33%" }}>
              {translate_data_profile?.["profile"]?.["limits"]?.["table"]?.[
                "used_column"
              ]?.["label"] ?? "Iskorišćeno"}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", width: "33.33%" }}>
              {translate_data_profile?.["profile"]?.["limits"]?.["table"]?.[
                "total_limit_column"
              ]?.["label"] ?? "Ukupan limit"}
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", width: "33.33%" }}>
              {translate_data_profile?.["profile"]?.["limits"]?.["table"]?.[
                "remaining_column"
              ]?.["label"] ?? "Preostalo"}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{ "&:nth-of-type(even)": { backgroundColor: "#fafafa" } }}
          >
            <TableCell sx={{ width: "33.33%" }}>
              {new Intl.NumberFormat("sr-RS").format(limit?.used)}
            </TableCell>
            <TableCell sx={{ width: "33.33%" }}>
              {new Intl.NumberFormat("sr-RS").format(limit?.limit)}
            </TableCell>
            <TableCell sx={{ width: "33.33%" }}>
              {new Intl.NumberFormat("sr-RS").format(limit?.remaining)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Section>
      {(limits ?? [])?.map((limit) =>
        renderTable(
          translate_data_profile?.["profile"]?.["limits"]?.["table"]?.[
            limit?.type
          ]?.["title"] ?? limit?.table_name,
          limit
        )
      )}
    </Section>
  );
};

export default Limits;
