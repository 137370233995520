import React from "react";
import CustomTable from "../../CustomTable/ui/CustomTable";
import columns from "../data/contractInfoTableColumns.json";
import formatDate from "../../../helpers/dateHelpers";

const ContractInfoTable = ({ data, translations }) => {
  const tableData = {
    ...data,
    currency: data.currency.toUpperCase(),
    contract_amount: parseFloat(data.contract_amount),
    from_date: formatDate(data.from_date, "DD.MM.YYYY", "DD-MM-YYYY HH:mm:ss"),
    expire_at: formatDate(data.expire_at, "DD.MM.YYYY", "DD-MM-YYYY HH:mm:ss"),
  };

  return (
    <CustomTable
      columns={columns}
      data={tableData}
      translations={translations}
      omitProperties={["type"]}
      className={`guideline-target-element`}
      targetId={`contract_info`}
    />
  );
};

export default ContractInfoTable;
