import { useContext, useEffect, useState } from "react";
import { userContext } from "../services/userContext";
import {
  Route,
  Navigate,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { routes } from "./routes";
import { staticRoutes } from "./routes";
import React from "react";
import DelayedRender from "../components/DelayedRender/DelayedRender";
import { useQuery } from "@tanstack/react-query";
import { get, post } from "../services/api";
import { useIdle } from "@uidotdev/usehooks";
import { Modal } from "../components/shared/modal";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { Header } from "../components/Header/Header";
import ContractAdvisor from "../components/Contract/ui/ContractAdvisor";

const ApplicationRouter = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  //provera da li je korisnik ulogovan
  const { isLoggedIn, setIsLoggedIn, user, logout, login } =
    useContext(userContext);

  //rute koje mogu da vide korisnici koji nisu ulogovani
  const unAuthorizedRoutes = routes?.filter((route) => !route.authorized);

  //rute koje mogu da vide korisnici koji su ulogovani
  const authorizedRoutes = routes?.filter((route) => route.authorized);

  //komponenta DelayedRender se koristi da bi se uveo artifical delay radi provere da li je korisnik ulogovan

  const [enabled, setEnabled] = useState(false);

  const { refetch } = useQuery({
    queryKey: ["userRefreshToken"],
    queryFn: () => {
      let customer_token = Cookies.get("customer-token");
      if (customer_token?.includes("cus")) {
        return get(`/portal/profile/user/refresh-token`).then((res) => {
          if (res?.code === 200) {
            Cookies.set("scoring_user", JSON.stringify(res?.payload?.user));
            Cookies.set("scoring_token", res?.payload?.access_token, {
              expires: 365,
            });
            setIsLoggedIn(true);
            return res?.payload;
          }
        });
      }
    },
    refetchInterval: 1000 * 60 * 10,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    enabled: enabled,
  });

  const is_user_idle = useIdle(1000 * 60 * 25);
  const should_logout = useIdle(1000 * 60 * 30);

  const handleLogout = async (pathname, search) => {
    return await post(`/portal/profile/user/logout`).then((res) => {
      setIsIdle(false);
      logout(pathname, search);
    });
  };

  const [isIdle, setIsIdle] = useState(false);

  useEffect(() => {
    if (is_user_idle && isLoggedIn) {
      setIsIdle(true);
    }
  }, [is_user_idle]);

  useEffect(() => {
    if (should_logout && isLoggedIn) {
      handleLogout(pathname, search);
      toast.error("Sesija je istekla. Molimo Vas da se ponovo prijavite.", {
        position: "top-center",
        autoClose: 2000,
      });
    }
  }, [should_logout]);

  useEffect(() => {
    const interval = setInterval(
      () => {
        setEnabled(true);
      },
      1000 * 60 * 5
    );
    setEnabled(false);
    return () => clearInterval(interval);
  }, [enabled]);

  return (
    <>
      <Routes>
        {unAuthorizedRoutes?.map((route, index) => {
          return (
            <Route key={index} path={route?.path} element={route?.element} />
          );
        })}

        {authorizedRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <DelayedRender delay={0}>
                <Header />
                {isLoggedIn ? route.element : <Navigate to="/login" />}
                <ContractAdvisor />
              </DelayedRender>
            }
          >
            {route.children &&
              route.children.map((childRoute, childIndex) => (
                <Route
                  key={childIndex}
                  path={childRoute.path}
                  element={childRoute.element}
                />
              ))}
          </Route>
        ))}

        {staticRoutes?.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>

      {isIdle && isLoggedIn && (
        <div
          className={`min-h-screen w-screen bg-black/40 fixed top-0 left-0 z-50`}
        >
          <Modal
            title="Obaveštenje"
            openDeleteDialog={{ show: isIdle }}
            nameOfButtonCancel="Odjavite se"
            nameOfButton="Nastavite rad"
            deafultDeleteIcon={false}
            description={`Automatsko odjavljivanje će nastati za 5 minuta. Da li želite da nastavite rad?`}
            handleConfirm={() => {
              setIsIdle(false);
              refetch();
            }}
            sx={{
              backgroundColor: "#28a86e",
              "&:hover": { backgroundColor: "rgb(28, 117, 77)" },
            }}
            handleCancel={() => {
              handleLogout();
              setIsIdle(false);
              toast.success("Uspešno ste se odjavili!", {
                position: "top-center",
                autoClose: 2000,
              });
            }}
            handleCancelToken={true}
          />
        </div>
      )}
    </>
  );
};

export default ApplicationRouter;
