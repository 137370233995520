import { Stack } from "@mui/material";
import { styled } from "@mui/styles";

const CustomStackStyled = styled(Stack)(({ theme }) => ({
  "& > :last-child": {
    marginBottom: "0 !important",
  },
}));

export default CustomStackStyled;
