import { get, post } from "../../../services/api";
import { toast } from "react-toastify";

// Fetch Sister Companies
export const fetchSisterCompanies = async (
  companyId,
  filters = [],
  search = "",
  page = 1
) => {
  try {
    const payload = {
      company_id: Number(companyId),
      page,
      search,
      filters: filters.length > 0 ? filters : [],
      limit: 10,
    };

    const response = await post(
      "portal/companies/other-important-info/related-companies/companies-sisters/list",
      payload,
      "SISTER_COMPANIES_LIST"
    );

    if (response?.code === 200) {
      return response?.payload;
    } else {
      toast.error("Greška pri učitavanju kompanija sestara.");
      return [];
    }
  } catch (error) {
    console.error("❌ Greška pri učitavanju kompanija sestara:", error);
    toast.error("Greška pri učitavanju kompanija sestara.");
    return [];
  }
};

// Fetch Sister Companies Filters
export const fetchSisterCompanyFilters = async () => {
  try {
    const response = await get(
      "portal/companies/other-important-info/related-companies/companies-sisters/ddl/filters"
    );
    if (response?.code === 200) {
      return response?.payload || [];
    } else {
      toast.error("Greška pri učitavanju filtera kompanija sestara.");
      return [];
    }
  } catch (error) {
    console.error("❌ Greška pri učitavanju filtera kompanija sestara:", error);
    toast.error("Greška pri učitavanju filtera kompanija sestara.");
    return [];
  }
};

// Fetch Sister Companies Owner Filters
export const fetchSisterCompanyOwnerFilters = async (companyId) => {
  try {
    const response = await get(
      `portal/companies/other-important-info/related-companies/companies-sisters/ddl/owner-filters?company_id=${companyId}`
    );
    if (response?.code === 200) {
      return response?.payload || [];
    } else {
      toast.error("Greška pri učitavanju filtera vlasnika.");
      return [];
    }
  } catch (error) {
    console.error("❌ Greška pri učitavanju filtera vlasnika:", error);
    toast.error("Greška pri učitavanju filtera vlasnika.");
    return [];
  }
};
