import Chevron from "../../assets/Icons/right-chevron.png";
import { useMutation, useQuery } from "@tanstack/react-query";
import { get, post } from "../../services/api";
import profileTabs from "./data.json";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { userContext } from "../../services/userContext";
import { useIsMobile, useTranslate } from "../../services/hooks";
import Cookies from "js-cookie";

export const SidebarProfile = ({ children }) => {
  const is_mobile = useIsMobile();
  const [isOpen, setIsOpen] = useState(!is_mobile);
  const location = useLocation();
  const navigate = useNavigate();

  const { logout } = useContext(userContext);

  const [selected, setSelected] = useState({});
  const [active, setActive] = useState("basic_data");
  const { lang, setLang } = useContext(userContext);

  const active_tab = location.pathname.split("/")[2];

  const onParamsSearchChange = (value) => {
    Cookies.set("selected_lang", value);
    setLang(value);
  };

  const handleChange = (value, name) => {
    setSelected((prev) => ({ ...prev, [name]: value }));
  };

  const { data: countries_ddl, refetch: refetchCountries } = useQuery({
    queryKey: ["ddl_countries"],
    queryFn: async () => {
      return await get(
        `/portal/profile/configs/ddl/countries?portal_filters=true`
      ).then((res) => res?.payload ?? []);
    },
  });

  const { data, refetch } = useQuery({
    queryKey: ["profile"],
    queryFn: async () => {
      return await get(`/portal/profile/configs`)?.then((res) => {
        if (res?.payload) {
          refetchCountries();
          refetchLang();
          setSelected({
            country: res?.payload?.country,
            language: res?.payload?.lang,
          });
          return res.payload;
        } else {
          return {};
        }
      });
    },
  });

  const { data: translate_data_profile } = useTranslate("profile", "PROFILE");

  const { data: languages_ddl, refetch: refetchLang } = useQuery({
    queryKey: ["ddl_languages"],
    queryFn: async () => {
      return await get(`/portal/profile/configs/ddl/languages`).then(
        (res) => res?.payload ?? []
      );
    },
  });

  const { mutate: log_out, isPending: logoutPending } = useMutation({
    mutationKey: ["logout"],
    mutationFn: async () => {
      return await post(`/portal/profile/user/logout`).then((res) => {
        switch (res?.code) {
          case 200:
            logout(null, null);
            toast.success(res?.payload?.message ?? "Uspešno ste se odjavili!", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
            });
            break;
          default:
            toast.error("Greška prilikom odjave!", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
            });
            break;
        }
      });
    },
  });

  useEffect(() => {
    if (active_tab) {
      setActive(active_tab);
    } else {
      setActive("basic_data");
    }
  }, [active_tab]);

  const handleTab = (param) => {
    if (param) {
      navigate(`/account/${param}`);
      setActive(param);
    }
    return null;
  };

  const { data: translate } = useTranslate("profile-menu", "PROFILE_MENU");

  return (
    <>
      <div className={`fixed top-[5.4rem] bottom-[0] left-0  max-md:hidden`}>
        <div
          className={
            isOpen
              ? `bg-[#202831] z-[5] transition-all duration-500 translate-x-0 xl:w-[270px] 3xl:w-[320px] 4xl:w-[350px] h-full flex flex-col`
              : `bg-[#202831] -translate-x-[100%] duration-700 transition-all xl:w-[270px] 3xl:w-[320px] 4xl:w-[350px] h-full flex flex-col`
          }
        >
          <div className={`flex flex-col py-4 relative bg-[#2b343b] pl-6 pr-4`}>
            <p className={`text-white text-base`}>
              {translate?.["profile-menu"]?.["default"]?.["header"]?.[
                "welcome"
              ]?.["label"] ?? "Dobrodošli"}
              , {data?.customer_name}
            </p>
            <div
              className={`absolute -right-[0.82rem] ${
                !isOpen && `-right-[2.5rem]`
              } top-1/2 transform -translate-y-1/2 bg-white rounded-full shadow-xl shadow-black cursor-pointer`}
              onClick={() => setIsOpen((prev) => !prev)}
            >
              <img
                src={Chevron}
                alt={``}
                width={`23px`}
                className={`p-1 ${isOpen ? "rotate-180" : ""}`}
              />
            </div>
          </div>
          <div className={`flex-1 overflow-y-auto customscroll`}>
            <div className={`flex flex-col gap-1`}>
              {(profileTabs ?? [])?.map(({ id, name, param }) => {
                return (
                  <div
                    onClick={() => {
                      handleTab(param);
                    }}
                    key={`item-${id}`}
                    className={`flex items-center gap-4 pl-6 cursor-pointer py-2 pr-4 ${
                      active === param ? "bg-[#ed545c]" : "hover:bg-[#ed545c]"
                    }`}
                  >
                    <p className={`text-white`}>
                      {translate?.["profile-menu"]?.["default"]?.["item"]?.[
                        param
                      ]?.["label"] ?? name}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={`mt-auto mb-2 pt-5 mx-6`}>
            <Button
              className={`w-full`}
              disabled={logoutPending}
              variant={`contained`}
              color={`error`}
              onClick={() => {
                log_out();
              }}
            >
              {translate?.["profile-menu"]?.["default"]?.["footer"]?.[
                "logout_button"
              ]?.["label"] ?? "Odjavite se"}
            </Button>
          </div>
        </div>
      </div>
      <div
        className={`${
          isOpen
            ? `pl-[23rem] 3xl:pl-[25rem]`
            : ` max-md:px-[1rem] md:pl-[3rem]`
        } mx-auto pr-[3rem] transition-all duration-500 `}
      >
        <Outlet
          context={{
            data,
            refetch,
            selected,
            onParamsSearchChange,
            handleChange,
            countries_ddl,
            languages_ddl,
            translate_data_profile,
          }}
        />
        {children}
      </div>
    </>
  );
};
