import { QueryClient } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./services/userContext";
import ApplicationRouter from "./utils/ApplicationRouter";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import { JoyrideProvider } from "./context/JoyrideContext";
import { NotificationsProvider } from "./services/notificationsCountContext";
const queryClient = new QueryClient();

const persister = createSyncStoragePersister({
  storage: typeof window !== "undefined" ? window.localStorage : null,
  key: "scoring-data-cache-v1.01",
});

const App = () => {
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
    >
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <JoyrideProvider>
            <AuthProvider>
              <NotificationsProvider>
                <ToastContainer />
                <ApplicationRouter />
              </NotificationsProvider>
            </AuthProvider>
          </JoyrideProvider>
        </ThemeProvider>
      </BrowserRouter>
    </PersistQueryClientProvider>
  );
};

export default App;
