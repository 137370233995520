import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { deleteMethod, post } from "../../services/api";
import { toast } from "react-toastify";
import { Section } from "../../components/shared/section";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import UnfollowActionButton from "../../components/Account/ActionButtons/UnfollowActionButton";

const CompaniesFollowingList = () => {
  const { translate_data_profile } = useOutletContext();

  const [selectedId, setSelectedId] = useState("");

  const {
    data: list = [],
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["companies_following"],
    queryFn: async () => {
      return await post(`/portal/profile/companies/list`, {
        search: "",
        limit: "",
        page: 1,
        filters: [],
        sort: [],
      })?.then((res) => {
        if (res?.payload?.items?.length > 0) {
          return res?.payload?.items;
        } else {
          return [];
        }
      });
    },
  });

  // const handleMessage = useMutation({
  //   mutationKey: ["message"],
  //   mutationFn: async ({ id }) => {
  //     return await get(`/portal/profile/companies/message/${id}`)?.then(
  //       (res) => {},
  //     );
  //   },
  // });

  const { mutate: unfollow, isSuccess } = useMutation({
    mutationKey: ["message"],
    mutationFn: async ({ id }) => {
      return await deleteMethod(
        `/portal/profile/companies/confirm/${id}`
      )?.then((res) => {
        if (res?.code === 200) {
          toast.success(res?.payload?.message, {
            position: "top-center",
          });
        } else {
          toast.error(res?.data?.message, {
            position: "top-center",
          });
        }
      });
    },
  });

  useEffect(() => {
    isSuccess && refetch();
  }, [isSuccess]);

  const navigate = useNavigate();

  return (
    <Section>
      <TableContainer
        component={Paper}
        sx={{
          marginBottom: 4,
          borderRadius: 2,
          boxShadow: 3,
          overflow: "hidden",
        }}
      >
        <Box sx={{ backgroundColor: "#ed545c", color: "white", padding: 1 }}>
          <p>
            {translate_data_profile?.["profile"]?.["monitoring"]?.["table"]?.[
              "monitoring"
            ]?.["title"] ?? "Monitoring"}
          </p>
        </Box>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
              <TableCell sx={{ fontWeight: "bold", width: "33.33%" }}>
                {translate_data_profile?.["profile"]?.["monitoring"]?.[
                  "table"
                ]?.["company_name_column"]?.["label"] ?? "Naziv"}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", width: "33.33%" }}>
                {translate_data_profile?.["profile"]?.["monitoring"]?.[
                  "table"
                ]?.["pib_column"]?.["label"] ?? "PIB"}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", width: "33.33%" }}>
                {translate_data_profile?.["profile"]?.["monitoring"]?.[
                  "table"
                ]?.["maticni_broj_column"]?.["label"] ?? "Matični broj"}
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", width: "33.33%" }}>
                {translate_data_profile?.["profile"]?.["monitoring"]?.[
                  "table"
                ]?.["actions_column"]?.["label"] ?? "Akcije"}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading
              ? [...Array(5)].map((_, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <div className={`w-20 h-4 bg-gray-300 rounded`} />
                      </TableCell>
                      <TableCell>
                        <div className={`w-20 h-4 bg-gray-300 rounded`} />
                      </TableCell>
                      <TableCell>
                        <div className={`w-20 h-4 bg-gray-300 rounded`} />
                      </TableCell>
                      <TableCell>
                        <div className={`w-20 h-4 bg-gray-300 rounded`} />
                      </TableCell>
                    </TableRow>
                  );
                })
              : (list ?? [])?.map((item, index) => {
                  const { naziv, pib, firma_id, maticni_broj } = item;

                  return (
                    <TableRow key={index} className={`hover:bg-[#f7f7f7]`}>
                      <TableCell
                        className={`cursor-pointer`}
                        onClick={() => {
                          navigate(`/company-profile/basic-data/${firma_id}`);
                        }}
                        sx={{ width: "25%" }}
                      >
                        {naziv}
                      </TableCell>
                      <TableCell
                        className={`cursor-pointer`}
                        onClick={() => {
                          navigate(`/company-profile/basic-data/${firma_id}`);
                        }}
                        sx={{ width: "25%" }}
                      >
                        {pib}
                      </TableCell>
                      <TableCell
                        className={`cursor-pointer`}
                        onClick={() => {
                          navigate(`/company-profile/basic-data/${firma_id}`);
                        }}
                        sx={{ width: "25%" }}
                      >
                        {maticni_broj}
                      </TableCell>
                      <TableCell sx={{ width: "25%" }}>
                        <UnfollowActionButton
                          id={firma_id}
                          onConfirm={() => unfollow({ id: firma_id })}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
    </Section>
  );
};

export default CompaniesFollowingList;
