import Logo from "../../assets/images/scoring-logo.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import bg from "../../assets/images/BG.jpg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { get, post } from "../../services/api";
import { SelectInput } from "./select";
import { AutocompleteInput } from "./autocomplete";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  bg: {
    backgroundImage: `url(${bg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    minHeight: "100dvh",
  },
  header: {
    padding: "0.9rem 3rem",
    height: "fit-content",
    backgroundColor: "#232f3e",
  },
}));

export const RegistrationSteps = () => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const slug = searchParams.get("slug");
  const navigate = useNavigate();
  const [data, setData] = useState({
    country_id: 192,
  });

  const { data: countries, isFetching } = useQuery({
    queryKey: ["countries"],
    queryFn: async () => {
      return await get(`/portal/registration/main/ddl/country_id`)
        .then((res) => res?.payload)
        ?.catch((err) => err);
    },
    refetchOnWindowFocus: false,
  });

  const [openedAccordion, setOpenedAccordion] = useState(0);

  const { data: step, refetch } = useQuery({
    queryKey: ["steps"],
    queryFn: async () => {
      return await get(
        `/portal/registration/main/progress-step/${slug ?? data?.slug}`
      )
        .then((res) => res?.payload)
        ?.catch((err) => err);
    },
    refetchOnWindowFocus: false,
  });
  const {
    data: company,
    mutate,
    isSuccess,
  } = useMutation({
    mutationKey: ["addCompany", data?.firma_id],
    mutationFn: async () => {
      return await post(`/portal/registration/main/company/basic-data`, {
        ...data,
      })
        .then((res) => {
          refetch();
          if (res?.code === 200) {
            setOpenedAccordion(2);
          } else {
            toast.error("Unesite sve podatke!", {
              autoClose: 2000,
              position: "top-center",
            });
          }
        })
        ?.catch((err) => err);
    },
    refetchOnWindowFocus: false,
  });

  const {} = useQuery({
    queryKey: ["companyInfo", data?.firma_id],
    queryFn: async () => {
      if (data?.firma_id) {
        return await get(`/portal/registration/main/company/${data?.firma_id}`)
          .then((res) => {
            setData({
              ...data,
              name: res?.payload?.naziv,
              id: data?.id,
              country_id: data?.country_id,
              firma_id: data?.firma_id,
              pib: res?.payload?.pib,
              maticni_broj: res?.payload?.maticni_broj,
              city: res?.payload?.mesto,
              address: res?.payload?.adresa,
              postal_code: data?.postal_code,
              slug: data?.slug,
            });
          })
          ?.catch((err) => err);
      }
    },
    refetchOnWindowFocus: false,
  });

  const { mutate: mutateWorker } = useMutation({
    mutationKey: ["addWorker", data?.first_name],
    mutationFn: async () => {
      return await post(
        `/portal/registration/main/company/representative/basic-data`,
        {
          ...data,
        }
      )
        .then((res) => {
          refetch();
          if (res?.code === 200) {
            setOpenedAccordion(3);
            const timeout = setTimeout(() => {
              refetch();
            }, 200);
            return () => clearTimeout(timeout);
          } else {
            toast.error(res?.data?.message, {
              autoClose: 2000,
              position: "top-center",
            });
          }
        })
        ?.catch((err) => err);
    },
    refetchOnWindowFocus: false,
  });

  const { data: conditions } = useQuery({
    queryKey: [step],
    queryFn: async () => {
      if (step?.includes("conditions")) {
        return await get(`/portal/registration/main/terms/document`)
          .then((res) => res?.payload)
          ?.catch((e) => e);
      }
    },
    refetchOnWindowFocus: false,
  });

  const { mutate: mutateConditions } = useMutation({
    mutationKey: ["conditionsMutate"],
    mutationFn: async () => {
      return await post(`/portal/registration/main/terms`, {
        ...data,
      }).then((res) => {
        if (res?.code === 200) {
          setOpenedAccordion(4);
          const timeout = setTimeout(() => {
            refetch();
            getInfo();
          }, 200);
          return () => clearTimeout(timeout);
        } else {
          toast.error("Unesite sve podatke!", {
            autoClose: 2000,
            position: "top-center",
          });
        }
      });
    },
    refetchOnWindowFocus: false,
  });

  const { data: view_info, refetch: getInfo } = useQuery({
    queryKey: ["allInfo"],
    queryFn: async () => {
      return await get(`/portal/registration/main/${data?.slug}`)
        .then((res) => res?.payload)
        ?.catch((e) => e);
    },
    enabled: false,
    refetchOnWindowFocus: false,
  });

  const { mutate: register, isPending } = useMutation({
    mutationKey: ["finish"],
    mutationFn: async () => {
      return await post(`/portal/registration/main/accept `, {
        slug: data?.slug,
        id: data?.id,
      }).then((res) => {
        if (res?.code === 200) {
          toast.success(`Uspešno ste poslali zahtev za registraciju!`, {
            autoClose: 2000,
            position: "top-center",
          });
          navigate("/login");
        } else {
          toast.error("Došlo je do greške!", {
            autoClose: 2000,
            position: "top-center",
          });
        }
      });
    },
    refetchOnWindowFocus: false,
  });

  const {} = useQuery({
    queryKey: ["fillData"],
    queryFn: async () => {
      if (slug) {
        return await get(`/portal/registration/main/${slug}`).then((res) => {
          if (res?.code === 200) {
            setData(res?.payload);
            refetch();
          }
        });
      }
    },
    refetchOnWindowFocus: false,
  });

  const [isTextareaScrolled, setIsTextareaScrolled] = useState(false);
  const textareaRef = useRef(null);
  const hasScrolledToBottomRef = useRef(false);

  const handleScroll = useCallback(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      const { scrollTop, clientHeight, scrollHeight } = textarea;
      const scrolledToBottom = scrollTop + clientHeight >= scrollHeight - 1;

      if (scrolledToBottom && !hasScrolledToBottomRef.current) {
        setIsTextareaScrolled(true);
        hasScrolledToBottomRef.current = true;
      }
    }
  }, []);

  const handleTextareaScroll = useCallback(() => {
    requestAnimationFrame(handleScroll);
  }, [handleScroll]);

  return (
    <div
      style={{
        width: "100%",
        overflowY: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "start",
          margin: "5rem auto",
          alignItems: "center",
          maxWidth: "34.375rem",
        }}
      >
        <Accordion
          defaultExpanded={true}
          expanded={openedAccordion === 0}
          sx={{
            width: "100%",
          }}
          onClick={() => {
            openedAccordion !== 0 && setOpenedAccordion(0);
          }}
        >
          <AccordionSummary>
            <h3>1. Odabir države</h3>
          </AccordionSummary>
          <AccordionDetails>
            <SelectInput
              required={true}
              options={countries}
              isLoading={isFetching}
              setData={setData}
              data={data}
              setOpenedAccordion={setOpenedAccordion}
              openedAccordion={openedAccordion}
              refetch={refetch}
              setSearchParams={setSearchParams}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          onClick={() => {
            if (step?.includes("basic_company_info") && openedAccordion !== 1) {
              setOpenedAccordion(1);
            }
          }}
          disabled={!step?.includes("basic_company_info")}
          sx={{
            width: "100%",
          }}
          expanded={openedAccordion === 1}
        >
          <AccordionSummary>
            <h3>2. Odabir firme</h3>
          </AccordionSummary>
          <AccordionDetails>
            <AutocompleteInput
              data={data}
              setData={setData}
              setOpenedAccordion={setOpenedAccordion}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "1rem",
              }}
            >
              <Button
                variant={`contained`}
                color={`error`}
                sx={{ marginRight: "auto" }}
                onClick={() => {
                  setOpenedAccordion(0);
                }}
              >
                Prethodni korak
              </Button>
              <Button
                variant={`contained`}
                color={`success`}
                sx={{ marginLeft: "auto" }}
                onClick={() => {
                  mutate(data);
                }}
              >
                Sledeći korak
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          onClick={() => {
            if (step?.includes("basic_info") && openedAccordion !== 2) {
              setOpenedAccordion(2);
            }
          }}
          disabled={!step?.includes("basic_info")}
          expanded={openedAccordion === 2}
          sx={{
            width: "100%",
          }}
        >
          <AccordionSummary>
            <h3>3. Informacije o predstavniku firme</h3>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              required
              sx={{
                width: "100%",
                marginTop: "1rem",
              }}
              label={`Ime`}
              value={data?.first_name || ""}
              onChange={(e) => {
                setData({
                  ...data,
                  first_name: e.target.value,
                });
              }}
            />

            <TextField
              required
              sx={{
                width: "100%",
                marginTop: "1rem",
              }}
              label={`Prezime`}
              value={data?.last_name || ""}
              onChange={(e) => {
                setData({
                  ...data,
                  last_name: e.target.value,
                });
              }}
            />

            <TextField
              required
              sx={{
                width: "100%",
                marginTop: "1rem",
              }}
              label={`E-mail`}
              value={data?.email || ""}
              onChange={(e) => {
                setData({
                  ...data,
                  email: e.target.value,
                });
              }}
            />

            <TextField
              required
              sx={{
                width: "100%",
                marginTop: "1rem",
              }}
              label={`Telefon`}
              value={data?.phone || ""}
              onChange={(e) => {
                setData({
                  ...data,
                  phone: e.target.value,
                });
              }}
            />
            <TextField
              required
              sx={{
                width: "100%",
                marginTop: "1rem",
              }}
              label={`Fiksni telefon`}
              value={data?.landline_phone || ""}
              onChange={(e) => {
                setData({
                  ...data,
                  landline_phone: e.target.value,
                });
              }}
            />

            <TextField
              required
              sx={{
                width: "100%",
                marginTop: "1rem",
              }}
              label={`Pozicija u firmi`}
              value={data?.working_position || ""}
              onChange={(e) => {
                setData({
                  ...data,
                  working_position: e.target.value,
                });
              }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "1rem",
              }}
            >
              <Button
                variant={`contained`}
                color={`error`}
                sx={{ marginRight: "auto" }}
                onClick={() => {
                  setOpenedAccordion(1);
                }}
              >
                Prethodni korak
              </Button>
              <Button
                variant={`contained`}
                color={`success`}
                sx={{ marginLeft: "auto" }}
                onClick={() => {
                  mutateWorker(data);
                }}
              >
                Sledeći korak
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          onClick={() => {
            if (step?.includes("conditions") && openedAccordion !== 3) {
              setOpenedAccordion(3);
            }
          }}
          disabled={!step?.includes("conditions")}
          expanded={openedAccordion === 3}
          sx={{
            width: "100%",
          }}
        >
          <AccordionSummary>
            <h3>4. Uslovi korišćenja</h3>
          </AccordionSummary>
          <AccordionDetails>
            <TextareaAutosize
              ref={textareaRef}
              onScroll={handleTextareaScroll}
              style={{
                width: "100%",
              }}
              disabled={!step?.includes("conditions")}
              readOnly
              maxRows={5}
              value={conditions?.content}
            ></TextareaAutosize>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.2rem",
                flexWrap: "wrap",
              }}
            >
              <FormControlLabel
                className={`!text-black`}
                control={
                  <Checkbox
                    disabled={!isTextareaScrolled}
                    checked={
                      data?.accept_terms === 1 || data?.accept_terms === "1"
                    }
                    color={`success`}
                  />
                }
                label={`Prihvatam uslove korišćenja`}
                onChange={(e) => {
                  let val = e.target.checked;
                  setData({
                    ...data,
                    accept_terms: val ? 1 : 0,
                  });
                }}
              />

              <FormControl>
                <FormControlLabel
                  className={`!text-black`}
                  control={
                    <Checkbox
                      disabled={!isTextareaScrolled}
                      checked={
                        data?.accept_policy === 1 || data?.accept_policy === "1"
                      }
                      color={`success`}
                    />
                  }
                  label={`Prihvatam politiku obrade ličnih podataka`}
                  onChange={(e) => {
                    let val = e.target.checked;
                    setData({
                      ...data,
                      accept_policy: val ? 1 : 0,
                    });
                  }}
                />
              </FormControl>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "1rem",
              }}
            >
              <Button
                disabled={!step?.includes("conditions")}
                variant={`contained`}
                color={`error`}
                sx={{ marginRight: "auto" }}
                onClick={() => {
                  setOpenedAccordion(2);
                }}
              >
                Prethodni korak
              </Button>
              <Button
                disabled={
                  !step?.includes("conditions") ||
                  !Boolean(Number(data?.accept_policy)) ||
                  !Boolean(Number(data?.accept_terms))
                }
                variant={`contained`}
                color={`success`}
                sx={{ marginLeft: "auto" }}
                onClick={() => {
                  if (
                    Boolean(data?.accept_terms) &&
                    Boolean(data?.accept_policy)
                  ) {
                    mutateConditions(data);
                  } else {
                    toast.error("Morate prihvatiti sve uslove!", {
                      position: "top-center",
                    });
                  }
                }}
              >
                Sledeći korak
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          onClick={() => {
            if (step?.includes("review") && openedAccordion !== 4) {
              setOpenedAccordion(4);
            }
          }}
          disabled={!step?.includes("review")}
          expanded={openedAccordion === 4}
          sx={{
            width: "100%",
          }}
        >
          <AccordionSummary>
            <h3>5. Pregled unetih informacija</h3>
          </AccordionSummary>
          <AccordionDetails>
            {view_info && (
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              >
                <p>Naziv firme: {view_info?.name}</p>
                <p>PIB: {view_info?.pib}</p>
                <p>Matični broj: {view_info?.maticni_broj}</p>
                <p>Grad: {view_info?.city}</p>
                <p>Adresa: {view_info?.address}</p>
                <p>Ime podnosioca: {view_info?.first_name}</p>
                <p>Prezime podnosioca: {view_info?.last_name}</p>
                <p>E-mail: {view_info?.email}</p>
                <p>Telefon: {view_info?.phone}</p>
                <p>Pozicija: {view_info?.working_position}</p>
                <p>
                  Saglasan/a sam sa uslovima korišćenja:{" "}
                  {view_info?.accept_terms === 1 ||
                  view_info?.accept_terms === "1"
                    ? "Da"
                    : "Ne"}
                </p>
                <p>
                  Saglasan/a sam sa politikom obrade ličnih podataka:{" "}
                  {view_info?.accept_policy === 1 ||
                  view_info?.accept_terms === "1"
                    ? "Da"
                    : "Ne"}
                </p>
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "1rem",
              }}
            >
              <Button
                variant={`contained`}
                color={`error`}
                sx={{ marginRight: "auto" }}
                onClick={() => {
                  setOpenedAccordion(2);
                }}
              >
                Prethodni korak
              </Button>
              <Button
                variant={`contained`}
                color={`success`}
                sx={{ marginLeft: "auto" }}
                onClick={register}
                disabled={isPending}
              >
                Završi registraciju
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};
