import { useQuery } from "@tanstack/react-query";
import FollowingModal from "../../Modal/FollowingModal/FollowingModal";
import { get } from "../../../services/api";
import { Button } from "@mui/material";
import { icons } from "../../../lib/icons";

const UnfollowActionButton = ({ id, onConfirm }) => {
  const { refetch: refetchUnfollowMessage } = useQuery({
    queryKey: ["unfollowMessage", id],
    queryFn: async () => {
      const res = await get(`/portal/profile/companies/message/${id}`);
      if (res?.payload) return res.payload;
    },
    enabled: false,
  });

  return (
    <FollowingModal
      onConfirm={onConfirm}
      renderButton={({ onClick }) => (
        <Button
          onClick={() => {
            refetchUnfollowMessage()?.then((res) => onClick(res.data));
          }}
          color={"error"}
          variant={"contained"}
        >
          {icons.unsave}
        </Button>
      )}
    />
  );
};

export default UnfollowActionButton;
