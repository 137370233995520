import { useMemo } from "react";

export const useValidationSchema = (
  validation,
  formFields,
  translations,
  validationMessages
) => {
  return useMemo(() => {
    if (!translations) return null;
    return validation(formFields, translations, validationMessages);
  }, [validation, formFields, translations, validationMessages]);
};
