import { CloudDownload } from "@material-ui/icons";
import { TableCell } from "@mui/material";
import React from "react";
import CustomTableCellAction from "./CustomTableCellAction";
import DOMPurify from "dompurify";

const CustomTableCell = ({ actions, item, value, column, className, sx }) => {
  let content = value || "-";

  if (column) {
    switch (column.type) {
      case "image":
        content = (
          <img
            src={item[column.prop_name]}
            alt=""
            className="w-8 h-8 rounded-md"
          />
        );
        break;
      case "text":
        content = item[column.prop_name];
        break;
      case "file":
        content = (
          <a
            href={item[column.prop_name]}
            download={`document.${item[column.prop_name]}`}
            target="_blank"
            rel="noreferrer"
          >
            <CloudDownload className="!w-8 !h-8" />
          </a>
        );
        break;
      case "actions":
        content = (
          <>
            {actions &&
              actions.length > 0 &&
              actions.map((action, index) => (
                <CustomTableCellAction
                  key={index}
                  action={action}
                  item={item}
                />
              ))}
          </>
        );
        break;
      default:
        content = item[column.prop_name] || "-";
    }
  }

  const isHTML =
    typeof content === "string" && /<\/?[a-z][\s\S]*>/i.test(content);

  const sanitizedContent = isHTML
    ? DOMPurify.sanitize(content, {
        ALLOWED_TAGS: [
          "p",
          "h1",
          "h2",
          "h3",
          "h4",
          "h5",
          "h6",
          "strong",
          "b",
          "em",
          "i",
          "ul",
          "ol",
          "li",
          "div",
          "span",
          "br",
          "a",
          "img",
        ],
        ALLOWED_ATTR: ["href", "target", "rel", "src", "alt"],
      })
    : content;

  return (
    <TableCell
      className={`${className} !htmlRender`}
      sx={{
        placeItems: sx?.textAlign ?? "center",
        textAlign: sx?.textAlign ?? "center",
        ...sx,
      }}
      {...(isHTML
        ? { dangerouslySetInnerHTML: { __html: sanitizedContent } }
        : { children: content })}
    />
  );
};

export default CustomTableCell;
