import SpiderWeb from "../../../assets/Icons/net.png";
import Filterweb from "../../../assets/Icons/filterweb.png";

const SectionHeader = ({
  text,
  onIconClick,
  iconText = "Paukova mreža",
  filterIcon = false,
  hideIcon = false,
}) => {
  return (
    <header className="mb-5">
      <div className="flex flex-row justify-between items-end border-b border-[#ed545c]">
        <div className="font-medium text-left">
          <span>{text ? text : "Naslov sekcije"}</span>
        </div>
        {!hideIcon && (
          <div className="flex justify-center bg-[#2b343b] rounded-t-[15px] rounded-tl-[15px]">
            <div
              className="text-sm text-white flex flex-row items-center gap-2 px-7 py-[0.2rem] cursor-pointer"
              onClick={onIconClick}
            >
              {filterIcon ? (
                <img src={Filterweb} className="max-w-[30px] h-auto" alt="" />
              ) : (
                <img
                  src={SpiderWeb}
                  className="max-w-[17px] h-auto invert"
                  alt=""
                />
              )}
              <span>{iconText}</span>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default SectionHeader;
