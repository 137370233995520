import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import {
  fetchSisterCompanies,
  fetchSisterCompanyFilters,
  fetchSisterCompanyOwnerFilters,
} from "../data/dataAccess";
import SisterCompaniesTable from "./SisterCompaniesTable";
import SisterCompaniesFilterModal from "./SisterCompaniesFilterModal";

import "./SisterCompaniesTable.css";

const SisterCompanies = ({ companyId }) => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [selectedOwner, setSelectedOwner] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get("companySistersPage")) || 1;

  const {
    data: filtersData,
    isLoading: isLoadingFilters,
    refetch: refetchFilters,
  } = useQuery({
    queryKey: ["sisterCompanyFilters"],
    queryFn: fetchSisterCompanyFilters,
    enabled: false,
  });

  const {
    data: ownerFiltersData,
    isLoading: isLoadingOwnerFilters,
    refetch: refetchOwnerFilters,
  } = useQuery({
    queryKey: ["sisterCompanyOwnerFilters", companyId],
    queryFn: () => fetchSisterCompanyOwnerFilters(companyId),
    enabled: false,
  });

  const { data: sisterCompaniesData = [], isLoading: isLoadingCompanies } =
    useQuery({
      queryKey: [
        "sisterCompanies",
        companyId,
        selectedFilters,
        searchQuery,
        page,
        searchParams,
      ],
      queryFn: () =>
        fetchSisterCompanies(companyId, selectedFilters, searchQuery, page),
    });

  const formattedData = {
    ...sisterCompaniesData,
    items: sisterCompaniesData?.items?.map((item) => ({
      ...item,
      sestra_procenat_vlasnistva: item.sestra_procenat_vlasnistva
        ? parseFloat(item.sestra_procenat_vlasnistva).toFixed(2)
        : "-",
    })),
  };

  const handleOpenModal = () => {
    setModalOpen(true);
    refetchFilters();
    refetchOwnerFilters();
  };

  const applyFilters = () => {
    const filters = [];
    if (selectedType) {
      filters.push({ code: "type", value: { selected: [selectedType] } });
    }
    if (selectedOwner) {
      filters.push({ code: "by_owners", value: { selected: [selectedOwner] } });
    }

    setSelectedFilters(filters);
    setSearchQuery(searchTerm);
    setModalOpen(false);
  };

  const resetFilters = () => {
    setSelectedFilters([]);
    setSelectedType("");
    setSelectedOwner("");
    setSearchTerm("");
    setSearchQuery("");
  };

  const getFormattedFilterSummary = () => {
    const typeName = selectedFilters.find((f) => f.code === "type")?.value
      ?.selected?.[0];
    const ownerId = selectedFilters.find((f) => f.code === "by_owners")?.value
      ?.selected?.[0];

    const typeLabel = filtersData?.find((f) => f.id === typeName)?.name;
    const ownerRaw = ownerFiltersData?.find((f) => f.id === ownerId)?.name;

    const match = ownerRaw?.match(/\(([\d.]+)%\)$/);
    const formattedPercentage = match
      ? `${parseFloat(match[1]).toFixed(2)}%`
      : "";
    const ownerLabel = ownerRaw
      ?.replace(/\(([\d.]+)%\)/, `(${formattedPercentage})`)
      ?.toLowerCase()
      ?.replace(/(^|\s)\S/g, (l) => l.toUpperCase());

    return [
      typeLabel ? `Tip: ${typeLabel}` : null,
      ownerLabel ? `Vlasnik: ${ownerLabel}` : null,
      searchQuery ? `Naziv sadrži: "${searchQuery}"` : null,
    ]
      .filter(Boolean)
      .join(" • ");
  };

  return (
    !!sisterCompaniesData?.items?.length && (
      <section className="sister-companies py-5">
        <SisterCompaniesTable
          data={formattedData}
          isLoading={isLoadingCompanies}
          activeFiltersText={getFormattedFilterSummary()}
          onResetFilters={resetFilters}
          onFilterClick={handleOpenModal}
        />

        <SisterCompaniesFilterModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          filtersData={filtersData}
          ownerFiltersData={ownerFiltersData}
          isLoading={isLoadingFilters || isLoadingOwnerFilters}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          selectedOwner={selectedOwner}
          setSelectedOwner={setSelectedOwner}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          onApply={applyFilters}
        />
      </section>
    )
  );
};

export default SisterCompanies;
