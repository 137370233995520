import { useMutation, useQuery } from "@tanstack/react-query";
import { get, post } from "./api";
import { useContext, useEffect, useState } from "react";
import { userContext } from "./userContext";
import { toast } from "react-toastify";

export const useFilterNames = (ddl_slug, value) => {
  return useQuery({
    queryKey: ["ddl_slug", ddl_slug, value],
    queryFn: async () => {
      if (ddl_slug === "addresses") {
        return await get(
          `portal/companies/search/company/basic/ddl/${ddl_slug}?search=${value}`
        ).then((res) => res?.payload);
      }

      if (ddl_slug === "activities") {
        return await get(
          `portal/companies/search/company/basic/ddl/${ddl_slug}?id=${value}`
        ).then((res) => res?.payload);
      } else {
        return await get(
          `portal/companies/search/company/basic/ddl/${ddl_slug}?id=${value}`
        ).then((res) => res?.payload);
      }
    },
  });
};

export const useTranslate = (page, key) => {
  const { lang } = useContext(userContext);

  const { data, mutate, isPending, isSuccess } = useMutation({
    mutationKey: [
      `${key}`,
      {
        lang: lang,
      },
    ],
    mutationFn: async () => {
      return await post(
        `/portal/translations/list`,
        {
          page: page,
        },
        "TRANSLATIONS_LIST"
      )
        .then((res) => res?.payload)
        ?.catch((err) => console.log(err));
    },
  });

  useEffect(() => {
    if (lang) {
      mutate();
    }
  }, [lang, mutate]);

  return { data, isPending, isSuccess };
};

export const useIsMobile = () => {
  const [is_mobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return is_mobile;
};

export const useExport = () => {
  return useMutation({
    mutationKey: ["data_export"],
    mutationFn: async ({
      search,
      selected_filters,
      selected_sort,
      selected_columns,
    }) => {
      let columns_arr = [];
      (selected_columns?.[0]?.options ?? [])?.forEach((item) => {
        if (item?.visible) {
          columns_arr.push(item?.prop_name);
        }
      });

      return await post(
        `/portal/companies/export`,
        {
          search: search ?? "",
          filters: selected_filters ?? [],
          sort: selected_sort ?? [],
          columns: columns_arr ?? [],
          extension: "xlsx",
        },
        "USERS"
      )?.then((res) => {
        switch (res?.code) {
          default:
            toast.error(res?.data?.message, {
              position: "top-center",
            });
            break;
          case 200:
            toast.success(res?.payload?.message, {
              position: "top-center",
            });
            return res?.payload;
        }

        return res?.payload;
      });
    },
  });
};
