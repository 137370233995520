import React from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  InputLabel,
} from "@mui/material";
import { Spinner } from "../../shared/spinner";

const RelatedCompaniesFilterModal = ({
  open,
  onClose,
  filtersData,
  isLoading,
  selectedFilter,
  setSelectedFilter,
  searchTerm,
  setSearchTerm,
  onApply,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box className="modal-box relative p-6 bg-white rounded-md shadow-md max-w-[500px] mx-auto mt-[10%]">
        <div className="flex justify-between items-center mb-4">
          <Typography variant="h6">Povezane kompanije</Typography>
        </div>

        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <TextField
              fullWidth
              label="Pretraga po imenu kompanije"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="mb-4"
            />

            <FormControl fullWidth margin="normal">
              <InputLabel>Tip povezanosti</InputLabel>
              <Select
                value={selectedFilter}
                onChange={(e) => setSelectedFilter(e.target.value)}
                label="Tip povezanosti"
              >
                <MenuItem value="">Svi tipovi</MenuItem>
                {filtersData?.map((f) => (
                  <MenuItem key={f.id} value={f.id}>
                    {f.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className="flex justify-end mt-4">
              <Button
                variant="contained"
                onClick={onApply}
                className="filter-modal-button"
              >
                Pretraži
              </Button>
            </div>
          </>
        )}

        <div
          className="absolute top-5 right-[18px] w-fit text-black cursor-pointer p-3 hover:text-red-500"
          onClick={onClose}
        >
          <span className="text-lg">X</span>
        </div>
      </Box>
    </Modal>
  );
};

export default RelatedCompaniesFilterModal;
