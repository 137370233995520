import { useQuery } from "@tanstack/react-query";
import React from "react";
import { get } from "../../../services/api";
import CustomTable from "../../CustomTable/ui/CustomTable";

const OurBaseInNumbers = ({ translations }) => {
  const { data } = useQuery({
    queryKey: ["ourBaseInNumbers"],
    queryFn: async () =>
      await get(`portal/homepage/statistics/one`).then((res) => res.payload),
  });

  return (
    <CustomTable
      translations={translations}
      data={data}
      direction={`column`}
      withDefaultColumns
    />
  );
};

export default OurBaseInNumbers;
