import { useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";

export const Pagination = ({
  pagination,
  translate_data,
  className,
  count,
}) => {
  const getPaginationArray = (selectedPage, totalPages) => {
    const start = Math.max(1, selectedPage - 2);
    const end = Math.min(totalPages, start + 4);
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };
  const [params, setParams] = useSearchParams();
  const limit = params.get("limit");
  const filters = params.get("filters");
  const search = params.get("search");
  const sort = params.get("sort");

  const [page, setPage] = useState(Number(params?.get("page")));

  const handleSetParams = ({ page }) => {
    const newParams = {};
    if (limit) newParams.limit = limit;
    if (sort) newParams.sort = sort;
    if (filters) newParams.filters = filters;
    if (search) newParams.search = search;
    if (page) newParams.page = page;
    setParams(newParams);
  };

  return (
    <div
      className={`mt-10 bottom-0 w-full flex items-center justify-between bg-[#232f3e] px-[3rem] py-2`}
    >
      {count > 0 && (
        <i className="text-white">
          {translate_data?.["search"]?.["default"]?.["footer"]?.[
            "show_results"
          ]?.["label"] ?? "Približan broj rezultata"}
          : {count}
        </i>
      )}

      <div className={`flex items-center gap-2 text-sm ml-auto`}>
        {getPaginationArray(
          pagination.selected_page,
          pagination.total_pages,
        ).map((num, index, array) => (
          <>
            {index === 0 && num !== 1 && (
              <>
                <span
                  className={`${className} cursor-pointer select-none rounded-lg border border-white px-3 py-1 font-light hover:border-white hover:bg-[#aa1d23] text-white hover:text-white max-sm:text-[1.2rem]`}
                  onClick={() => {
                    handleSetParams({ page: 1 });
                    window.scrollTo(0, 0);
                  }}
                >
                  1
                </span>
                {num - 1 !== 1 && (
                  <span
                    className={`${className} select-none rounded-lg text-white px-3 py-1 font-light max-sm:text-[1.2rem]`}
                  >
                    ...
                  </span>
                )}
              </>
            )}
            {index > 0 && num - array[index - 1] > 1 && (
              <span
                className={`${className} select-none rounded-lg px-3 py-1 font-light max-sm:text-[1.2rem]`}
              >
                ...
              </span>
            )}
            <span
              className={`${
                num === pagination.selected_page
                  ? `${className} cursor-pointer select-none rounded-lg bg-[#aa1d23] px-3 py-1 font-light border border-white text-white max-sm:text-[1.2rem]`
                  : `${className} cursor-pointer select-none rounded-lg border border-white px-3 py-1 font-light hover:bg-[#aa1d23] text-white max-sm:text-[1.2rem]`
              }`}
              onClick={() => {
                handleSetParams({ page: num });
                window.scrollTo(0, 0);
              }}
            >
              {num}
            </span>
            {index === array.length - 1 && num !== pagination.total_pages && (
              <>
                {pagination.total_pages - num !== 1 && (
                  <span
                    className={`${className} select-none text-white  rounded-lg px-3 py-1 font-light max-sm:text-[1.2rem]`}
                  >
                    ...
                  </span>
                )}
                <span
                  className={`${className} cursor-pointer select-none rounded-lg border border-white px-3 py-1 font-light hover:border-white hover:bg-[#aa1d23] text-white hover:text-white max-sm:text-[1.2rem]`}
                  onClick={() => {
                    handleSetParams({ page: pagination.total_pages });
                    window.scrollTo(0, 0);
                  }}
                >
                  {pagination.total_pages}
                </span>
              </>
            )}
          </>
        ))}
      </div>
    </div>
  );
};
