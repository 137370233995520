import React from "react";
import { useQuery } from "@tanstack/react-query";
import { post } from "../../services/api";
import { useTranslate } from "../../services/hooks";
import NotificationsListTable from "../../components/Notifications/ui/NotificationsListTable";
import { Section } from "../../components/shared/section";
import { useSearchParams } from "react-router-dom";

const NotificationsPage = () => {
  const [searchParams] = useSearchParams();

  const page = +searchParams.get("page") || 1;

  const { data: translations } = useTranslate("notifications", "default") || {};

  const { notifications: { default: notificationsTranslations } = {} } =
    translations || {};

  const { data: notifications } = useQuery({
    queryKey: ["notifications", page],
    queryFn: async () => {
      return await post(`portal/notifications/list/list`, { page }).then(
        (res) => res.payload
      );
    },
  });

  return (
    <Section>
      <NotificationsListTable
        translations={notificationsTranslations?.table}
        data={notifications}
      />
    </Section>
  );
};

export default NotificationsPage;
