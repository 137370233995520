import { Button, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { Visibility } from "@material-ui/icons";

const CustomTableCellAction = ({ action, item }) => {
  let content;

  switch (action.type) {
    case "view":
      content = (
        <Tooltip title={`Prikaži`}>
          <Button variant={`contained`} onClick={() => action.onClick(item)}>
            <Visibility />
          </Button>
        </Tooltip>
      );
  }

  return content;
};

export default CustomTableCellAction;
