import React from "react";
import CustomTable from "../../CustomTable/ui/CustomTable";
import columns from "../data/managementTableColumns.json";

import { Button } from "@mui/material";

const ManagementTable = ({
  data,
  isLoading,
  activeFiltersText,
  onOpenFilter,
  onResetFilters,
}) => {
  return isLoading ? (
    <div className="min-h-[15rem] bg-slate-300 w-full h-full animate-pulse rounded-lg mt-7"></div>
  ) : (
    <CustomTable
      columns={columns}
      data={data || []}
      name="Menadžment"
      isLoading={isLoading}
      translations={{ heading: { title: "Menadžment" } }}
      className="management-table"
      filtersButton={
        <Button
          onClick={onOpenFilter}
          variant="contained"
          color="secondary"
          size="small"
        >
          Pretraži
        </Button>
      }
      activeFiltersText={activeFiltersText}
      onResetFilters={onResetFilters}
      noDataMessage="Nema podataka o menadžmentu."
      pageParamName="managementPage"
    />
  );
};

export default ManagementTable;
