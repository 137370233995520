import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { post } from "../../services/api";

export const SelectInput = ({
  data,
  options,
  isLoading,
  setData,
  refetch,
  openedAccordion,
  setOpenedAccordion,
  setSearchParams,
}) => {
  const {
    mutate,
    data: payload,
    isPending,
  } = useMutation({
    mutationKey: ["countryId", data?.country_id],
    mutationFn: async (country_id) => {
      return await post(`/portal/registration/main/country`, {
        country_id,
        slug: data?.token,
      })
        .then((res) => {
          setData({
            id: res?.payload?.id,
            country_id: data?.country_id,
            slug: res?.payload?.slug,
          });

          setSearchParams({
            slug: res?.payload?.slug,
          });

          const timeout = setTimeout(() => {
            refetch();
          }, 200);
          return () => clearTimeout(timeout);
        })
        ?.catch((err) => err);
    },
  });

  const [selectedValue, setSelectedValue] = useState(data?.country_id || 192);

  const onChange = (event) => {
    setSelectedValue(event.target.value);
    setData({
      country_id: event.target.value,
      slug: data?.slug,
      id: data?.id,
    });
  };

  return (
    <FormControl sx={{ width: "100%", paddingTop: "" }} required={true}>
      <InputLabel>Izaberite državu</InputLabel>
      {!isLoading && (
        <Select value={selectedValue} onChange={onChange}>
          {(options ?? [])?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "1rem",
        }}
      >
        <Button
          variant={`contained`}
          color={`success`}
          sx={{ marginLeft: "auto" }}
          onClick={() => {
            setOpenedAccordion(1);
            mutate(selectedValue);
          }}
        >
          Sledeći korak
        </Button>
      </Box>
    </FormControl>
  );
};
