import React from "react";
import CustomTable from "../../CustomTable/ui/CustomTable";
import columns from "../data/ownershipStructureColumns.json";
import { Button } from "@mui/material";
import "./OwnershipStructure.css";

const OwnershipStructureTable = ({
  data,
  isLoading,
  onOpenFilter,
  activeFiltersText,
  onResetFilters,
}) => {
  return (
    <div>
      {isLoading ? (
        <div className="min-h-[15rem] max-h-[20rem] bg-slate-300 w-full h-full animate-pulse rounded-lg mt-7"></div>
      ) : (
        <CustomTable
          columns={columns}
          data={data}
          name="Vlasnička Struktura"
          className="ownership-structure-table"
          translations={{
            heading: { title: "Vlasnička Struktura" },
          }}
          filtersButton={
            <Button
              onClick={onOpenFilter}
              variant="contained"
              color="secondary"
              size="small"
            >
              Pretraži
            </Button>
          }
          activeFiltersText={activeFiltersText}
          onResetFilters={onResetFilters}
          noDataMessage="Nema podataka o vlasničkoj strukturi."
          pageParamName="ownershipPage"
        />
      )}
    </div>
  );
};

export default OwnershipStructureTable;
