import { post, get } from "../../../services/api";
import { toast } from "react-toastify";

// Fetch related companies
export const fetchRelatedCompanies = async (
  companyId,
  filters = [],
  search = "",
  page = 1
) => {
  try {
    const payload = {
      company_id: Number(companyId),
      page,
      search,
      filters: filters.length > 0 ? filters : [],
      limit: 10,
    };

    const response = await post(
      "portal/companies/other-important-info/related-companies/other-related-companies/list",
      payload,
      "RELATED_COMPANIES_LIST"
    );

    if (response?.code === 200) {
      return response.payload;
    } else {
      toast.error("Greška pri učitavanju povezanih kompanija.");
      return [];
    }
  } catch (error) {
    console.error("❌ Greška:", error);
    toast.error("Greška pri učitavanju povezanih kompanija.");
    return [];
  }
};

// Fetch filters
export const fetchRelatedCompaniesFilters = async () => {
  try {
    const response = await get(
      "portal/companies/other-important-info/related-companies/other-related-companies/ddl/filters"
    );
    return response?.payload || [];
  } catch (error) {
    console.error("❌ Greška pri učitavanju filtera:", error);
    toast.error("Greška pri učitavanju filtera povezanih kompanija.");
    return [];
  }
};
