import React from "react";
import { PaperSection } from "../../../PaperSection/ui/PaperSection";
import { Typography } from "@mui/material";
import { useTranslate } from "../../../../services/hooks";

const SingleNewsDescription = ({ description }) => {
  const { data: translations } = useTranslate("news-details", "default") || {};

  const {
    "news-details": {
      default: { description: descriptionTranslations } = {},
    } = {},
  } = translations || {};

  return (
    <PaperSection>
      <Typography component={`h3`} className="!text-2xl !font-semibold !mb-2">
        {descriptionTranslations?.heading.title}
      </Typography>
      <Typography
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
    </PaperSection>
  );
};

export default SingleNewsDescription;
