import { useState } from "react";
import { useTranslate } from "../../../services/hooks";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const FollowingModal = ({ renderButton, onConfirm }) => {
  const [modal, setModal] = useState({
    isOpen: false,
    message: "",
  });

  const handleOpenModal = (message) =>
    setModal({
      isOpen: true,
      message,
    });

  const handleCloseModal = () => setModal({ isOpen: false, message: "" });

  const { data: modal_translate_data } = useTranslate("global", "GLOBAL");

  return (
    <>
      {renderButton({ onClick: handleOpenModal })}
      <Dialog
        open={modal.isOpen}
        onClose={handleCloseModal}
        className="text-center"
        PaperProps={{
          className: "max-w-[30rem] !rounded-xl",
        }}
        componentsProps={{
          backdrop: {
            className: "bg-black/50",
          },
        }}
      >
        {modal.message?.main_checkbox && (
          <DialogTitle>{modal.message.main_checkbox}</DialogTitle>
        )}
        <DialogContent>{modal.message?.main_line}</DialogContent>
        <DialogActions className="flex !justify-center gap-4 mb-2">
          <Button
            variant={"contained"}
            color={"error"}
            onClick={handleCloseModal}
          >
            {modal_translate_data?.["global"]?.["default"]?.["global"]?.[
              "word"
            ]?.["no"] ?? "Ne"}
          </Button>
          <Button
            variant={"contained"}
            color={"success"}
            onClick={() => {
              onConfirm();
              handleCloseModal();
            }}
          >
            {modal_translate_data?.["global"]?.["default"]?.["global"]?.[
              "word"
            ]?.["yes"] ?? "Da"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FollowingModal;
