import React, { useState } from "react";
import DetailsModal from "../../DetailsModal/ui/DetailsModal";
import "./HexagonCard.css";

const HexagonCard = ({ title, count, items, main, endpoint, companyId }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div className="flex hexagon-card-wrapper">
      <div
        className={`hexagon-card cursor-pointer ${main ? "main" : ""}`}
        onClick={handleOpenModal}
      >
        <div className="hexagon-content">
          <div className="hexagon-title">{title}</div>
          {count !== undefined && (
            <div className="hexagon-count !text-[0.8rem]">{count}</div>
          )}
        </div>
      </div>
      {items?.length > 0 && (
        <div className="flex items-end flex-col w-full hexagon-list-wrapper">
          <ul className="hexagon-list">
            {items?.slice(0, 10).map((item, index) => {
              if (!item || !item.name?.trim()) return null;

              return (
                <li key={index} className="hexagon-item text-center">
                  <span className="hexagon-text">
                    {item.name} {item.value ? `(${item.value})` : ""}
                  </span>
                </li>
              );
            })}
          </ul>
          {items?.length > 10 && (
            <div
              onClick={handleOpenModal}
              className="py-1 px-2 w-full text-center cursor-pointer text-black border border-[#d3e6f5] rounded"
            >
              Vidi sve
            </div>
          )}
        </div>
      )}

      <DetailsModal
        open={openModal}
        onClose={handleCloseModal}
        title={title}
        items={items}
        endpoint={endpoint}
        companyId={companyId}
      />
    </div>
  );
};

export default HexagonCard;
