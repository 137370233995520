import { Close } from "@material-ui/icons";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React from "react";

const CustomModal = ({ isOpen, title, onClose, TopAction, children }) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle sx={{ m: 0, p: 2 }}>{title}</DialogTitle>
      {TopAction && TopAction}
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
        className="!text-gray-500"
      >
        <Close />
      </IconButton>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
};

export default CustomModal;
