import React, { useState, useEffect } from "react";
import { Modal, Box, TextField, Typography, Button } from "@mui/material";
import { post } from "../../../services/api";
import { toast } from "react-toastify";
import "./DetailsModal.css";

const fetchSearchResults = async (endpoint, companyId, search) => {
  try {
    const response = await post(endpoint, {
      company_id: Number(companyId),
      limit: 10,
      search: search,
    });
    if (response?.code === 200) {
      const items = response?.payload?.items || [];

      return items.map((item) => {
        if (item.zastupnik_naziv) {
          return {
            name: item.zastupnik_naziv,
            value: item.zastupnik_godine,
          };
        }
        if (item.vlasnik_naziv) {
          return {
            name: item.vlasnik_naziv,
            value: item.vlasnik_godine,
          };
        }
        if (item.sestra_naziv) {
          return {
            name: item.sestra_naziv,
          };
        }
        if (item.cerka_naziv) {
          return {
            name: item.cerka_naziv,
          };
        }
        return {
          name: "Nepoznato",
          value: "",
        };
      });
    } else {
      toast.error("Greška pri pretrazi podataka.");
      return [];
    }
  } catch (error) {
    console.log("❌ Greška pri pretrazi podataka:", error);
    toast.error("Greška pri pretrazi podataka.");
    return [];
  }
};

const DetailsModal = ({ open, onClose, title, items, endpoint, companyId }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState(items);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = async () => {
    if (searchTerm.length > 2) {
      const results = await fetchSearchResults(endpoint, companyId, searchTerm);
      setSearchResults(results);
    } else {
      setSearchResults(items);
    }
  };

  useEffect(() => {
    setSearchResults(items);
  }, [items]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="details-modal">
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className="!mb-2 flex justify-between items-center"
        >
          {title}
          {searchTerm && (
            <span
              onClick={() => {
                setSearchTerm("");
                setSearchResults(items);
              }}
              className="text-sm text-blue-500 underline cursor-pointer hover:text-blue-700 ml-4"
            >
              Poništi pretragu
            </span>
          )}
        </Typography>
        <Box display="flex" gap="10px" marginBottom="10px">
          <TextField
            label="Pretraga"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={handleInputChange}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            style={{ minWidth: "100px" }}
            className="filter-modal-button"
          >
            Pretraži
          </Button>
        </Box>
        <ul className="modal-list">
          {searchResults?.map((item, index) => {
            if (!item || !item.name?.trim()) return null;
            return (
              <li key={index} className="modal-item">
                {item.name} {item.value ? `(${item.value})` : ""}
              </li>
            );
          })}
        </ul>
        <div
          className="absolute top-0 right-[18px] w-fit text-black cursor-pointer p-3 hover:text-red-500"
          onClick={onClose}
        >
          <span className="text-[16px]">X</span>
        </div>
      </Box>
    </Modal>
  );
};

export default DetailsModal;
