import { CloudDownload } from "@material-ui/icons";
import { IconButton, Typography } from "@mui/material";
import React from "react";
import CustomModal from "../../../CustomModal/ui/CustomModal";

const SingleNewsDocumentDialog = ({ isOpen, document, onClose }) => {
  return (
    <CustomModal
      isOpen={isOpen}
      title={document.title}
      onClose={onClose}
      TopAction={
        <IconButton
          sx={{
            position: "absolute",
            right: 50,
            top: 8,
          }}
          className="!text-gray-500"
          href={document.file}
          download={document.file}
        >
          <CloudDownload />
        </IconButton>
      }
    >
      <Typography gutterBottom>{document.description}</Typography>
      <img src={document.thumb_image} alt="" className="select-none" />
    </CustomModal>
  );
};

export default SingleNewsDocumentDialog;
