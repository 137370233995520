import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Pagination, Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import SingleNewsGallerySlide from "./SingleNewsGallerySlide";

const SingleNewsGallery = ({ gallery }) => {
  return (
    <Swiper
      modules={[Navigation, Pagination, A11y]}
      slidesPerView={3}
      spaceBetween={20}
      direction="horizontal"
      pagination={{
        clickable: true,
      }}
      navigation
      className="w-full !select-none !pb-8 !px-5"
    >
      {gallery.map((image, index) => (
        <SwiperSlide key={index} className="!flex !justify-center">
          <SingleNewsGallerySlide index={index} image={image} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SingleNewsGallery;
