import { Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";

/**
 * Custom Typography component
 * @param {Object} props
 * @param {'date' | 'text-array' | 'text' | 'default'} props.type - The type of the content
 * @param {any} props.value - The value to render
 * @param {import('@mui/material').TypographyProps} props.rest - MUI Typography props (e.g., sx, className, etc.)
 * @returns {JSX.Element}
 */
const CustomTypography = ({ data, prop_name, type, value, ...rest }) => {
  let content;

  switch (type) {
    case "date":
      content = dayjs(data.basic_data[prop_name].date_time).format(
        "DD.MM.YYYY"
      );
      break;
    case "text-array":
      content = data[prop_name].map((element) => element.name).join(", ");
      break;
    case "text":
      content = data.basic_data[prop_name];
      break;
    default:
      content = data.basic_data[prop_name];
  }

  return <Typography {...rest}>{content || "/"}</Typography>;
};

export default CustomTypography;
