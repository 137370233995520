import { post, get } from "../../../services/api";
import { toast } from "react-toastify";

export const fetchCompanyDaughters = async (
  companyId,
  filters = [],
  search = "",
  page = 1
) => {
  try {
    const payload = {
      company_id: Number(companyId),
      page,
      search,
      filters: filters.length > 0 ? filters : [],
      limit: 10,
    };

    const response = await post(
      "portal/companies/other-important-info/related-companies/companies-daughters/list",
      payload,
      "COMPANY_DAUGHTERS_LIST"
    );

    if (response?.code === 200) {
      return response.payload;
    } else {
      toast.error("Greška pri učitavanju kompanija ćerki.");
      return [];
    }
  } catch (error) {
    console.error("❌ Greška pri učitavanju kompanija ćerki:", error);
    toast.error("Greška pri učitavanju kompanija ćerki.");
    return [];
  }
};

export const fetchCompanyDaughtersFilters = async () => {
  try {
    const response = await get(
      "portal/companies/other-important-info/related-companies/companies-daughters/ddl/filters"
    );
    if (response?.code === 200) {
      return response?.payload || [];
    } else {
      toast.error("Greška pri učitavanju filtera kompanija ćerki.");
      return [];
    }
  } catch (error) {
    console.error("❌ Greška pri učitavanju filtera kompanija ćerki:", error);
    toast.error("Greška pri učitavanju filtera kompanija ćerki.");
    return [];
  }
};
