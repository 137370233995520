import { TextField } from "@mui/material";
import React from "react";

const CustomInput = ({
  variant,
  label,
  type,
  value,
  onChange,
  error,
  helperText,
}) => {
  return (
    <TextField
      variant={variant}
      label={label}
      type={type || "text"}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      sx={{
        "& .MuiOutlinedInput-root": {
          "& input": {
            "&:focus": {
              boxShadow: "none",
              outline: "none",
            },
          },
        },
        "& .MuiOutlinedInput-notchedOutline legend span": {
          fontSize: "0.75rem",
        },
      }}
    />
  );
};

export default CustomInput;
