import React from "react";
import HexagonCard from "../../HexagonCard/ui/HexagonCard";
import SectionHeader from "../../SectionHeader/ui/SectionHeader";
import "./HexagonGraph.css";

const HexagonGraph = ({ data, companyId }) => {
  const mainHexagon = data.find((hex) => hex.main);
  const otherHexagons = data.filter((hex) => !hex.main);

  return (
    <section>
      <div className="@container">
        <div className="bg-white shadow-xl rounded-lg px-[1rem] max-sm:px-[1rem] mt-7 pt-5 pb-[2rem]">
          <SectionHeader text="Paukova mreža" hideIcon />
          <div className="hexagon-graph">
            <div className="hexagon-side left">
              {otherHexagons.slice(0, 2).map((hexagon, index) => (
                <HexagonCard
                  key={index}
                  title={hexagon.title}
                  count={hexagon.count}
                  items={hexagon.items}
                  endpoint={hexagon.endpoint}
                  companyId={companyId}
                />
              ))}
            </div>
            <div className="hexagon-center">
              <HexagonCard
                title={mainHexagon.title}
                count={mainHexagon.count}
                items={mainHexagon.items}
                main={true}
              />
            </div>
            <div className="hexagon-side right">
              {otherHexagons.slice(2).map((hexagon, index) => (
                <HexagonCard
                  key={index}
                  title={hexagon.title}
                  count={hexagon.count}
                  items={hexagon.items}
                  endpoint={hexagon.endpoint}
                  companyId={companyId}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HexagonGraph;
