import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { post } from "../../../services/api";
import { ArrowForward, Event } from "@material-ui/icons";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const RecentNews = ({ translations }) => {
  const { data } = useQuery({
    queryKey: ["categoryNews"],
    queryFn: async () =>
      await post(`/portal/news/category/list`).then((res) => res.payload),
  });

  const navigate = useNavigate();

  return (
    <Paper elevation={4} className="p-4 flex flex-col gap-3">
      <Typography component={`h2`} variant={`h5`} className="!font-semibold">
        {translations?.heading.title}
      </Typography>
      <Grid
        container
        columnSpacing={2}
        rowGap={2}
        sx={{
          display: "grid",
          gridTemplateColumns: {
            sm: "repeat(2, 1fr)",
          },
          pb: 1,
        }}
      >
        {data?.items?.map((item, index) => (
          <Grid item sm={6} key={item.id} className="!max-w-full">
            <Paper key={item.id} elevation={4}>
              <Stack spacing={2}>
                <Box position={`relative`} className="h-56">
                  <img
                    src={item.images.thumb_image}
                    alt=""
                    className="w-full h-full object-cover object-center"
                  />
                  <Box
                    className="absolute bottom-0 w-full text-white text-center py-2 "
                    sx={{
                      bgcolor: "background.complementary",
                      color: "text.complementary",
                    }}
                  >
                    <Typography
                      component={`h3`}
                      variant={`h5`}
                      fontWeight={500}
                      textTransform={`uppercase`}
                    >
                      {item.basic_data.title}
                    </Typography>
                  </Box>
                  <Box
                    className="absolute top-2 right-3 rounded-full text-white py-1 px-2"
                    sx={{ bgcolor: "primary.main" }}
                  >
                    <Typography className="!text-sm !font-semibold">
                      {translations?.new_badge.label}
                    </Typography>
                  </Box>
                  <Box className="absolute top-2 left-3 flex text-white flex-col gap-1">
                    {data?.categories?.map((category) => (
                      <Grid
                        key={category.id}
                        className="rounded-full py-1 px-2"
                        sx={{ bgcolor: "background.dark" }}
                      >
                        <Typography className="!text-sm !font-semibold">
                          {category.name}
                        </Typography>
                      </Grid>
                    ))}
                  </Box>
                </Box>
                <Stack px={1} pb={1} spacing={1}>
                  <p className="line-clamp-3">
                    {item.basic_data.short_description}
                  </p>
                  <Grid
                    container
                    justifyContent={`space-between`}
                    alignItems={`center`}
                  >
                    <Grid item display={`flex`} gap={1} alignItems={`center`}>
                      <Event />
                      <p>
                        {dayjs(item.basic_data.created_at.date_time).format(
                          "DD.MM.YYYY"
                        )}
                      </p>
                    </Grid>
                    <Grid item>
                      <Button
                        color={`primary`}
                        variant={`contained`}
                        endIcon={<ArrowForward />}
                        sx={{
                          borderRadius: 10,
                        }}
                        onClick={() => navigate(`/portal/news/${item.id}`)}
                      >
                        {translations?.more_button.label}
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default RecentNews;
