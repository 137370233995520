import { useQuery } from "@tanstack/react-query";
import React from "react";
import { get } from "../../../../services/api";
import { useParams } from "react-router-dom";
import { Grid, Stack } from "@mui/material";
import SingleNewsGallery from "./SingleNewsGallery";
import SingleNewsDocumentsTable from "./SingleNewsDocumentsTable";
import { PaperSection } from "../../../PaperSection/ui/PaperSection";
import CustomStackStyled from "../../../CustomStack/styled/CustomStackStyled";
import SingleNewsHeading from "./SingleNewsHeading";
import SingleNewsDescription from "./SingleNewsDescription";
import BackButton from "../../../BackButton/ui/BackButton";

const SingleNews = () => {
  const { newsId } = useParams();

  const { data } = useQuery({
    queryKey: ["new", newsId],
    queryFn: async () =>
      await get(`/portal/news/details/${newsId}`).then((res) => res.payload),
  });

  return (
    <Grid className="px-11 py-4">
      <BackButton />
      <Grid item sm={12}>
        <PaperSection>
          {data && (
            <CustomStackStyled className="p-2" gap={2}>
              <SingleNewsHeading data={data} />
              <Grid container spacing={2}>
                <Grid item sm={7}>
                  <Stack gap={4}>
                    <SingleNewsDescription
                      description={data.basic_data.description}
                    />
                  </Stack>
                </Grid>
                <Grid item sm={5}>
                  <Stack gap={4}>
                    <PaperSection>
                      <Stack
                        gap={2}
                        className="!relative !w-full !overflow-hidden"
                      >
                        <img
                          src={data.images.thumb_image}
                          className="w-full max-h-[50vh] object-cover object-center border-2 border-primary-main rounded-md"
                          alt=""
                        />
                        {data.gallery.length > 0 && (
                          <SingleNewsGallery gallery={data.gallery} />
                        )}
                      </Stack>
                    </PaperSection>
                    {data.documents.length > 0 && (
                      <SingleNewsDocumentsTable data={data.documents} />
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </CustomStackStyled>
          )}
        </PaperSection>
      </Grid>
    </Grid>
  );
};

export default SingleNews;
