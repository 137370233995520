import { Delete } from "@material-ui/icons";
import {
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";

const ComboBoxSelect = ({
  label,
  options,
  selectedOptions,
  disabled,
  onChange,
}) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const handleDelete = (value) => {
    onChange(selectedOptions.filter((option) => option !== value));
  };

  return (
    <FormControl disabled={disabled} fullWidth>
      <InputLabel color={`error`}>{label}</InputLabel>
      <Select
        multiple
        label={label}
        variant={`outlined`}
        color={`error`}
        value={selectedOptions || []}
        onChange={handleChange}
        renderValue={(selected) => (
          <Grid container gap={1}>
            {selected.map((value) => {
              const option = options.find((opt) => opt.id === value);

              return (
                <Chip
                  key={value}
                  label={option?.name}
                  deleteIcon={<Delete />}
                  onDelete={() => handleDelete(value)}
                  onMouseDown={(event) => event.stopPropagation()}
                />
              );
            })}
          </Grid>
        )}
        MenuProps={{
          PaperProps: {
            style: { maxHeight: 5 * 54 + 8 },
          },
        }}
        sx={{
          "& .MuiOutlinedInput-notchedOutline legend span": {
            fontSize: "0.75rem",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            <Checkbox
              color={`error`}
              checked={selectedOptions?.includes(option.id)}
            />
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ComboBoxSelect;
