import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

export default function formatDate(date, format, inputFormat) {
  if (inputFormat) {
    return dayjs(date, inputFormat).format(format);
  }

  return dayjs(date).format(format);
}
