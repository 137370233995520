import { useQuery } from "@tanstack/react-query";
import { get } from "../../../services/api";
import CustomTable from "../../CustomTable/ui/CustomTable";
import { Fragment, useState } from "react";
import ContractMoreScoringPackagesList from "./ContractMoreScoringPackagesList";
import { Button, Typography } from "@mui/material";

const ContractScoringPackagesTable = ({ contractsId, translations }) => {
  const [areShownMoreOptions, setAreShownMoreOptions] = useState(false);

  const { data, refetch } = useQuery({
    queryKey: ["scoring_packages", contractsId],
    queryFn: async () =>
      await get(`/portal/profile/scoring-packages/${contractsId}`).then(
        (res) => res.payload
      ),
  });

  const handleCloseMorePackagesList = () => setAreShownMoreOptions(false);

  return (
    <Fragment>
      {data && (
        <Fragment>
          <CustomTable
            name={translations?.scoring_packages_table?.heading?.title}
            data={data.scoring_packages}
            className={`guideline-target-element`}
            targetId={`selected_scoring_packages`}
          />
          <CustomTable
            name={translations?.scoring_functions_table?.heading?.title}
            data={data.scoring_packages_items}
            className={`guideline-target-element`}
            targetId={`selected_scoring_functions`}
          />
          <Typography>
            {translations?.need_more?.question?.label}{" "}
            <Button
              color={`error`}
              onClick={() => setAreShownMoreOptions((prev) => !prev)}
            >
              {
                translations?.[areShownMoreOptions ? "show_less" : "show_more"]
                  ?.button?.label
              }
            </Button>
          </Typography>
          {areShownMoreOptions && (
            <ContractMoreScoringPackagesList
              contractsId={contractsId}
              onClose={handleCloseMorePackagesList}
              translations={translations?.input}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default ContractScoringPackagesTable;
