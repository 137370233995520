export const joyrideTranslationElements = {
  sr_RS: {
    open: "Otvori uvod",
    back: "Nazad",
    close: "Zatvori",
    next: "Sledeće",
    skip: "Preskoči",
    nextLabelWithProgress: "Sledeće (Korak {step} od {steps})",
    last: "Završi",
  },
  en_US: {
    open: "Open the guideline",
    back: "Back",
    close: "Close",
    next: "Next",
    skip: "Skip",
    nextLabelWithProgress: "Next (Step {step} of {steps})",
    last: "Finish",
  },
  de_DE: {
    open: "Richtlinie öffnen",
    back: "Zurück",
    close: "Schließen",
    next: "Weiter",
    skip: "Überspringen",
    nextLabelWithProgress: "Weiter (Schritt {step} von {steps})",
    last: "Fertig",
  },
  it_IT: {
    open: "Apri la guida",
    back: "Indietro",
    close: "Chiudi",
    next: "Avanti",
    skip: "Salta",
    nextLabelWithProgress: "Avanti (Passo {step} di {steps})",
    last: "Fine",
  },
};

export const saveButtonTranslations = {
  sr_RS: "Sačuvaj",
  en_US: "Save",
  de_DE: "Speichern",
  it_IT: "Salvalo",
};

export const defaultTableColumnNames = {
  sr_RS: {
    title: "Naziv",
    value: "Vrednost",
  },
  en_US: {
    title: "Title",
    value: "Value",
  },
  de_DE: {
    title: "Titel",
    value: "Wert",
  },
  it_IT: {
    title: "Titolo",
    value: "Valore",
  },
};
