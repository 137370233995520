import { useQuery } from "@tanstack/react-query";
import ContractInfoTable from "../../components/Contract/ui/ContractInfoTable";
import { get } from "../../services/api";
import { Stack } from "@mui/material";
import ContractScoringPackagesTable from "../../components/Contract/ui/ContractScoringPackagesTable";
import { Section } from "../../components/shared/section";
import ContractAdvisor from "../../components/Contract/ui/ContractAdvisor";
import PageWithJoyride from "../../components/Joyride/ui/PageWithJoyride";
import { useTranslate } from "../../services/hooks";

const initialSteps = [
  {
    content: "Welcome to contract!",
    target: "#contract_info",
    placement: "right",
  },
  {
    content: "Second contract info",
    target: "#selected_scoring_packages",
    placement: "bottom",
  },
  {
    content: "THIRD contract info",
    target: "#selected_scoring_functions",
    placement: "top",
  },
];

const ContractPage = () => {
  const { data, refetch } = useQuery({
    queryKey: ["contracts"],
    queryFn: async () => {
      return await get(`/portal/profile/contracts`).then(
        (res) => res.payload || {}
      );
    },
  });

  const { data: translations } = useTranslate("contract", "default") || {};

  const { contract: { default: contractTranslations } = {} } =
    translations || {};

  return (
    <PageWithJoyride pageName="contract" initialSteps={initialSteps}>
      <Section className="!w-full">
        <Stack gap={4}>
          {data && (
            <>
              <ContractInfoTable
                data={data}
                translations={contractTranslations?.basic_info_table}
              />
              <ContractScoringPackagesTable
                contractsId={data.id}
                translations={contractTranslations}
              />
            </>
          )}
        </Stack>
      </Section>
    </PageWithJoyride>
  );
};

export default ContractPage;
