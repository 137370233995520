import { post, get } from "../../../services/api";
import { toast } from "react-toastify";

export const fetchOwnershipStructure = async (
  companyId,
  filters = [],
  search = "",
  page = 1
) => {
  try {
    const payload = {
      company_id: Number(companyId),
      page,
      search,
      filters: Array.isArray(filters) ? filters : [],
      limit: 10,
    };

    const response = await post(
      "portal/companies/other-important-info/ownership-structure/list",
      payload,
      "OWNERSHIP_STRUCTURE_LIST"
    );

    if (response?.code === 200) {
      return response.payload;
    } else {
      toast.error("Greška pri učitavanju vlasničke strukture.");
      return [];
    }
  } catch (error) {
    console.error("❌ Greška pri učitavanju vlasničke strukture:", error);
    toast.error("Greška pri učitavanju vlasničke strukture.");
    return [];
  }
};

// Fetch Filter Options for Ownership Structure
export const fetchOwnershipFilters = async () => {
  try {
    const response = await get(
      "portal/companies/other-important-info/ownership-structure/ddl/filters"
    );
    if (response?.code === 200) {
      return response?.payload || {};
    } else {
      toast.error("Greška pri učitavanju filtera vlasničke strukture.");
      return [];
    }
  } catch (error) {
    console.error(
      "❌ Greška pri učitavanju filtera vlasničke strukture:",
      error
    );
    toast.error("Greška pri učitavanju filtera vlasničke strukture.");
    return [];
  }
};
