import React, { useContext, useState } from "react";
import ComboBoxSelect from "../../ComboBoxSelect/ui/ComboBoxSelect";
import { useMutation, useQuery } from "@tanstack/react-query";
import { get, post } from "../../../services/api";
import { Button, Grid, Stack } from "@mui/material";
import { toast } from "react-toastify";
import { saveButtonTranslations } from "../../../utils/translations";
import { userContext } from "../../../services/userContext";

const ContractMoreScoringPackagesList = ({
  contractsId,
  onClose,
  translations,
}) => {
  const [selectedOptions, setSelectedOptions] = useState({
    scoring_packages: [],
    scoring_packages_items: [],
  });

  const { lang } = useContext(userContext);

  const { data, refetch } = useQuery({
    queryKey: ["more_scoring_packages"],
    queryFn: async () =>
      await get(
        `/portal/profile/scoring-packages/more/packages/${contractsId}`
      ).then((res) => res.payload),
    refetchOnWindowFocus: false,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      return await post(`/portal/profile/scoring-packages/more/packages`, {
        contracts_id: contractsId,
        ...selectedOptions,
      }).then((res) => res.payload);
    },
    onSuccess: (payload) => {
      onClose();
      toast.success(payload?.message);
    },
    onError: (payload) => toast.error(payload?.message),
  });

  const handleSelectOption = (type, options) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [type]: options,
    }));
  };

  return (
    <>
      {data && (
        <Stack spacing={3}>
          <Grid container gap={3}>
            <Grid item xs={12}>
              <ComboBoxSelect
                disabled={isPending}
                label={translations?.scoring_packages?.label}
                options={data?.scoring_packages}
                selectedOptions={selectedOptions?.scoring_packages}
                onChange={(options) =>
                  handleSelectOption("scoring_packages", options)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <ComboBoxSelect
                disabled={isPending}
                label={translations?.scoring_functions?.label}
                options={data?.scoring_packages_items}
                selectedOptions={selectedOptions?.scoring_packages_items}
                onChange={(options) =>
                  handleSelectOption("scoring_packages_items", options)
                }
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Button
              color={`error`}
              variant={`contained`}
              onClick={mutate}
              disabled={isPending}
            >
              {saveButtonTranslations[lang]}
            </Button>
          </Grid>
        </Stack>
      )}
    </>
  );
};

export default ContractMoreScoringPackagesList;
