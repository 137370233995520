import { Grid, Stack } from "@mui/material";
import RecentNews from "../../components/News/RecentNews/RecentNews";
import ExchangeRateList from "../../components/ExchangeRateList/ui/ExchangeRateList";
import OurBaseInNumbers from "../../components/OurBaseInNumbers/ui/OurBaseInNumbers";
import SubjectsBase from "../../components/SubjectsBase/ui/SubjectsBase";
import { useTranslate } from "../../services/hooks";

const Dashboard = () => {
  const { data: translations } = useTranslate("dashboard", "default") || {};

  const {
    dashboard: {
      default: { recent_news: recentNewsTranslations } = {},
      exchange_rate_list: exchangeRateListTranslations,
      our_base_in_numbers: ourBaseInNumbersTranslations,
      subjects_base: subjectsBaseTranslations,
    } = {},
  } = translations || {};

  return (
    <>
      <Stack className="p-6" gap={2}>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <RecentNews translations={recentNewsTranslations} />
          </Grid>
          <Grid
            item
            sm={8}
            sx={{ display: `flex`, flexDirection: `column`, gap: 2 }}
          >
            <ExchangeRateList
              translations={exchangeRateListTranslations?.table}
            />
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <OurBaseInNumbers
                  translations={ourBaseInNumbersTranslations?.table}
                />
              </Grid>
              <Grid item sm={6}>
                <SubjectsBase translations={subjectsBaseTranslations?.table} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm={4}></Grid>
        </Grid>
      </Stack>
    </>
  );
};
export default Dashboard;
