import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import React from "react";
import { Section } from "../../components/shared/section";
import PersonalInfoTable from "../../components/PersonalInfo/ui/PersonalInfoForm";
import { useMutation } from "@tanstack/react-query";
import { post } from "../../services/api";
import { toast } from "react-toastify";
import { useOutletContext } from "react-router-dom";

const BasicData = () =>
  //     {
  //   selected,
  //   handleChange,
  //   languages_ddl,
  //   countries_ddl,
  //   data,
  //   refetch,
  //   onParamsSearchChange,
  //   translate_data_profile,
  // }
  {
    const {
      data,
      refetch,
      selected,
      onParamsSearchChange,
      handleChange,
      countries_ddl,
      languages_ddl,
      translate_data_profile,
    } = useOutletContext();

    const { mutate, isPending } = useMutation({
      mutationKey: ["save"],
      mutationFn: async () => {
        onParamsSearchChange(selected?.language ?? data?.lang);
        return await post(
          `/portal/profile/configs`,
          {
            id: data?.id ?? "",
            country: selected?.country ?? data?.country,
            lang: selected?.language ?? data?.lang,
          },
          "USERS"
        )?.then((res) => {
          if (res?.code === 200) {
            refetch();
            toast.success(res?.payload?.message ?? "Uspešno sačuvano!", {
              position: "top-center",
            });
          } else {
            toast.error("Greška prilikom čuvanja!", {
              position: "top-center",
            });
          }
        });
      },
    });

    return (
      <Stack gap={2}>
        <Section className={`grid grid-cols-3 gap-5`}>
          <div className={`col-span-full`}>
            <h2 className={`mb-5`}>
              {translate_data_profile?.["profile"]?.["basic-data"]?.[
                "header"
              ]?.["form"]?.["title"] ?? "Osnovne informacije o korisniku"}
            </h2>
          </div>
          <div className={`col-span-1`}>
            <FormControl fullWidth>
              <InputLabel htmlFor="country">
                {translate_data_profile?.["profile"]?.["basic-data"]?.[
                  "header"
                ]?.["form_country_input"]?.["label"] ?? "Država"}
              </InputLabel>
              <Select
                fullWidth
                value={selected?.country ?? data?.country}
                id={"country"}
                onChange={({ target: { value } }) => {
                  handleChange(value, "country");
                }}
                label={"Država"}
                placeholder={"Država"}
                name={"country"}
              >
                {(countries_ddl ?? []).map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={`col-span-1`}>
            <FormControl fullWidth>
              <InputLabel htmlFor="country">
                {translate_data_profile?.["profile"]?.["basic-data"]?.[
                  "header"
                ]?.["form_country_language"]?.["label"] ?? "Jezik"}
              </InputLabel>
              <Select
                fullWidth
                value={selected?.language ?? data?.lang}
                id={"language"}
                onChange={({ target: { value } }) => {
                  handleChange(value, "language");
                }}
                label={"Jezik"}
                placeholder={"Jezik"}
                name={"language"}
              >
                {(languages_ddl ?? []).map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={`col-span-full flex`}>
            <Button
              disabled={isPending}
              onClick={() => {
                mutate();
              }}
              color={"success"}
              variant={"contained"}
              sx={{ marginLeft: "auto" }}
            >
              {translate_data_profile?.["profile"]?.["basic-data"]?.[
                "header"
              ]?.["form_submit_button"]?.["label"] ?? "Sačuvaj"}
            </Button>
          </div>
        </Section>
        <PersonalInfoTable />
      </Stack>
    );
  };

export default BasicData;
