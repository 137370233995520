import React from "react";
import CustomTable from "../../CustomTable/ui/CustomTable";
import columns from "../data/sisterCompaniesColumns.json";
import { Button } from "@mui/material";

const SisterCompaniesTable = ({
  data,
  isLoading,
  activeFiltersText,
  onResetFilters,
  onFilterClick,
}) => {
  return (
    <CustomTable
      columns={columns}
      data={data}
      name="Kompanije sestre"
      isLoading={isLoading}
      activeFiltersText={activeFiltersText}
      onResetFilters={onResetFilters}
      filtersButton={
        <Button
          onClick={onFilterClick}
          variant="contained"
          color="secondary"
          size="small"
        >
          Pretraži
        </Button>
      }
      pageParamName="companySistersPage"
    />
  );
};

export default SisterCompaniesTable;
