import { useContext, useEffect } from "react";
import Joyride, { STATUS } from "react-joyride";
import { useJoyride } from "../../../context/JoyrideContext";
import { prepareSteps } from "../../../helpers/stepsHelpers";
import { useQuery } from "@tanstack/react-query";
import { post } from "../../../services/api";
import { joyrideTranslationElements } from "../../../utils/translations";
import { userContext } from "../../../services/userContext";

const PageWithJoyride = ({ pageName, initialSteps, children }) => {
  const { run, stopTour, steps, setSteps } = useJoyride();
  const { lang } = useContext(userContext);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      stopTour();
    }
  };

  const { data } = useQuery({
    queryKey: ["guidelines", pageName],
    queryFn: async () =>
      await post(`portal/translations/guidelines/list`, {
        page: pageName,
      }).then((res) => res.payload),
  });

  useEffect(() => {
    if (data) {
      const updatedSteps = initialSteps.map((step, index) => ({
        ...step,
        title: data[`step-${(index + 1) * 10}`]?.title,
        content: data[`step-${(index + 1) * 10}`]?.body,
      }));
      setSteps(prepareSteps(updatedSteps));
    }
  }, [data, initialSteps, setSteps]);

  return (
    <>
      {children}
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={run}
        scrollOffset={64}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={steps}
        locale={joyrideTranslationElements[lang]}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
    </>
  );
};

export default PageWithJoyride;
