import { handleAutocomplete } from "./functions";

export const Input = ({
  data,
  setData,
  id,
  name,
  label,
  type,
  isReset,
  image,
  key,
}) => {
  return (
    <div className="flex flex-col gap-1 w-full">
      <label htmlFor={id} className={`text-white text-[.9rem] font-light`}>
        {label}
      </label>
      <div className={`relative w-full`}>
        <input
          key={key}
          id={id}
          pattern={
            type === `email` ? `[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$` : null
          }
          name={name}
          type={type}
          className={`rounded-xl bg-white/30 2xl:h-[2.9rem] 3xl:h-[3.4rem] text-white placeholder:text-white pl-16 w-full focus:border-[#aa1d23] focus:ring-0`}
          value={data?.[name]}
          onChange={({ target: { value } }) => {
            setData({ ...data, [name]: value });
          }}
          autoComplete={handleAutocomplete(isReset, type)}
        />
        <img
          src={image}
          width={33}
          height={33}
          className={`absolute invert left-4 top-1/2 transform -translate-y-1/2`}
          alt={``}
        />
      </div>
    </div>
  );
};
