import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import OwnershipStructureTable from "./OwnershipStructureTable";
import OwnershipStructureFilterModal from "./OwnershipStructureFilterModal";
import {
  fetchOwnershipStructure,
  fetchOwnershipFilters,
} from "../data/dataAccess";

import "./OwnershipStructure.css";

const OwnershipStructure = ({ companyId }) => {
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [appliedType, setAppliedType] = useState("");
  const [appliedPercentage, setAppliedPercentage] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedPercentage, setSelectedPercentage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get("ownershipPage")) || 1;

  useEffect(() => {
    setSelectedFilter([]);
  }, []);

  const {
    data: filtersData,
    isLoading: isLoadingFilters,
    refetch: refetchFilters,
  } = useQuery({
    queryKey: ["ownershipFilters"],
    queryFn: fetchOwnershipFilters,
    enabled: false,
  });

  const { data: ownershipData, isLoading: isLoadingOwnership } = useQuery({
    queryKey: [
      "ownershipStructure",
      companyId,
      selectedFilter,
      searchQuery,
      page,
    ],
    queryFn: () =>
      fetchOwnershipStructure(companyId, selectedFilter, searchQuery, page),
  });

  const formattedData = {
    ...ownershipData,
    items: ownershipData?.items?.map((item, index) => ({
      ...item,
      id: index + 1,
      vlasnik_naziv: item.vlasnik_naziv
        ? `${item.vlasnik_naziv}${item.vlasnik_godine ? ` (${item.vlasnik_godine})` : ""}`
        : "Nepoznato",
      vlasnik_procenat: item.vlasnik_procenat
        ? parseFloat(item.vlasnik_procenat).toFixed(2)
        : "0.00",
      vlasnik_tip: item.vlasnik_tip || "Nepoznato",
    })),
  };

  const handleOpenModal = () => {
    setModalOpen(true);
    refetchFilters();
  };

  const handleCloseModal = () => setModalOpen(false);

  const applyFilters = () => {
    const mapped = [];
    if (selectedType) {
      mapped.push({ code: "type", value: { selected: [selectedType] } });
    }
    if (selectedPercentage) {
      mapped.push({
        code: "percentage",
        value: { selected: [selectedPercentage] },
      });
    }

    setSelectedFilter(mapped);
    setSearchQuery(searchTerm);
    setAppliedType(selectedType);
    setAppliedPercentage(selectedPercentage);
    setModalOpen(false);
  };

  const resetFilters = () => {
    setSelectedType("");
    setAppliedType("");
    setAppliedPercentage("");
    setSelectedPercentage("");
    setSearchTerm("");
    setSearchQuery("");
    setSelectedFilter([]);
  };

  const getFormattedFilterSummary = () => {
    const typeName = filtersData?.types?.filters?.find(
      (f) => f.id === appliedType
    )?.name;
    const percName = filtersData?.percentages?.filters?.find(
      (f) => f.id === appliedPercentage
    )?.name;
    return [
      typeName ? `Tip: ${typeName}` : null,
      percName ? `Udeo: ${percName}` : null,
      searchQuery ? `Naziv sadrži: "${searchQuery}"` : null,
    ]
      .filter(Boolean)
      .join(" • ");
  };

  return (
    <section className="ownership-structure pb-5">
      <OwnershipStructureFilterModal
        open={modalOpen}
        onClose={handleCloseModal}
        filtersData={filtersData}
        isLoadingFilters={isLoadingFilters}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        selectedPercentage={selectedPercentage}
        setSelectedPercentage={setSelectedPercentage}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        onApply={applyFilters}
      />

      <OwnershipStructureTable
        data={formattedData}
        isLoading={isLoadingOwnership}
        columnsPath={"../data/ownershipStructureColumns.json"}
        onOpenFilter={() => handleOpenModal()}
        activeFiltersText={getFormattedFilterSummary()}
        onResetFilters={resetFilters}
      />
    </section>
  );
};

export default OwnershipStructure;
