import { Navigate } from "react-router-dom";
import LoginScreen from "../pages/LoginScreen/LoginScreen";
import DemoRequest from "../pages/DemoRequest/DemoRequest";
import DemoRequestForm from "../pages/DemoRequest/DemoRequestForm/DemoRequestForm";
import DemoRequestSuccess from "../pages/DemoRequest/DemoRequestForm/DemoRequestSuccess";
import PasswordReset from "../pages/PasswordReset/PasswordReset";
import Dashboard from "../pages/Dashboard/Dashboard";
import { SearchPage } from "../pages/SearchPage/SearchPage";
import CompanyProfile from "../pages/CompanyProfile/CompanyProfile";
import { RegistrationSteps } from "../pages/DemoRequest/registration-steps";
import { ExportPage } from "../pages/Export/export";
import ContractPage from "../pages/Contract/ContractPage";
import SingleNews from "../components/News/SingleNews/ui/SingleNews";
import NotificationsPage from "../pages/Notifications/NotificationsPage";
import NotificationsSingleNotificationPage from "../pages/Notifications/NotificationsSingleNotificationPage";
import BasicData from "../pages/Account/BasicData";
import Limits from "../pages/Account/Limits";
import CompaniesFollowingList from "../pages/Account/CompaniesFollowingList";
import { SidebarProfile } from "../UI/SidebarProfile/sidebar-profile";

//rute koje mogu da vide ulogovani/izlogovani korisnici

const routes = [
  {
    path: "/",
    element: <Navigate to={`/login`} />,
    authorized: false,
  },
  {
    path: "/login",
    element: <LoginScreen />,
    authorized: false,
  },
  {
    path: "/registracija",
    element: <DemoRequest />,
    authorized: false,
  },
  {
    path: "/registracija/*",
    element: <DemoRequestForm />,
    authorized: false,
  },
  {
    path: "/registracija/*/uspesno",
    element: <DemoRequestSuccess />,
    authorized: false,
  },
  {
    path: "/reset-lozinke/:token",
    element: <PasswordReset />,
    authorized: false,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    authorized: true,
  },
  {
    path: "/pretraga",
    element: <SearchPage />,
    authorized: true,
  },
  {
    path: "/company-profile/:page/:id",
    element: <CompanyProfile />,
    authorized: true,
  },
  {
    path: "/portal/companies/export/:token",
    element: <ExportPage />,
    authorized: true,
  },
  {
    path: "/account",
    element: <SidebarProfile />,
    authorized: true,
    children: [
      {
        path: "basic_data",
        element: <BasicData />,
      },
      {
        path: "limits",
        element: <Limits />,
      },
      {
        path: "monitoring",
        element: <CompaniesFollowingList />,
      },
      {
        path: "contract",
        element: <ContractPage />,
      },
      {
        path: "notifications",
        element: <NotificationsPage />,
      },
      {
        path: "notifications/:notificationId",
        element: <NotificationsSingleNotificationPage />,
      },
    ],
  },
  {
    path: "/portal/news/:newsId",
    element: <SingleNews />,
    authorized: true,
  },
];

export { routes };

//staticne rute koje mogu svi da vide
const staticRoutes = [
  {
    path: "/kontakt",
    element: <div>Kontakt</div>,
  },
  {
    path: "/o-nama",
    element: <div>O nama</div>,
  },
  {
    path: "/politika-privatnosti",
    element: <div>Politika privatnosti</div>,
  },
];
export { staticRoutes };
