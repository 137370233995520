import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { fetchManagement } from "../data/dataAccess";
import ManagementTable from "./ManagementTable";
import ManagementFilterModal from "./ManagementFilterModal";

const Management = ({ companyId }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get("managementPage")) || 1;

  const { data: managementData, isLoading } = useQuery({
    queryKey: ["management", companyId, searchQuery, page],
    queryFn: () => fetchManagement(companyId, searchQuery, page),
  });

  const formattedData = {
    ...managementData,
    items: managementData?.items?.map((item) => ({
      ...item,
      zastupnik_naziv: item.zastupnik_godine
        ? `${item.zastupnik_naziv} (${item.zastupnik_godine})`
        : item.zastupnik_naziv,
    })),
  };

  const handleApply = () => {
    setSearchQuery(searchTerm);
    setModalOpen(false);
  };

  const handleReset = () => {
    setSearchTerm("");
    setSearchQuery("");
  };

  const activeFiltersText = searchQuery
    ? `Pretraga po imenu: "${searchQuery}"`
    : "";

  return (
    <section>
      <ManagementTable
        data={formattedData}
        isLoading={isLoading}
        activeFiltersText={activeFiltersText}
        onOpenFilter={() => setModalOpen(true)}
        onResetFilters={handleReset}
      />

      {
        <ManagementFilterModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          onApply={handleApply}
        />
      }
    </section>
  );
};

export default Management;
