import React from "react";
import {
  Modal,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Button,
} from "@mui/material";
import { Spinner } from "../../shared/spinner";

const OwnershipStructureFilterModal = ({
  open,
  onClose,
  filtersData,
  loading,
  selectedType,
  setSelectedType,
  selectedPercentage,
  setSelectedPercentage,
  searchTerm,
  setSearchTerm,
  onApply,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box className="modal-box relative p-6 bg-white rounded-md shadow-md max-w-[500px] mx-auto mt-[10%]">
        <div className="flex justify-between items-center mb-4">
          <Typography variant="h6">Vlasnička struktura</Typography>
        </div>

        {loading ? (
          <Spinner />
        ) : (
          <>
            <TextField
              fullWidth
              label="Pretraga po vlasniku"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="mb-4"
            />

            <FormControl fullWidth margin="normal">
              <InputLabel>Tip vlasništva</InputLabel>
              <Select
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
                label="Tip vlasništva"
              >
                {filtersData?.types?.filters?.map((f) => (
                  <MenuItem key={f.id} value={f.id}>
                    {f.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel>Udeo vlasništva</InputLabel>
              <Select
                value={selectedPercentage}
                onChange={(e) => setSelectedPercentage(e.target.value)}
                label="Udeo vlasništva"
              >
                {filtersData?.percentages?.filters?.map((f) => (
                  <MenuItem key={f.id} value={f.id}>
                    {f.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className="flex justify-end mt-4">
              <Button
                variant="contained"
                onClick={onApply}
                className="filter-modal-button"
              >
                Pretraži
              </Button>
            </div>
          </>
        )}

        <div
          className="absolute top-5 right-[10px] w-fit text-black cursor-pointer p-3 hover:text-red-500"
          onClick={onClose}
        >
          <span className="text-lg">X</span>
        </div>
      </Box>
    </Modal>
  );
};

export default OwnershipStructureFilterModal;
