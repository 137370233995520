import React from "react";
import CustomTable from "../../components/CustomTable/ui/CustomTable";
import { Section } from "../../components/shared/section";
import BackButton from "../../components/BackButton/ui/BackButton";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { get } from "../../services/api";

const NotificationsSingleNotificationPage = () => {
  const { notificationId } = useParams();

  const { data } = useQuery({
    queryKey: ["notification", notificationId],
    queryFn: async () =>
      await get(`/portal/notifications/basic-data/${notificationId}`).then(
        (res) => res?.payload
      ),
  });

  return (
    <Grid className="py-4">
      <BackButton />
      <Section>
        <CustomTable
          data={data}
          omitProperties={["title", "created_at"]}
          alignment={`start`}
        />
      </Section>
    </Grid>
  );
};

export default NotificationsSingleNotificationPage;
