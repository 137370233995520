import React from "react";
import CustomTable from "../../CustomTable/ui/CustomTable";
import columns from "../data/relatedCompaniesColumns.json";
import {
  formatScoring,
  formatOwnership,
  formatColor,
} from "../../../utils/dataFormatters";

import "./RelatedCompaniesTable.css";

import { Button } from "@mui/material";

const RelatedCompaniesTable = ({
  data,
  isLoading,
  onFilterClick,
  activeFiltersText,
  onResetFilters,
}) => {
  const formattedData =
    data?.map((item) => ({
      ...item,
      povezana_firma_scoring_ocena: formatScoring(
        item.povezana_firma_scoring_ocena
      ),
      povezana_firma_scoring_ocena_boja: formatColor(
        item.povezana_firma_scoring_ocena_boja
      ),
      lice_vlasnik: formatOwnership(item.lice_vlasnik),
      lice_zastupnik: formatOwnership(item.lice_zastupnik),
    })) || [];

  return (
    <CustomTable
      columns={columns}
      data={{ items: formattedData }}
      name="Povezane kompanije"
      translations={{
        heading: { title: "Povezane kompanije" },
      }}
      className="related-companies-table"
      filtersButton={
        <Button
          onClick={onFilterClick}
          variant="contained"
          color="secondary"
          size="small"
        >
          Pretraži
        </Button>
      }
      activeFiltersText={activeFiltersText}
      onResetFilters={onResetFilters}
      isLoading={isLoading}
      pageParamName="relatedCompaniesPage"
    />
  );
};

export default RelatedCompaniesTable;
