import React from "react";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";

const ManagementFilterModal = ({
  open,
  onClose,
  searchTerm,
  setSearchTerm,
  onApply,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box className="modal-box relative p-6 bg-white rounded-md shadow-md max-w-[500px] mx-auto mt-[10%]">
        <div className="flex justify-between items-center mb-4">
          <Typography variant="h6">Menadžment</Typography>
        </div>

        <TextField
          fullWidth
          label="Pretraga po nazivu zastupnika"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="mb-4"
        />

        <div className="flex justify-end mt-4">
          <Button
            variant="contained"
            onClick={onApply}
            className="filter-modal-button"
          >
            Pretraži
          </Button>
        </div>

        <div
          className="absolute top-6 right-[18px] w-fit text-black cursor-pointer p-3 hover:text-red-500"
          onClick={onClose}
        >
          <span className="text-lg">X</span>
        </div>
      </Box>
    </Modal>
  );
};

export default ManagementFilterModal;
