import CompanyInfoHeader from "../../../../components/CompanyInfoComponents/CompanyInfoHeader/CompanyInfoHeader";
import ConnectedCompaniesHexagons from "../../../../components/ConnectedCompaniesHexagons/ui/ConnectedCompaniesHexagons";

const SpiderWeb = ({
  open,
  company_translate_data,
  company_data,
  company_id,
}) => {
  return (
    <div
      className={`${
        open ? `pl-[23rem] 3xl:pl-[25rem]` : ` max-md:px-[1rem] md:pl-[3rem]`
      } mx-auto pr-[3rem] transition-all duration-500 `}
    >
      <CompanyInfoHeader
        company_translate_data={company_translate_data}
        company_data={company_data}
      />
      <ConnectedCompaniesHexagons
        companyId={company_id}
        companyName={company_data?.naziv}
      />
    </div>
  );
};

export default SpiderWeb;
