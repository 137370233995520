import React, { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { post, get } from "../../../services/api";
import HexagonGraph from "../../HexagonGraph/ui/HexagonGraph";
import { Spinner } from "../../shared/spinner";

const fetchHexagonData = async (endpoint, companyId) => {
  try {
    const response = await post(endpoint, { company_id: Number(companyId) });
    if (response?.code === 200) {
      return response?.payload?.items || [];
    } else {
      toast.error(`Greška pri učitavanju podataka.`);
      return [];
    }
  } catch (error) {
    console.error(`❌ Greška pri učitavanju podataka:`, error);
    toast.error(`Greška pri učitavanju podataka.`);
    return [];
  }
};

const fetchHexagonCount = async (endpoint) => {
  try {
    const response = await get(endpoint);
    if (response?.code === 200) {
      return response?.payload?.count || 0;
    } else {
      toast.error(`Greška pri učitavanju broja podataka.`);
      return 0;
    }
  } catch (error) {
    console.error(`❌ Greška pri učitavanju broja podataka:`, error);
    toast.error(`Greška pri učitavanju broja podataka.`);
    return 0;
  }
};

const ConnectedCompaniesHexagons = ({ companyId, companyName }) => {
  const { data: managementData = [], isLoading: isManagementLoading } =
    useQuery({
      queryKey: ["managementData", companyId],
      queryFn: () =>
        fetchHexagonData(
          "portal/companies/spider-web/management/list",
          companyId
        ),
    });

  const { data: ownersData = [], isLoading: isOwnersLoading } = useQuery({
    queryKey: ["ownersData", companyId],
    queryFn: () =>
      fetchHexagonData("portal/companies/spider-web/owners/list", companyId),
  });

  const { data: sisterCompanies = [], isLoading: isSisterCompaniesLoading } =
    useQuery({
      queryKey: ["sisterCompanies", companyId],
      queryFn: () =>
        fetchHexagonData(
          "portal/companies/spider-web/companies-sisters/list",
          companyId
        ),
    });

  const {
    data: daughterCompanies = [],
    isLoading: isDaughterCompaniesLoading,
  } = useQuery({
    queryKey: ["daughterCompanies", companyId],
    queryFn: () =>
      fetchHexagonData(
        "portal/companies/spider-web/companies-daughters/list",
        companyId
      ),
  });

  const { data: managementCount = 0 } = useQuery({
    queryKey: ["managementCount", companyId],
    queryFn: () =>
      fetchHexagonCount(
        `portal/companies/spider-web/management/count/${companyId}`
      ),
  });

  const { data: ownersCount = 0 } = useQuery({
    queryKey: ["ownersCount", companyId],
    queryFn: () =>
      fetchHexagonCount(
        `portal/companies/spider-web/owners/count/${companyId}`
      ),
  });

  const { data: sisterCount = 0 } = useQuery({
    queryKey: ["sisterCount", companyId],
    queryFn: () =>
      fetchHexagonCount(
        `portal/companies/spider-web/companies-sisters/count/${companyId}`
      ),
  });

  const { data: daughterCount = 0 } = useQuery({
    queryKey: ["daughterCount", companyId],
    queryFn: () =>
      fetchHexagonCount(
        `portal/companies/spider-web/companies-daughters/count/${companyId}`
      ),
  });

  const isLoading =
    isManagementLoading ||
    isOwnersLoading ||
    isSisterCompaniesLoading ||
    isDaughterCompaniesLoading;

  const formattedData = useMemo(
    () => [
      {
        title: "Menadžment",
        count: managementCount,
        items: managementData.map((person) => ({
          name: person.zastupnik_naziv,
          value: person.zastupnik_godine,
        })),
        endpoint: "portal/companies/spider-web/management/list",
      },
      {
        title: "Vlasnici",
        count: ownersCount,
        items: ownersData.map((owner) => ({
          name: owner.vlasnik_naziv,
          value: owner.vlasnik_godine,
        })),
        endpoint: "portal/companies/spider-web/owners/list",
      },
      {
        title: companyName || "N/A",
        main: true,
      },
      {
        title: "Kompanije sestre",
        count: sisterCount,
        items: sisterCompanies.map((company) => ({
          name: company.sestra_naziv,
        })),
        endpoint: "portal/companies/spider-web/companies-sisters/list",
      },
      {
        title: "Kompanije ćerke",
        count: daughterCount,
        items: daughterCompanies.map((company) => ({
          name: company.cerka_naziv,
        })),
        endpoint: "portal/companies/spider-web/companies-daughters/list",
      },
    ],
    [
      managementCount,
      ownersCount,
      sisterCount,
      daughterCount,
      managementData,
      ownersData,
      sisterCompanies,
      daughterCompanies,
      companyName,
    ]
  );

  if (isLoading) {
    return (
      <div className="min-h-[540px] max-h-[600px] bg-slate-300 w-full h-full animate-pulse rounded-lg mt-7" />
    );
  }

  return <HexagonGraph data={formattedData} companyId={companyId} />;
};

export default ConnectedCompaniesHexagons;
