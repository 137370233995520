import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { get } from "../../services/api";
import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  FormControl,
  TextField,
} from "@mui/material";

export const AutocompleteInput = ({ data, setData, setOpenedAccordion }) => {
  const [search, setSearch] = useState("");

  const { data: options, isFetching } = useQuery({
    queryKey: ["DDL", search],
    queryFn: async () => {
      return await get(
        `portal/registration/main/ddl/company?search=${search}`,
      ).then((res) => res?.payload);
    },
  });

  const handleInputChange = (event, newInputValue) => {
    setSearch(newInputValue || "");
  };

  const [selectedOption, setSelectedOption] = useState(data?.name ?? "");

  const handleOptionChange = (event, newValue) => {
    setSelectedOption(newValue);
    setData({
      country_id: data?.country_id,
      slug: data?.slug,
      id: data?.id,
      firma_id: options?.find((option) => option?.name === newValue)?.id,
    });
  };

  const filterOptions = (options, params) => {
    const filtered = createFilterOptions()(options, params);
    return filtered.slice(0, 50);
  };

  useEffect(() => {
    if (data?.name) {
      setSelectedOption(data?.name);
      setData({
        country_id: data?.country_id,
        id: data?.id,
        slug: data?.slug,
        firma_id: options?.find((option) => option?.name === data?.name)?.id,
      });
    } else {
      setSelectedOption(data?.name);
      setData({
        country_id: data?.country_id,
        id: data?.id,
        slug: data?.slug,
      });
    }
  }, [data?.country_id]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSelectedOption(data?.name);
    }, 500);
    return () => clearTimeout(timeout);
  }, [data?.name]);

  return (
    <FormControl sx={{ width: "100%", paddingTop: "" }} required={true}>
      {data?.country_id === 192 ? (
        <Autocomplete
          disabled={!data?.id}
          value={selectedOption}
          onInputChange={handleInputChange}
          onChange={handleOptionChange}
          isOptionEqualToValue={(option, value) => option === value}
          noOptionsText={""}
          options={(options ?? []).map((option) => option?.name)}
          filterOptions={filterOptions}
          autoComplete
          renderInput={(params) => (
            <TextField {...params} label={`Pretražite firme`} required />
          )}
        />
      ) : (
        <TextField
          required
          label={`Firma`}
          sx={{ marginTop: "1rem" }}
          value={data?.name ?? ""}
          onChange={(e) => {
            setData({
              ...data,
              name: e.target.value,
            });
          }}
        />
      )}
      <TextField
        required
        label={`PIB`}
        sx={{ marginTop: "1rem" }}
        disabled={data?.country_id === 192}
        value={data?.pib ?? ""}
        onChange={(e) => {
          setData({
            ...data,
            pib: e.target.value,
          });
        }}
      />
      <TextField
        required
        label={`Matični broj`}
        sx={{ marginTop: "1rem" }}
        disabled={data?.country_id === 192}
        value={data?.maticni_broj ?? ""}
        onChange={(e) => {
          setData({
            ...data,
            maticni_broj: e.target.value,
          });
        }}
      />
      <TextField
        required
        label={`Adresa`}
        sx={{ marginTop: "1rem" }}
        disabled={data?.country_id === 192}
        value={data?.address ?? ""}
        onChange={(e) => {
          setData({
            ...data,
            address: e.target.value,
          });
        }}
      />
      <TextField
        label={`Grad`}
        sx={{ marginTop: "1rem" }}
        disabled={data?.country_id === 192}
        value={data?.city ?? ""}
        onChange={(e) => {
          setData({
            ...data,
            city: e.target.value,
          });
        }}
      />
      <TextField
        label={`Poštanski broj`}
        sx={{ marginTop: "1rem" }}
        disabled={data?.country_id === 192}
        value={data?.postal_code ?? ""}
        onChange={(e) => {
          setData({
            ...data,
            postal_code: e.target.value,
          });
        }}
      />
    </FormControl>
  );
};
