import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import RelatedCompaniesTable from "./RelatedCompaniesTable";
import RelatedCompaniesFilterModal from "./RelatedCompaniesFilterModal";

import {
  fetchRelatedCompanies,
  fetchRelatedCompaniesFilters,
} from "../data/dataAccess";

import "./RelatedCompaniesTable.css";

const RelatedCompanies = ({ companyId }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get("relatedCompaniesPage")) || 1;

  const {
    data: filtersData,
    isLoading: isLoadingFilters,
    refetch,
  } = useQuery({
    queryKey: ["related-companies-filters"],
    queryFn: fetchRelatedCompaniesFilters,
    enabled: false,
  });

  const { data: relatedCompaniesData = [], isLoading } = useQuery({
    queryKey: [
      "related-companies",
      companyId,
      appliedFilters,
      searchQuery,
      page,
    ],
    queryFn: () =>
      fetchRelatedCompanies(companyId, appliedFilters, searchQuery, page),
  });

  const handleOpenModal = () => {
    setModalOpen(true);
    refetch();
  };

  const handleApply = () => {
    const filters = [];
    if (selectedFilter) {
      filters.push({
        code: "type",
        value: { selected: [selectedFilter] },
      });
    }

    setAppliedFilters(filters);
    setSearchQuery(searchTerm);
    setModalOpen(false);
  };

  const handleReset = () => {
    setSelectedFilter("");
    setSearchTerm("");
    setSearchQuery("");
    setAppliedFilters([]);
  };

  const getFilterSummary = () => {
    const filterName = filtersData?.find((f) => f.id === selectedFilter)?.name;
    const parts = [
      filterName ? `Tip: ${filterName}` : null,
      searchQuery ? `Naziv sadrži: "${searchQuery}"` : null,
    ];
    return parts.filter(Boolean).join(" • ");
  };

  return (
    !!relatedCompaniesData?.items?.length && (
      <section className="related-companies py-5">
        <RelatedCompaniesTable
          data={relatedCompaniesData?.items}
          isLoading={isLoading}
          onFilterClick={handleOpenModal}
          onResetFilters={handleReset}
          activeFiltersText={getFilterSummary()}
        />

        <RelatedCompaniesFilterModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          filtersData={filtersData}
          isLoading={isLoadingFilters}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          onApply={handleApply}
        />
      </section>
    )
  );
};

export default RelatedCompanies;
