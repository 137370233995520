import { ArrowBack } from "@material-ui/icons";
import { Button, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <Grid item sm={12} className="flex justify-end !mb-4">
      <Button
        startIcon={<ArrowBack />}
        variant={`contained`}
        className="!font-semibold"
        onClick={() => navigate(-1)}
      >
        Nazad
      </Button>
    </Grid>
  );
};

export default BackButton;
