import React from "react";
import CustomTable from "../../CustomTable/ui/CustomTable";
import columns from "../data/notificationsListTableColumns.json";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "../../../services/notificationsCountContext";

const NotificationsListTable = ({ translations, data, onPageChange }) => {
  const navigate = useNavigate();
  const { markAsSeen } = useNotifications();

  const customActions = [
    {
      type: "view",
      onClick: (notification) => {
        navigate(`/account/notifications/${notification.id}`);

        if (notification.status === "Dostavljeno") markAsSeen(notification.id);
      },
    },
  ];

  const rowBadgeCondition = (rowData) => rowData.status === "Dostavljeno";

  return (
    <CustomTable
      translations={translations}
      data={data}
      columns={columns}
      actions={customActions}
      omitProperties={["content", "id_portal_customer_user"]}
      rowBadgeCondition={rowBadgeCondition}
      onPageChange={onPageChange}
    />
  );
};

export default NotificationsListTable;
