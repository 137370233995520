import { useTranslate } from "../../../../services/hooks";
import { Divider, Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import columns from "../data/singleNewsHeadingColumns.json";
import CustomTypography from "../../../CustomTypography/ui/CustomTypography";

const SingleNewsHeading = ({ data }) => {
  const { data: translations } = useTranslate("news-details", "default") || {};

  const {
    "news-details": { default: { heading: headingTranslations } = {} } = {},
  } = translations || {};

  return (
    <Grid>
      <Typography
        component={`h1`}
        variant={`h2`}
        className="uppercase !font-medium"
      >
        {data?.basic_data.title}
      </Typography>
      <Grid container>
        {columns.map((column, index) => {
          return (
            <Fragment key={index}>
              <Grid item className="!text-md flex gap-2 items-center">
                <Typography>
                  {headingTranslations?.[column.prop_name].label}:
                </Typography>
                <CustomTypography
                  data={data}
                  prop_name={column.prop_name}
                  type={column.type}
                />
              </Grid>
              {index < columns.length - 1 && (
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ mx: 2, borderWidth: 1 }}
                />
              )}
            </Fragment>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default SingleNewsHeading;
