import React from "react";
import CustomTable from "../../CustomTable/ui/CustomTable";
import columns from "../data/exchangeRateListColumns.json";
import { useQuery } from "@tanstack/react-query";
import { get } from "../../../services/api";

const ExchangeRateList = ({ translations }) => {
  const { data } = useQuery({
    queryKey: ["exchaneRateList"],
    queryFn: async () =>
      await get(`portal/homepage/exchange-rate`).then((res) => res.payload),
  });

  return (
    <CustomTable translations={translations} data={data} columns={columns} />
  );
};

export default ExchangeRateList;
