import * as Yup from "yup";
import { useValidationMessages } from "./useValidationMessages";

export const usePersonalInfoFormValidation = (
  formFields,
  translations,
  validationMessagesTranslation
) => {
  const messages = useValidationMessages(validationMessagesTranslation);

  return Yup.object().shape({
    [formFields?.["first_name"]]: Yup.string()
      .min(3, messages?.minLength(translations?.["first_name_input"]?.label, 3))
      .required(messages?.required(translations?.["first_name_input"]?.label)),
    [formFields?.["last_name"]]: Yup.string()
      .min(3, messages?.minLength(translations?.["last_name_input"]?.label, 3))
      .required(messages?.required(translations?.["last_name_input"]?.label)),
    [formFields?.["phone"]]: Yup.string()
      .min(9, messages?.minLength(translations?.["phone_input"]?.label, 9))
      .required(messages?.required(translations?.["phone_input"]?.label)),
    [formFields?.["landline_phone"]]: Yup.string().optional(),
    [formFields?.["working_position"]]: Yup.string()
      .min(
        3,
        messages?.minLength(translations?.["working_position_input"]?.label, 3)
      )
      .required(
        messages?.required(translations?.["working_position_input"]?.label)
      ),
    [formFields?.["email"]]: Yup.string()
      .email(messages?.email)
      .required(messages?.required(translations?.["email_input"]?.label)),
  });
};
