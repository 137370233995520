import {
  Badge,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { omitKeys } from "../../../helpers/objectFormatHelpers";
import CustomTableCell from "./CustomTableCell";
import CustomTablePagination from "./CustomTablePagination";
import { defaultTableColumnNames } from "../../../utils/translations";
import { useContext } from "react";
import { userContext } from "../../../services/userContext";

//KORISTITI OVU TABELU SVUDA I POGLEDATI KROZ OSTALE PRIMERE SVE VARIJACIJE NJENOG KORISCENJA ILI POGLEDATI KOMENTARE ISPOD U KOMPONENTI

const CustomTable = ({
  name,
  data,
  columns = [],
  omitProperties = [],
  translations,
  actions,
  className,
  targetId,
  onSelectRow,
  alignment,
  rowBadgeCondition,
  onPageChange,
  direction,
  withDefaultColumns,
  filtersButton,
  activeFiltersText,
  onResetFilters,
  noDataMessage,
  pageParamName = "page",
}) => {
  const { lang } = useContext(userContext);

  const items = data?.items || data;
  const totalItems = data?.pagination?.total_items;
  const title =
    (translations?.heading?.title || data?.title || name) +
    (totalItems ? ` (${totalItems})` : "");
  const isArrayData = Array.isArray(data?.items || data);

  const handleRowClick = (item) => {
    if (onSelectRow) onSelectRow(item);
  };

  return (
    <TableContainer
      component={Paper}
      elevation={4}
      className={`!rounded-lg ${className}`}
      id={targetId}
    >
      {/* PREDSTAVLJA NASLOV TABELE  */}
      <Grid
        className="bg-[#ed545c] text-white p-2"
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="wrap"
      >
        <div className="flex flex-col md:flex-row md:items-center gap-2">
          <Typography className="!font-semibold">{title}</Typography>

          {activeFiltersText && (
            <div className="flex items-center gap-2 text-white text-sm">
              <span>{activeFiltersText}</span>
              {onResetFilters && (
                <span
                  onClick={onResetFilters}
                  className="text-red-300 cursor-pointer hover:underline"
                >
                  Poništi filtere ✖
                </span>
              )}
            </div>
          )}
        </div>

        {filtersButton && (
          <div className="flex justify-end items-center">{filtersButton}</div>
        )}
      </Grid>

      <Table>
        {/* PREDSTAVLJA NAZIVE KOLONA TABELE */}
        {!data?.message && (
          <TableHead>
            <TableRow className="bg-[#fafafa]">
              {columns && columns.length > 0 ? (
                <>
                  {columns.map((column, index) => (
                    <TableCell
                      key={index}
                      style={{ width: `${100 / columns.length}%` }}
                      className="!font-bold !text-center"
                    >
                      {translations?.[column.prop_name]?.label || column.name}
                    </TableCell>
                  ))}
                </>
              ) : (
                withDefaultColumns && (
                  //UKOLIKO UOPSTE COLUMNS NIJE PROSLEDJEN RENDERUJU SE `Naslov` I `Vrednost` KOLONE SA STATICNIM PREVODIMA U FAJLU `translations.js`
                  <>
                    <TableCell
                      sx={{
                        fontWeight: 700,
                        textAlign: `center`,
                        width: "50%",
                      }}
                    >
                      {defaultTableColumnNames?.[lang]?.title}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 700,
                        textAlign: `center`,
                        width: "50%",
                      }}
                    >
                      {defaultTableColumnNames?.[lang]?.value}
                    </TableCell>
                  </>
                )
              )}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {/* PREDSTAVLJA PORUKU U TABELI KADA NEMA PODATAKA */}
          {isArrayData && items.length === 0 ? (
            <CustomTableCell
              value={noDataMessage || data?.message || "Nema podataka."}
              sx={{ textAlign: alignment || "center" }}
            />
          ) : (
            <>
              {isArrayData ? (
                columns.length === 0 ? (
                  // UKOLIKO JE DATA ARRAY I NEMA PROSLEDJENIH KOLONA RADI MAP ITEMA IZ DATA I KORISTI FIELD NAME IZ ITEMA ZA RENDEROVANJE CELIJE
                  <TableRow onClick={() => onSelectRow()}>
                    {items.map((item, index) => {
                      const filteredItem = omitKeys(item, [
                        ...omitProperties,
                        "id",
                      ]);
                      return (
                        <CustomTableCell
                          key={index}
                          value={filteredItem.name}
                        />
                      );
                    })}
                  </TableRow>
                ) : (
                  // UKOLIKO DATA NIJE ARRAY I IMA PROSLEDJENIH KOLONA RADI MAP ITEMA IZ DATA I KORISTI SVE FIELDOVE IZ ITEMA I NA OSNOVU NJIH RENDERUJE VREDNOSTI U CELIJI
                  items.map((item, index) => {
                    const filteredItem = omitKeys(item, [
                      ...omitProperties,
                      "id",
                    ]);
                    const showBadge = rowBadgeCondition?.(item);

                    return (
                      <TableRow
                        key={index}
                        onClick={() => handleRowClick(item)}
                        sx={{
                          backgroundColor: !rowBadgeCondition
                            ? "white"
                            : showBadge
                              ? "white"
                              : "rgba(0, 0, 0, 0.04)",
                          position: "relative",
                        }}
                      >
                        {columns.map((column) => {
                          const value = item[column.prop_name];

                          if (
                            [
                              "cerka_scoring_ocena",
                              "sestra_scoring_ocena",
                              "povezana_firma_scoring_ocena",
                            ].includes(column.prop_name)
                          ) {
                            const color =
                              item[`${column.prop_name}_boja`] ||
                              item[
                                column.prop_name.replace(
                                  "_ocena",
                                  "_ocena_boja"
                                )
                              ];

                            return (
                              <TableCell
                                key={column.prop_name}
                                align="center"
                                sx={{ verticalAlign: "middle" }}
                              >
                                <div
                                  style={{
                                    backgroundColor: color || "#f0f0f0",
                                    color: "#fff",
                                    padding: "6px 12px",
                                    borderRadius: "7px",
                                    display: "inline-block",
                                    fontWeight: 600,
                                    minWidth: "40px",
                                    fontSize: "13.2px",
                                    paddingBlock: "0.125rem",
                                    width: "100%",
                                    maxWidth: "130px",
                                  }}
                                  title={`Scoring: ${value}`}
                                >
                                  {value || "-"}
                                </div>
                              </TableCell>
                            );
                          }

                          // Default render
                          return (
                            <CustomTableCell
                              key={column.prop_name}
                              column={column}
                              actions={actions}
                              item={{ ...filteredItem, id: item.id }}
                              sx={{ textAlign: alignment || "center" }}
                              className={
                                showBadge ? "!font-[700]" : "!font-[600]"
                              }
                            />
                          );
                        })}

                        {/* UKOLIKO JE PROSLEDJEN `rowBadgeCondition` RENDERUJE SE BADGE (crvena tackica) NA LEVOJ STRANI ROWA */}
                        {showBadge && (
                          <TableCell
                            sx={{
                              position: "absolute",
                              left: 8,
                              top: "50%",
                              transform: "translateY(-50%)",
                              zIndex: 1,
                              pointerEvents: "none",
                              border: "none",
                            }}
                          >
                            <Badge color="error" variant="dot" />
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })
                )
              ) : (
                <>
                  {data &&
                    (direction === "column" ? (
                      // UKOLIKO JE DATA PROSLEDJEN KAO OBJEKAT I DIRECTION STAVLJEN KAO `column` TABELA SE RENDERUJE U 2 KOLONE A FIELDOVI SE REDJAJU VERTIKALNO NA DOLE
                      Object.entries(data, [...omitProperties, "id"]).map(
                        ([key, value]) => (
                          <TableRow key={key} onClick={handleRowClick}>
                            <CustomTableCell value={key} />
                            <CustomTableCell value={value} />
                          </TableRow>
                        )
                      )
                    ) : (
                      // UKOLIKO JE DATA PROSLEDJEN KAO OBJEKAT I DIRECTION NIJE STAVLJEN TABELA SE RENDERUJE U 1 REDU GDE SU NAREDJANI SVI VALUES OD PROPERTYA
                      <TableRow onClick={handleRowClick}>
                        {Object.entries(
                          omitKeys(data, [...omitProperties, "id"])
                        ).map(([key, value]) => (
                          <CustomTableCell
                            key={key}
                            value={value}
                            sx={{ textAlign: alignment || "center" }}
                          />
                        ))}
                      </TableRow>
                    ))}
                </>
              )}
            </>
          )}
        </TableBody>
      </Table>
      {data?.pagination && (
        //PAGINACIJA SE RENDERUJE UKOLIKO `data.items` IMA PAGINACIJU
        <CustomTablePagination
          pagination={data.pagination}
          onPageChange={onPageChange}
          pageParamName={pageParamName}
        />
      )}
    </TableContainer>
  );
};

export default CustomTable;
