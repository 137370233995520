import { post } from "../../../services/api";
import { toast } from "react-toastify";

export const fetchManagement = async (companyId, search = "", page = 1) => {
  try {
    const payload = {
      company_id: Number(companyId),
      search,
      limit: 10,
      page,
    };

    const response = await post(
      "/portal/companies/other-important-info/management/list",
      payload,
      "PORTAL_COMPANY_MANAGEMENT"
    );

    if (response?.code === 200) {
      return response?.payload || [];
    } else {
      toast.error("Greška pri učitavanju menadžment podataka.");
      return [];
    }
  } catch (error) {
    console.error("❌ Greška pri učitavanju menadžment podataka:", error);
    toast.error("Greška pri učitavanju menadžment podataka.");
    return [];
  }
};
