import { currencyFormat } from "../../../utils/functions";
import { Button, Tooltip } from "@mui/material";
import { icons } from "../../../lib/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { get, post } from "../../../services/api";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useTranslate } from "../../../services/hooks";

const CompanyInfoHeader = ({
  company_translate_data: translations,
  company_data: data,
  code,
  isLoading,
  translate_section,
  id,
}) => {
  const [openModal, setOpenModal] = useState({
    open: false,
    message: "",
  });

  const { mutate, isPending, isSuccess } = useMutation({
    mutationKey: ["follow", id],
    mutationFn: async () => {
      return await post(
        `/portal/companies/follow`,
        {
          company_id: id,
        },
        "USERS",
      )?.then((res) => {
        if (res?.payload && res?.code === 200) {
          toast.success(res?.payload?.message, {
            position: "top-center",
          });
          setOpenModal({ open: false, message: "" });

          return res?.payload;
        } else {
          toast.error(res?.data?.message, { position: "top-center" });
          setOpenModal({ open: false, message: "" });
          return {};
        }
      });
    },
  });

  const { data: is_following, refetch } = useQuery({
    queryKey: ["is_following", id],
    queryFn: async () => {
      return await get(`/portal/companies/follow/${id}`, "USERS")?.then(
        (res) => {
          if (res?.payload) {
            return res?.payload;
          } else {
            return {};
          }
        },
      );
    },
  });
  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess]);

  const { refetch: refetchFollowMessage } = useQuery({
    queryKey: ["followMessage", id],
    queryFn: async () => {
      return await get(
        `/portal/companies/follow/follow-message/${id}`,
        "USERS",
      )?.then((res) => {
        if (res?.payload) {
          return res?.payload;
        }
      });
    },
    enabled: false,
  });
  const { refetch: refetchUnfollowMessage } = useQuery({
    queryKey: ["unFollowMessage", id],
    queryFn: async () => {
      return await get(
        `/portal/companies/follow/unfollow-message/${id}`,
        "USERS",
      )?.then((res) => {
        if (res?.payload) {
          return res?.payload;
        }
      });
    },
    enabled: false,
  });

  const { data: modal_translate_data } = useTranslate("global", "GLOBAL");

  return (
    <>
      <div className={`@container`}>
        {isLoading ? (
          <div
            className={`min-h-[5rem] max-h-[7rem] bg-slate-300 w-full h-full animate-pulse rounded-lg mt-7`}
          ></div>
        ) : (
          <div
            className={`flex ${
              code === 403 && "border"
            } flex-col @[78.125rem]:flex-row @[93.438rem]:flex-row items-center bg-white shadow-xl rounded-lg @[1138px]:gap-5 @[1150px]:justify-between justify-between px-[1rem] max-sm:px-[1rem] mt-7 py-[2rem]`}
          >
            {code === 403 ? (
              <p>
                {translate_section?.["global"]?.["default"]?.["global"]?.[
                  "section"
                ]?.["permission"] ?? "Ovu sekciju ne možete videti."}
              </p>
            ) : (
              <>
                <div className={`flex flex-col gap-5`}>
                  {data?.logo ? (
                    <a href={`https://${data?.web}`} target={`_blank`}>
                      <img src={data?.logo} alt="Logo" />
                    </a>
                  ) : data?.naziv_skraceni ? (
                    data?.web ? (
                      <a
                        className={`p-5 w-full bg-[#2b343b] rounded-lg transition-all duration-300 hover:bg-[#ed545c] text-white font-bold text-[1.025rem]`}
                        href={data?.web ? `https://${data?.web}` : "#"}
                        target={`_blank`}
                      >
                        {data?.naziv_skraceni}
                      </a>
                    ) : (
                      <span
                        className={`p-5 w-full bg-[#2b343b] rounded-lg transition-all duration-300 hover:bg-[#ed545c] text-white font-bold text-[1.025rem]`}
                      >
                        {data?.naziv_skraceni}
                      </span>
                    )
                  ) : null}

                  {/*<div className={`flex flex-col items-center`}>*/}
                  {/*  <div className={`flex items-center gap-9`}>*/}
                  {/*    <div className={`flex items-center gap-2`}>*/}
                  {/*      <p className={`text-sm hover:underline cursor-pointer`}>*/}
                  {/*        Posetite sajt*/}
                  {/*      </p>*/}
                  {/*      <img src={Web} alt="Web" width={`18`} className={``} />*/}
                  {/*    </div>*/}
                  {/*    <div className={`flex items-center gap-2`}>*/}
                  {/*      <p className={`text-sm hover:underline cursor-pointer`}>*/}
                  {/*        Kontakt telefon*/}
                  {/*      </p>*/}
                  {/*      <img src={Phone} alt="Web" width={`18`} className={``} />*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*  <h1*/}
                  {/*    className={`text-sm font-semibold text-center self-center mt-3 hover:underline cursor-pointer`}*/}
                  {/*  >*/}
                  {/*    Zahtevajte kontakt određene osobe*/}
                  {/*  </h1>*/}
                  {/*</div>*/}
                </div>
                <div
                  className={`grid grid-cols-2 shrink-0  self-stretch flex-1 mt-5 @[93.438rem]:mt-0 w-full @[93.438rem]:max-w-[65%]`}
                >
                  <div
                    className={`col-span-1  pl-5 rounded-l-lg flex flex-col items-start justify-center bg-[#f4f7fa]`}
                  >
                    <h1 className={`text-[0.95rem] font-semibold`}>
                      {translations?.["company-profile"]?.["basic-data"]?.[
                        "header"
                      ]?.["full_name"]?.["label"] ?? "Pun naziv"}
                      :&nbsp;
                      <span className={`font-normal max-sm:hidden`}>
                        {data?.naziv_pun}
                      </span>{" "}
                    </h1>{" "}
                    <span className={`sm:hidden font-normal`}>
                      {data?.naziv_pun}
                    </span>{" "}
                  </div>
                  <div
                    className={`col-span-1  pl-5 rounded-r-lg flex flex-col items-start justify-center bg-[#f4f7fa]`}
                  >
                    <h1 className={`text-[0.95rem] font-semibold`}>
                      {translations?.["company-profile"]?.["basic-data"]?.[
                        "header"
                      ]?.["status"]?.["label"] ?? "Status"}
                      :&nbsp;
                      <span
                        className={`max-sm:hidden text-green-500 font-medium`}
                      >
                        {data?.status}
                      </span>
                    </h1>
                    <span className={`sm:hidden text-green-500 font-medium`}>
                      {data?.status}
                    </span>
                  </div>
                  <div
                    className={`col-span-1  pl-5 rounded-l-lg flex flex-col items-start justify-center `}
                  >
                    <h1 className={`text-[0.95rem] font-semibold`}>
                      {translations?.["company-profile"]?.["basic-data"]?.[
                        "header"
                      ]?.["activity"]?.["label"] ?? "Delatnost"}
                      :&nbsp;
                      <span className={`font-normal max-sm:hidden`}>
                        {data?.delatnost}
                      </span>{" "}
                    </h1>{" "}
                    <span className={`font-normal sm:hidden`}>
                      {data?.delatnost}
                    </span>{" "}
                  </div>
                  <div
                    className={`col-span-1  pl-5 rounded-r-lg flex flex-col items-start justify-center `}
                  >
                    <h1 className={`text-[0.95rem] font-semibold`}>
                      {translations?.["company-profile"]?.["basic-data"]?.[
                        "header"
                      ]?.["mb"]?.["label"] ?? "MB"}
                      :{" "}
                      <span className={`font-normal max-sm:hidden`}>
                        {data?.maticni_broj}
                      </span>{" "}
                    </h1>
                    <span className={`font-normal sm:hidden`}>
                      {data?.maticni_broj}
                    </span>
                  </div>
                  <div
                    className={`col-span-1  pl-5 rounded-l-lg flex flex-col items-start justify-center bg-[#f4f7fa]`}
                  >
                    <h1 className={`text-[0.95rem] font-semibold`}>
                      {translations?.["company-profile"]?.["basic-data"]?.[
                        "header"
                      ]?.["address"]?.["label"] ?? "Adresa"}
                      :&nbsp;
                      <a
                        target={`_blank`}
                        href={`https://www.google.com/maps/search/${
                          data?.adresa ? data?.adresa : ""
                        } ${data?.mesto ? data?.mesto : ""}`}
                        className={`font-normal max-sm:hidden hover:underline`}
                      >
                        {data?.adresa}
                      </a>{" "}
                    </h1>
                    <a
                      target={`_blank`}
                      href={`https://www.google.com/maps/search/${
                        data?.adresa ? data?.adresa : ""
                      } ${data?.mesto ? data?.mesto : ""}`}
                      className={`font-normal sm:hidden hover:underline`}
                    >
                      {data?.adresa}
                    </a>
                  </div>
                  <div
                    className={`col-span-1  pl-5 rounded-r-lg flex flex-col items-start justify-center bg-[#f4f7fa]`}
                  >
                    <h1 className={`text-[0.95rem] font-semibold`}>
                      {translations?.["company-profile"]?.["basic-data"]?.[
                        "header"
                      ]?.["pib"]?.["label"] ?? "PIB"}
                      :{" "}
                      <span className={`font-normal max-sm:hidden`}>
                        {data?.pib}
                      </span>{" "}
                    </h1>
                    <span className={`font-normal sm:hidden`}>{data?.pib}</span>
                  </div>
                  <div
                    className={`col-span-1  pl-5 rounded-r-lg flex flex-col items-start justify-center`}
                  >
                    <h1 className={`text-[0.95rem] font-semibold`}>
                      {translations?.["company-profile"]?.["basic-data"]?.[
                        "header"
                      ]?.["city"]?.["label"] ?? "Grad"}
                      :{" "}
                      <a
                        target={`_blank`}
                        href={`https://www.google.com/maps/search/${
                          data?.adresa ? data?.adresa : ""
                        } ${data?.mesto ? data?.mesto : ""}`}
                        className={`font-normal max-sm:hidden hover:underline`}
                      >
                        {data?.mesto}
                      </a>{" "}
                    </h1>
                    <a
                      target={`_blank`}
                      href={`https://www.google.com/maps/search/${
                        data?.adresa ? data?.adresa : ""
                      } ${data?.mesto ? data?.mesto : ""}`}
                      className={`font-normal sm:hidden hover:underline`}
                    >
                      {data?.mesto}
                    </a>
                  </div>
                  <div
                    className={`col-span-1  pl-5 rounded-r-lg flex flex-col items-start justify-center`}
                  >
                    <h1 className={`text-[0.95rem] font-semibold`}>
                      {translations?.["company-profile"]?.["basic-data"]?.[
                        "header"
                      ]?.["pdv"]?.["label"] ?? "PDV"}
                      :{" "}
                      <span className={`font-normal max-sm:hidden`}>
                        {data?.pdv}
                      </span>{" "}
                    </h1>
                    <span className={`font-normal sm:hidden`}>{data?.pdv}</span>
                  </div>
                  <div
                    className={`col-span-1  pl-5 rounded-l-lg flex flex-col items-start justify-center bg-[#f4f7fa]`}
                  >
                    <h1 className={`text-[0.95rem] font-semibold`}>
                      {translations?.["company-profile"]?.["basic-data"]?.[
                        "header"
                      ]?.["phone"]?.["label"] ?? "Telefon"}
                      :&nbsp;
                      <a
                        href={`tel:${data?.telefon}`}
                        className={`font-normal max-sm:hidden hover:underline`}
                      >
                        {data?.telefon}
                      </a>{" "}
                    </h1>
                    <a
                      href={`tel:${data?.telefon}`}
                      className={`font-normal sm:hidden hover:underline`}
                    >
                      {data?.telefon}
                    </a>
                  </div>
                  <div
                    className={`col-span-1  pl-5 rounded-r-lg flex flex-col items-start justify-center bg-[#f4f7fa]`}
                  >
                    <h1 className={`text-[0.95rem] font-semibold`}>
                      {translations?.["company-profile"]?.["basic-data"]?.[
                        "header"
                      ]?.["founding_date"]?.["label"] ?? "Datum osnivanja"}
                      :&nbsp;
                      <span className={`font-normal max-sm:hidden`}>
                        {data?.datum_osnivanja}
                      </span>{" "}
                    </h1>{" "}
                    <span className={`font-normal sm:hidden`}>
                      {data?.datum_osnivanja}
                    </span>
                  </div>
                  <div
                    className={`col-span-1  pl-5 rounded-l-lg flex flex-col items-start justify-center `}
                  >
                    <h1 className={`text-[0.95rem] font-semibold`}>
                      {translations?.["company-profile"]?.["basic-data"]?.[
                        "header"
                      ]?.["email"]?.["label"] ?? "Email"}
                      :&nbsp;
                      <a
                        href={`mailto:${data?.email}`}
                        className={`font-normal max-sm:hidden hover:underline`}
                      >
                        {data?.email}
                      </a>{" "}
                    </h1>
                    <a
                      href={`mailto:${data?.email}`}
                      className={`font-normal sm:hidden hover:underline`}
                    >
                      {data?.email}
                    </a>
                  </div>
                  <div
                    className={`col-span-1 gap-10  pl-5 rounded-r-lg flex max-sm:gap-5 items-center justify-start`}
                  >
                    <h1 className={`text-[0.95rem] font-semibold`}>
                      {translations?.["company-profile"]?.["basic-data"]?.[
                        "header"
                      ]?.["blockages"]?.["label"] ?? "Blokade"}
                      :&nbsp;
                      <span className={`font-normal`}>
                        {data?.blokade}
                      </span>{" "}
                    </h1>
                    <h1 className={`text-[0.95rem] font-semibold`}>
                      {translations?.["company-profile"]?.["basic-data"]?.[
                        "header"
                      ]?.["bills_of_exchange"]?.["label"] ?? "Menice"}
                      :&nbsp;
                      <span className={`font-normal`}>{data?.menice}</span>{" "}
                    </h1>
                  </div>
                </div>
                <div
                  className={`flex flex-col self-stretch gap-2.5 h-full mt-5 @[93.438rem]:mt-0 w-full @[78rem]:w-[160px] @[93.438rem]:w-[160px]`}
                >
                  <div
                    className={`flex flex-col gap-1 items-center justify-center`}
                  >
                    <div
                      className={`w-full py-1 flex flex-col items-center text-center rounded-lg`}
                      style={{
                        backgroundColor: `${data?.color}`,
                      }}
                    >
                      <h1 className={`text-white font-semibold`}>
                        {data?.scoring_ocena}
                      </h1>
                    </div>
                    <p className={`text-sm font-medium`}>Scoring</p>
                  </div>
                  <div
                    className={`flex flex-col  gap-1 items-center justify-center`}
                  >
                    <div
                      className={`w-full flex py-1 flex-col items-center text-center rounded-lg bg-[#2b343b]`}
                    >
                      <h1 className={`text-white font-semibold`}>
                        {currencyFormat(data?.kreditni_limit)}
                      </h1>
                    </div>
                    <p className={`text-sm font-medium`}>
                      {translations?.["company-profile"]?.["basic-data"]?.[
                        "header"
                      ]?.["credit_limit"]?.["label"] ?? "Kreditni limit"}
                    </p>
                  </div>
                  <div
                    className={`flex flex-col gap-1 items-center justify-center`}
                  >
                    <Tooltip title={is_following?.tooltip} placement={"left"}>
                      <div
                        onClick={() => {
                          if (is_following?.follow) {
                            refetchUnfollowMessage()?.then((res) => {
                              setOpenModal({
                                open: true,
                                message: res?.data?.main_line,
                              });
                            });
                          } else {
                            refetchFollowMessage()?.then((res) => {
                              setOpenModal({
                                open: true,
                                message: res?.data?.main_line,
                              });
                            });
                          }
                        }}
                        className={`w-full cursor-pointer flex py-1 flex-col items-center text-center rounded-lg transition-colors duration-300 bg-[#aa1d23] ${is_following?.follow ? "bg-green-600" : ""}`}
                      >
                        <p className={`text-white font-semibold`}>
                          {is_following?.follow ? icons?.check : icons.close}
                        </p>
                      </div>
                    </Tooltip>
                    <p className={`text-sm font-medium`}>
                      {translations?.["company-profile"]?.["basic-data"]?.[
                        "header"
                      ]?.["monitoring"]?.["label"] ?? "Monitoring"}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <div
        onClick={() =>
          setOpenModal({
            open: false,
            message: "",
          })
        }
        className={
          openModal?.open
            ? "fixed top-0 left-0 bg-black/50 w-dvw h-dvh z-[500]"
            : "hidden"
        }
      />
      <div
        className={
          openModal?.open
            ? "fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl p-4 z-[501] max-w-[30rem] text-center"
            : "hidden"
        }
      >
        <p>{openModal?.message}</p>
        <div className={"flex items-center gap-5 mt-5 justify-center"}>
          <Button
            variant={"contained"}
            color={"error"}
            onClick={() => {
              setOpenModal({
                open: false,
                message: "",
              });
            }}
          >
            {modal_translate_data?.["global"]?.["default"]?.["global"]?.[
              "word"
            ]?.["no"] ?? "Ne"}
          </Button>
          <Button
            variant={"contained"}
            color={"success"}
            onClick={() => {
              mutate();
            }}
          >
            {modal_translate_data?.["global"]?.["default"]?.["global"]?.[
              "word"
            ]?.["yes"] ?? "Da"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CompanyInfoHeader;
