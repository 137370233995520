import { useState, useEffect } from "react";

const DelayedRender = ({ isLoggedIn, delay, children }) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShouldRender(true);
    }, delay);
    return () => clearTimeout(timeout);
  }, [delay]);

  return shouldRender ? children : null;
};

export default DelayedRender;
