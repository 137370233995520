import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ed545c",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#fafafa",
    },
    background: {
      default: "#ffffff",
      neutral: "#f8f9fa",
      complementary: "#f2f4f6",
      dark: "#1f2933",
    },
    text: {
      primary: "#495057",
      dark: "#ffffff",
      complementary: "#414750",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        h1: {
          fontSize: "2.5rem",
          fontWeight: "bold",
          margin: "0.5em 0",
          color: "#333",
        },
        h2: {
          fontSize: "2rem",
          fontWeight: "bold",
          margin: "0.5em 0",
          color: "#333",
        },
        h3: {
          fontSize: "1.75rem",
          fontWeight: "bold",
          margin: "0.5em 0",
          color: "#333",
        },
        h4: {
          fontSize: "1.5rem",
          fontWeight: "bold",
          margin: "0.5em 0",
          color: "#333",
        },
        h5: {
          fontSize: "1.25rem",
          fontWeight: "bold",
          margin: "0.5em 0",
          color: "#333",
        },
        h6: {
          fontSize: "1rem",
          fontWeight: "bold",
          margin: "0.5em 0",
          color: "#333",
        },
        ul: {
          listStyleType: "disc",
          paddingLeft: "20px",
          margin: "0.5em 0",
        },
        li: {
          margin: "0.2em 0",
          lineHeight: "1.5",
          color: "#333",
        },
      },
    },
  },
});

export default theme;
