import React from "react";
import CustomTable from "../../CustomTable/ui/CustomTable";
import columns from "../data/companyDaughtersColumns.json";
import { Button } from "@mui/material";

const CompanyDaughtersTable = ({
  data,
  isLoading,
  onFilterClick,
  activeFiltersText,
  onResetFilters,
}) => {
  return (
    <div>
      {isLoading ? (
        <div className="min-h-[15rem] bg-slate-300 w-full h-full animate-pulse rounded-lg mt-7"></div>
      ) : (
        <CustomTable
          columns={columns}
          data={data}
          name="Kompanije ćerke"
          translations={{ heading: { title: "Kompanije ćerke" } }}
          className="company-daughters-table mt-5"
          filtersButton={
            <Button
              onClick={onFilterClick}
              variant="contained"
              color="secondary"
              size="small"
            >
              Pretraži
            </Button>
          }
          activeFiltersText={activeFiltersText}
          onResetFilters={onResetFilters}
          pageParamName="companyDaughtersPage"
        />
      )}
    </div>
  );
};

export default CompanyDaughtersTable;
