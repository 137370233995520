import formatDate from "../../../helpers/dateHelpers";

const CompanyInformations = ({ additional_company_data, isLoading }) => {
  return (
    <div className={`@container`}>
      {isLoading ? (
        <div className="min-h-[15rem] max-h-[15rem] bg-slate-300 w-full h-full animate-pulse rounded-lg mt-7"></div>
      ) : (
        <div className="grid grid-cols-2 bg-white shadow-xl rounded-lg gap-x-5 @[1138px]:gap-x-10 gap-y-5 @[1138px]:gap-y-5 @[1150px]:justify-between px-[1rem] max-sm:px-[1rem] mt-7 py-[2rem]">
          <div className={`col-span-2 w-full`}>
            <div className="grid grid-cols-2 shrink-0 self-stretch flex-1 mt-5 @[93.438rem]:mt-0 w-full">
              {additional_company_data?.poslovno_ime && (
                <div className="col-span-1 pl-5 py-2 rounded-l-lg flex flex-col items-start justify-center bg-[#f4f7fa]">
                  <h3 className="text-[0.95rem] font-semibold">
                    Poslovno ime:{" "}
                    <span className="font-normal">
                      {additional_company_data.poslovno_ime}
                    </span>
                  </h3>
                </div>
              )}

              {additional_company_data?.pravna_forma && (
                <div className="col-span-1 pl-5 py-2 rounded-r-lg flex flex-col items-start justify-center bg-[#f4f7fa]">
                  <h3 className="text-[0.95rem] font-semibold">
                    Pravna forma:{" "}
                    <span className="font-normal">
                      {additional_company_data.pravna_forma}
                    </span>
                  </h3>
                </div>
              )}

              {additional_company_data?.datum_osnivanja && (
                <div className="col-span-1 pl-5 py-2 rounded-l-lg flex flex-col items-start justify-center">
                  <h3 className="text-[0.95rem] font-semibold">
                    Datum osnivanja:{" "}
                    <span className="font-normal">
                      {formatDate(
                        additional_company_data?.datum_osnivanja,
                        "DD.MM.YYYY."
                      )}
                    </span>
                  </h3>
                </div>
              )}

              {additional_company_data?.skraceno_poslovno_ime && (
                <div className="col-span-1 pl-5 py-2 rounded-r-lg flex flex-col items-start justify-center">
                  <h3 className="text-[0.95rem] font-semibold">
                    Skraćeno poslovno ime:{" "}
                    <span className="font-normal">
                      {additional_company_data.skraceno_poslovno_ime}
                    </span>
                  </h3>
                </div>
              )}

              {additional_company_data?.datum_brisanja && (
                <div className="col-span-2 pl-5 py-2 rounded-l-lg flex flex-col items-start justify-center bg-[#f4f7fa]">
                  <h3 className="text-[0.95rem] font-semibold">
                    Datum brisanja:{" "}
                    <span className="font-normal">
                      {additional_company_data.datum_brisanja}
                    </span>
                  </h3>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyInformations;
