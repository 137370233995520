import { createContext, useContext, useState } from "react";

const JoyrideContext = createContext();

export const JoyrideProvider = ({ children }) => {
  const [run, setRun] = useState(false);
  const [steps, setSteps] = useState([]);

  const startTour = () => setRun(true);

  const stopTour = () => setRun(false);

  return (
    <JoyrideContext.Provider
      value={{ run, startTour, stopTour, steps, setSteps }}
    >
      {children}
    </JoyrideContext.Provider>
  );
};

export const useJoyride = () => useContext(JoyrideContext);
