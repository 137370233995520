export const formatScoring = (score) => {
  if (!score || score === "-") return "N/A";
  return score.toUpperCase();
};

export const formatOwnership = (ownership) => {
  return ownership === 1 ? "Da" : "Ne";
};

export const formatColor = (color) => {
  if (!color) return "N/A";
  return color.charAt(0).toUpperCase() + color.slice(1);
};
