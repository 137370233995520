import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const Chart = ({ chartData }) => {
  //grafik za prikaz podataka po godinama
  return (
    <>
      <ResponsiveContainer
        width="95%"
        height={window.innerWidth < 768 ? 250 : 350}
        className={`!text-xs mx-auto fontSize`}
      >
        <AreaChart width={500} data={chartData?.graphData} className="text-xs">
          <CartesianGrid strokeDasharray="2 5" />
          <XAxis
            dy={15}
            dataKey="year"
            tick={{ fill: "white" }}
            tickLine={{ stroke: "white" }}
            fontSize={5}
          />
          <YAxis
            dx={-6}
            tick={{ fill: "white" }}
            tickLine={{ stroke: "white" }}
            dataKey={`value`}
            fontSize={5}
          />
          <Tooltip />
          <Area
            animationId={2}
            type="monotone"
            dataKey="value"
            stroke="#2b343b"
            fill="#286aa0"
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};

export default Chart;
