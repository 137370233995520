import { useMutation, useQuery } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useState } from "react";
import { get, post } from "./api";
import { userContext } from "./userContext";

export const NotificationsContext = createContext({
  count: 0,
  refetchNotificationsCount: () => {},
  markAsSeen: () => {},
});

export const NotificationsProvider = ({ children }) => {
  const [count, setCount] = useState(0);
  const { user } = useContext(userContext);

  const { refetch: refetchNotificationsCount } = useQuery({
    queryKey: ["notificationsCount"],
    queryFn: async () =>
      await get(`portal/notifications/list/badge-count`).then((res) =>
        setCount(res.payload?.[0]?.count)
      ),
    enabled: !!user,
  });

  useEffect(() => {
    if (user) refetchNotificationsCount();
  }, [user, refetchNotificationsCount]);

  const { mutate: markAsSeen } = useMutation({
    mutationKey: ["see-notification"],
    mutationFn: async (id) =>
      await post(`/portal/notifications/basic-data/seen`, {
        id_notifications: id,
      }),
    onSuccess: () => {
      refetchNotificationsCount();
    },
  });

  return (
    <NotificationsContext.Provider
      value={{ count, refetchNotificationsCount, markAsSeen }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationsContext);
