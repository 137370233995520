import Loader from "../../../UI/Loader/Loader";
import { translate } from "../../../utils/translate";
import { useContext } from "react";
import { userContext } from "../../../services/userContext";
import { currencyFormat } from "../../../utils/functions";

const Table = ({
  data,
  className,
  setChartData,
  chartData,
  translate_data,
}) => {
  const updateChartData = (rowName, rowSlug, year) => {
    const extractedData = (data ?? [])?.map((item) => {
      return {
        value: item[rowSlug],
        year: item.godina,
      };
    });

    setChartData({
      rowName: rowName,
      rowSlug: rowSlug,
      graphData: (extractedData ?? [])?.map((item, index) => {
        return {
          value: item?.value,
          year: item?.year,
        };
      }),
    });
  };

  const { lang } = useContext(userContext);

  return (
    <div className={`flex flex-col gap-1`}>
      <table className={`w-full `}>
        <tbody>
          <tr className={`diagramtable`}>
            <th className={`text-left font-semibold text-[0.95rem] pl-2 py-1`}>
              {translate_data?.trow?.godina ?? "Godina"}:
            </th>

            {(data ?? [])?.map((item, index) => {
              return (
                <th
                  key={index}
                  className={`text-right text-[0.95rem] !font-semibold`}
                >
                  {item?.godina}
                </th>
              );
            })}

            <th
              className={`pl-5 text-right text-[0.95rem] font-semibold pr-2 py-1`}
            >
              {translate_data?.thead?.promena ?? "Promena"}:
            </th>
          </tr>
          <tr
            className={`${className} bg-[#f4f7fa] diagramtable `}
            onClick={() => updateChartData("Ukupna aktiva", "ukupna_aktiva")}
          >
            <th className={`text-left font-semibold text-[0.95rem] pl-2 py-1`}>
              {translate_data?.trow?.ukupna_aktiva ?? "Ukupna aktiva"}:
            </th>
            {data.map((item, index) => {
              return (
                <td
                  key={index}
                  className={`text-right text-[0.95rem] !font-normal `}
                >
                  {currencyFormat(item?.ukupna_aktiva, "none")}
                </td>
              );
            })}
            <th
              className={`text-right text-[0.95rem] font-semibold pr-2 py-1`}
              style={{
                color: data?.[
                  data?.length - 1
                ]?.ukupna_aktiva_promena?.includes("-")
                  ? data[data?.length - 1]?.negative_change_color
                  : data[data?.length - 1]?.positive_change_color,
              }}
            >
              {data?.[data?.length - 1]?.ukupna_aktiva_promena}
            </th>
          </tr>
          <tr
            className={`${className} diagramtable`}
            onClick={() => updateChartData("Kapital", "kapital")}
          >
            <th className={`text-left font-semibold text-[0.95rem] pl-2 py-1`}>
              {translate_data?.trow?.kapital ?? "Kapital"}:
            </th>
            {data.map((item, index) => {
              return (
                <td
                  key={index}
                  className={`text-right text-[0.95rem] !font-normal `}
                >
                  {currencyFormat(item?.kapital, "none")}
                </td>
              );
            })}
            <th
              className={`text-right text-[0.95rem] font-semibold pr-2 py-1`}
              style={{
                color: data?.[data?.length - 1]?.kapital_promena?.includes("-")
                  ? data[data?.length - 1]?.negative_change_color
                  : data[data?.length - 1]?.positive_change_color,
              }}
            >
              {data?.[data?.length - 1]?.kapital_promena}
            </th>
          </tr>
          <tr
            className={`${className} bg-[#f4f7fa] diagramtable`}
            onClick={() => updateChartData("Obaveze", "obaveze")}
          >
            <th className={`text-left font-semibold text-[0.95rem] pl-2 py-1`}>
              {translate_data?.trow?.obaveze ?? "Obaveze"}:
            </th>
            {data.map((item, index) => {
              return (
                <td
                  key={index}
                  className={`text-right text-[0.95rem] !font-normal `}
                >
                  {currencyFormat(item?.obaveze, "none")}
                </td>
              );
            })}
            <th
              className={`text-right text-[0.95rem] font-semibold pr-2 py-1`}
              style={{
                color: data?.[data?.length - 1]?.obaveze_promena?.includes("-")
                  ? data[data?.length - 1]?.negative_change_color
                  : data[data?.length - 1]?.positive_change_color,
              }}
            >
              {data?.[data?.length - 1]?.obaveze_promena ?? ""}
            </th>
          </tr>
          <tr
            className={`${className} diagramtable`}
            onClick={() => updateChartData("Ukupni prihodi", "ukupni_prihodi")}
          >
            <th className={`text-left font-semibold text-[.95rem] pl-2 py-1`}>
              {translate_data?.trow?.ukupni_prihodi ?? "Ukupni prihodi"}:
            </th>
            {data.map((item, index) => {
              return (
                <td
                  key={index}
                  className={`text-right text-[0.95rem] !font-normal `}
                >
                  {currencyFormat(item?.ukupni_prihodi, "none")}
                </td>
              );
            })}
            <th
              className={`text-right text-[0.95rem] font-semibold pr-2 py-1`}
              style={{
                color: data?.[
                  data?.length - 1
                ]?.ukupni_prihodi_promena?.includes("-")
                  ? data[data?.length - 1]?.negative_change_color
                  : data[data?.length - 1]?.positive_change_color,
              }}
            >
              {data?.[data?.length - 1]?.ukupni_prihodi_promena}
            </th>
          </tr>
          <tr
            className={`${className} bg-[#f4f7fa] diagramtable`}
            onClick={() => updateChartData("Neto rezultat", "neto_rezultat")}
          >
            <th className={`text-left font-semibold text-[0.95rem] pl-2 py-1`}>
              {translate_data?.trow?.neto_rezultat ?? "Neto rezultat"}:
            </th>
            {data.map((item, index) => {
              return (
                <td
                  key={index}
                  className={`text-right text-[0.95rem] !font-normal `}
                >
                  {currencyFormat(item?.neto_rezultat, "none")}
                </td>
              );
            })}
            <th
              className={`text-right text-[0.95rem] font-semibold pr-2 py-1`}
              style={{
                color: data?.[
                  data?.length - 1
                ]?.neto_rezultat_promena?.includes("-")
                  ? data[data?.length - 1]?.negative_change_color
                  : data[data?.length - 1]?.positive_change_color,
              }}
            >
              {data?.[data?.length - 1]?.neto_rezultat_promena}
            </th>
          </tr>

          <tr
            className={`${className} diagramtable`}
            onClick={() =>
              updateChartData("Broj zaposlenih", "broj_zaposlenih")
            }
          >
            <th className={`text-left font-semibold text-[0.95rem] pl-2 py-1`}>
              {translate_data?.trow?.broj_zaposlenih ?? "Broj zaposlenih"}:
            </th>
            {data.map((item, index) => {
              return (
                <td
                  key={index}
                  className={`text-right text-[0.95rem] !font-normal`}
                >
                  {currencyFormat(item?.broj_zaposlenih, "none")}
                </td>
              );
            })}
            <th
              className={`text-right text-[0.95rem] font-semibold pr-2 py-1`}
              style={{
                color: data?.[
                  data?.length - 1
                ]?.broj_zaposlenih_promena?.includes("-")
                  ? data[data?.length - 1]?.negative_change_color
                  : data[data?.length - 1]?.positive_change_color,
              }}
            >
              {data?.[data?.length - 1]?.broj_zaposlenih_promena}
            </th>
          </tr>

          <tr
            className={`${className} diagramtable`}
            onClick={() =>
              updateChartData("Kreditni limit", "kreditni_limit_koeficijent")
            }
          >
            <th className={`text-left font-semibold text-[0.95rem] pl-2 py-1`}>
              {translate_data?.trow?.kreditni_limit_koeficijent ??
                "Kreditni limit"}
              :
            </th>
            {data.map((item, index) => {
              return (
                <td
                  key={index}
                  className={`text-right text-[0.95rem] !font-normal`}
                >
                  {currencyFormat(item?.kreditni_limit_koeficijent, "none")}
                </td>
              );
            })}
            <th
              className={`text-right text-[0.95rem] font-semibold pr-2 py-1`}
              style={{
                color: data?.[
                  data?.length - 1
                ]?.kreditni_limit_promena?.includes("-")
                  ? data[data?.length - 1]?.negative_change_color
                  : data[data?.length - 1]?.positive_change_color,
              }}
            >
              {data?.[data?.length - 1]?.kreditni_limit_promena}
            </th>
          </tr>

          <tr
            className={`${className} bg-[#f4f7fa] diagramtable`}
            onClick={() => updateChartData("Scoring ocena", "scoring_bodovi")}
          >
            <th className={`text-left font-semibold text-[0.95rem] pl-2 py-1`}>
              {translate_data?.trow?.scoring_bodovi ?? "Scoring ocena"}:
            </th>
            {data.map((item, index) => {
              return (
                <td
                  key={index}
                  className={`text-right text-[0.95rem] !font-normal`}
                >
                  {item?.scoring_ocena}
                </td>
              );
            })}
            <th
              className={`text-right text-[0.95rem] font-semibold pr-2 py-1`}
              style={{
                color: data[data?.length - 1]?.scoring_ocena_promena?.includes(
                  "-",
                )
                  ? data[data?.length - 1]?.negative_change_color
                  : data[data?.length - 1]?.positive_change_color,
              }}
            >
              {data[data?.length - 1]?.scoring_ocena_promena}
            </th>
          </tr>
        </tbody>
      </table>
      <p className="text-sm text-gray-500 mt-2 text-right">
        {data?.[0]?.amount_display_notification}
      </p>
    </div>
  );
};

export default Table;
