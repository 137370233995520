import CompanyInfoHeader from "../../../../components/CompanyInfoComponents/CompanyInfoHeader/CompanyInfoHeader";
import CompanyDiagram from "../../../../components/CompanyInfoComponents/CompanyDiagram/CompanyDiagram";
import CompanyInformations from "../../../../components/CompanyInfoComponents/CompanyInformations/CompanyInformations";
import Management from "../../../../components/ManagementTable/ui/Management";
import RelatedCompanies from "../../../../components/RelatedCompanies/ui/RelatedCompanies";
import OwnershipStructure from "../../../../components/OwnershipStructure/ui/OwnershipStructure";
import SisterCompanies from "../../../../components/SisterCompaniesTable/ui/SisterCompanies";
import CompanyDaughters from "../../../../components/CompanyDaughtersTable/ui/CompanyDaughters";
import SectionHeader from "../../../../components/SectionHeader/ui/SectionHeader";

import { useNavigate } from "react-router-dom";

const BasicData = ({
  open,
  company_translate_data,
  company_data,
  additional_company_data,
  code,
  isLoading,
  translate_section,
  id,
  isBasicCompanyDataLoading,
}) => {
  const navigate = useNavigate();
  const handleIconClick = () => {
    navigate(`/company-profile/spider-web/${id}`);
  };
  return (
    <div
      className={`${
        open ? `pl-[23rem] 3xl:pl-[25rem]` : ` max-md:px-[1rem] md:pl-[3rem]`
      } mx-auto pr-[3rem] transition-all duration-500 `}
    >
      <CompanyInfoHeader
        id={id}
        company_translate_data={company_translate_data}
        company_data={company_data}
        code={code}
        isLoading={isLoading}
        translate_section={translate_section}
      />
      <CompanyDiagram company_translate_data={company_translate_data} />
      <CompanyInformations
        additional_company_data={additional_company_data}
        isLoading={isBasicCompanyDataLoading}
      />
      <section className="other-important-informations">
        <div className="@container">
          <div className="bg-white shadow-xl rounded-lg px-[1rem] max-sm:px-[1rem] mt-7 pt-5 pb-[2rem]">
            <SectionHeader
              text="Ostale bitne informacije o kompaniji"
              onIconClick={handleIconClick}
            />
            <div className="flex gap-[1%]">
              <div className="flex-1">
                <Management companyId={id} />
              </div>
              <div className="flex-1">
                <OwnershipStructure companyId={id} />
              </div>
            </div>
            <SisterCompanies companyId={id} />
            <CompanyDaughters companyId={id} />
            <RelatedCompanies companyId={id} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default BasicData;
