import { useMutation, useQuery } from "@tanstack/react-query";
import FollowingModal from "../Modal/FollowingModal/FollowingModal";
import { get, post } from "../../services/api";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { Tooltip } from "@mui/material";

const BookmarkButton = ({ id, tooltipPlacement, savedIcon, unsavedIcon }) => {
  const { refetch, data } = useQuery({
    queryKey: ["is_following", id],
    queryFn: async () => {
      return await get(
        `/portal/companies/follow/${parseInt(id)}`,
        "USERS"
      )?.then((res) => {
        if (res?.payload) {
          return res?.payload;
        } else {
          return {};
        }
      });
    },
  });

  const { refetch: refetchFollowMessage } = useQuery({
    queryKey: ["followMessage", id],
    queryFn: async () => {
      return await get(
        `/portal/companies/follow/follow-message/${id}`,
        "USERS"
      )?.then((res) => {
        if (res?.payload) {
          return res?.payload;
        }
      });
    },
    enabled: false,
  });

  const { refetch: refetchUnfollowMessage } = useQuery({
    queryKey: ["unFollowMessage", id],
    queryFn: async () => {
      return await get(
        `/portal/companies/follow/unfollow-message/${id}`,
        "USERS"
      )?.then((res) => {
        if (res?.payload) {
          return res?.payload;
        }
      });
    },
    enabled: false,
  });

  const { mutate, isPending, isSuccess } = useMutation({
    mutationKey: ["follow", id],
    mutationFn: async () => {
      return await post(
        `/portal/companies/follow`,
        {
          company_id: id,
        },
        "USERS"
      )?.then((res) => {
        if (res?.payload && res?.code === 200) {
          toast.success(res?.payload?.message, {
            position: "top-center",
          });

          return res?.payload;
        } else {
          toast.error(res?.data?.message, { position: "top-center" });
          return {};
        }
      });
    },
  });

  useEffect(() => {
    refetch();
  }, [isSuccess]);

  return (
    <>
      {data && (
        <FollowingModal
          onConfirm={mutate}
          renderButton={({ onClick }) => (
            <Tooltip title={data.tooltip} placement={tooltipPlacement}>
              <button
                disabled={isPending}
                onClick={() => {
                  if (data.follow) {
                    refetchUnfollowMessage()?.then((res) => onClick(res?.data));
                  } else {
                    refetchFollowMessage()?.then((res) => onClick(res?.data));
                  }
                }}
                className={`${
                  data.follow
                    ? "!text-green-500"
                    : "!text-transparent group-hover:!text-green-500"
                } cursor-pointer !size-5 min-w-[1.5rem] hover:!text-green-600`}
              >
                {data.follow ? savedIcon : unsavedIcon}
              </button>
            </Tooltip>
          )}
        />
      )}
    </>
  );
};

export default BookmarkButton;
