import { useContext, useEffect } from "react";
import { get, post } from "../../../services/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid, Stack, Typography } from "@mui/material";
import CustomInput from "../../CustomInput/ui/CustomInput";
import { usePersonalInfoFormValidation } from "../../../hooks/usePersonalInfoFormValidation";
import { Section } from "../../shared/section";
import { toast } from "react-toastify";
import { useTranslate } from "../../../services/hooks";
import jsonFormFields from "../data/PersonalInfoTableColumns.json";
import { saveButtonTranslations } from "../../../utils/translations";
import { userContext } from "../../../services/userContext";
import { useValidationSchema } from "../../../hooks/useValidationSchema";

const formFields = jsonFormFields.reduce((acc, field) => {
  acc[field.prop_name] = field.prop_name;
  return acc;
}, {});

const PersonalInfoForm = () => {
  const { data: translations } = useTranslate("profile", "personal-data") || {};

  const {
    profile: { "personal-data": { form: personalDataTranslations } = {} } = {},
  } = translations || {};

  const { lang } = useContext(userContext);

  const { data: validations } = useTranslate("global", "form") || {};

  const {
    global: { form: { validation: validationMessagesTranslations } = {} } = {},
  } = validations || {};

  const validationSchema = useValidationSchema(
    usePersonalInfoFormValidation,
    formFields,
    personalDataTranslations,
    validationMessagesTranslations
  );

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    reset,
    trigger,
  } = useForm({
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
    mode: "onChange",
    defaultValues: Object.keys(formFields).reduce((acc, key) => {
      acc[key] = "";
      return acc;
    }, {}),
  });

  const { data, isSuccess } = useQuery({
    queryKey: ["user"],
    queryFn: async () => {
      return await get(`/portal/profile/user`).then((res) => res.payload || {});
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isSuccess) {
      reset(
        Object.keys(formFields).reduce((acc, key) => {
          acc[key] = data?.[key] || "";
          return acc;
        }, {})
      );
    }
  }, [isSuccess, data, reset]);

  const { mutate } = useMutation({
    mutationFn: async (formData) => {
      return await post(`/portal/profile/user`, {
        ...formData,
        id: data.id,
        contracts_id: data.contracts_id,
      }).then((res) => res.payload);
    },
    onSuccess: (payload) => toast.success(payload?.message),
    onError: (payload) => toast.error(payload?.message),
    refetchOnWindowFocus: false,
  });

  return (
    <Section>
      <Stack gap={5}>
        <Typography>{personalDataTranslations?.heading.title}</Typography>
        <form onSubmit={handleSubmit(mutate)} className="flex flex-col gap-5">
          <Grid gap={2} className="grid grid-cols-2">
            {Object.keys(formFields).map((key) => (
              <Controller
                key={key}
                name={key}
                control={control}
                render={({ field }) => (
                  <CustomInput
                    variant={`outlined`}
                    label={
                      personalDataTranslations?.[`${field.name}_input`]?.label
                    }
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      trigger(field.name);
                    }}
                    error={!!errors[field.name]?.message}
                    helperText={errors[field.name]?.message}
                  />
                )}
              />
            ))}
          </Grid>
          <Grid container justifyContent={`flex-end`}>
            <Button
              type="submit"
              className="justify-end"
              variant="contained"
              color="success"
              disabled={!isValid}
            >
              {saveButtonTranslations[lang]}
            </Button>
          </Grid>
        </form>
      </Stack>
    </Section>
  );
};

export default PersonalInfoForm;
