import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  createFilterOptions,
  FormControl,
  FormControlLabel,
  Icon,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useQuery } from "@tanstack/react-query";
import { get } from "../../services/api";
import { Spinner } from "./spinner";
import { useFilterNames } from "../../services/hooks";

export const Input = ({
  onChange,
  filter,
  tempSelectedFilters,
  onSubmit,
  translate_data,
}) => {
  switch (filter?.field_type) {
    case "text":
      return (
        <TextInput
          onChange={onChange}
          filter={filter}
          tempSelectedFilters={tempSelectedFilters}
          onSubmit={onSubmit}
        />
      );
    case "number_range":
      return (
        <RangeNumberInput
          onChange={onChange}
          filter={filter}
          onSubmit={onSubmit}
          tempSelectedFilters={tempSelectedFilters}
        />
      );
    case "date_range":
      return (
        <DateRangeInput
          onChange={onChange}
          filter={filter}
          onSubmit={onSubmit}
          tempSelectedFilters={tempSelectedFilters}
        />
      );
    case "autocomplete":
      return (
        <AutocompleteInput
          onChange={onChange}
          filter={filter}
          onSubmit={onSubmit}
          tempSelectedFilters={tempSelectedFilters}
        />
      );
    case "select":
      return (
        <SelectInput
          onChange={onChange}
          onSubmit={onSubmit}
          filter={filter}
          tempSelectedFilters={tempSelectedFilters}
        />
      );
    case "multiselect":
      return (
        <MultiSelectInput
          onChange={onChange}
          filter={filter}
          onSubmit={onSubmit}
          tempSelectedFilters={tempSelectedFilters}
        />
      );
    case "date":
      return (
        <DateInput onChange={onChange} filter={filter} onSubmit={onSubmit} />
      );
    case "checkbox":
      return (
        <CheckBoxInput
          onChange={onChange}
          filter={filter}
          translate_data={translate_data}
        />
      );
    case "tree_view":
      return (
        <TreeView
          onChange={onChange}
          filter={filter}
          tempSelectedFilters={tempSelectedFilters}
          translate_data={translate_data}
        />
      );
    case "range_select":
      return (
        <RangeSelectInput
          onChange={onChange}
          filter={filter}
          tempSelectedFilters={tempSelectedFilters}
        />
      );
  }
};

const TreeView = ({
  onChange,
  filter,
  tempSelectedFilters,
  translate_data,
  onSubmit,
}) => {
  const [search, setSearch] = useState("");
  const [selectedOption, setSelectedOption] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const val = `${
    tempSelectedFilters?.find((f) => f?.filter_code === filter?.filter_code)
      ?.value?.selected
  }`;

  useEffect(() => {
    if (val !== "undefined" && val !== null && val !== "null") {
      const selected_items = (val ?? ",")?.split(",");
      setSelectedOption(selected_items);
    } else {
      setSelectedOption([]);
    }
  }, [val]);

  const { data: options, isLoading } = useQuery({
    queryKey: ["DDL", filter?.id, filter?.api_url, search],
    queryFn: async () => {
      return await get(`${filter?.api_url}?search=${search}`).then((res) => {
        return res?.payload;
      });
    },
  });

  const { data: tree_options, isLoading: isFetching } = useQuery({
    queryKey: ["tree_options", filter?.id, filter?.api_url, openModal],
    queryFn: async () => {
      if (openModal) {
        return await get(`${filter?.api_url}/tree`).then((res) => {
          return res;
        });
      }
    },
  });

  const optionsLimit = 50;
  const defaultOptions = createFilterOptions();
  const filterOptions = (options, params) => {
    return defaultOptions(options, params).slice(0, optionsLimit);
  };

  useEffect(() => {
    if (!isLoading && val !== "undefined" && val !== null && val !== "null") {
      const selected_items = (val ?? ",")?.split(",");
      setSelectedOption(selected_items);
    }
  }, [val]);
  const handleClick = (option) => {
    const code = option?.name?.split("-")?.[0]?.trim();
    if (!selectedOption.includes(code)) {
      const newValue = [...selectedOption, code];
      setSelectedOption(newValue);
      onChange({
        target: {
          name: filter?.filter_code,
          value: newValue.join(","),
        },
      });
    }
    setOpenModal(false);
  };

  const renderAccordions = (options) => {
    return options?.map((option) =>
      option?.children ? (
        <Accordion key={option?.id} className={`!border-none`}>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={`group`}
            onDoubleClick={() => {
              handleClick(option);
            }}
          >
            <Typography className={`!relative`}>
              {option?.name}
              <Button
                variant={`contained`}
                color={`success`}
                className={`!hidden group-hover:!block !absolute right-[-12rem] top-[-30%]`}
                onClick={() => {
                  handleClick(option);
                }}
              >
                {translate_data?.["search-modal"]?.["default"]?.[
                  "activity_tree"
                ]?.["apply_option"]?.["label"] ?? "Primeni opciju"}
              </Button>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {renderAccordions(option?.children)}
          </AccordionDetails>
        </Accordion>
      ) : (
        <Typography
          className={`hover:!underline cursor-pointer !relative`}
          onClick={() => {
            handleClick(option);
          }}
        >
          {option?.name}
        </Typography>
      ),
    );
  };

  return (
    <>
      <>
        <FormControl
          sx={{ width: "100%", paddingTop: "" }}
          className={`!flex !flex-row items-center gap-2`}
        >
          <Autocomplete
            multiple
            className={`!flex-1`}
            readOnly={filter?.read_only}
            disabled={filter?.read_only}
            placeholder={filter?.name}
            isOptionEqualToValue={(option, value) => option === value}
            value={selectedOption}
            onInputChange={(event, newInputValue) => {
              let newIval = newInputValue ? newInputValue : "";
              setSearch(newIval);
            }}
            onChange={(event, newValue) => {
              if (newValue !== null) {
                setSelectedOption(newValue);
              } else {
                setSelectedOption("");
              }
              onChange({
                target: {
                  name: filter?.filter_code,
                  value: Array.isArray(newValue)
                    ? newValue
                        .map((option) => option?.split("-")?.[0]?.trim())
                        .join(",")
                    : "",
                },
              });
            }}
            noOptionsText={""}
            options={(options ?? [])?.map((option) => option?.name)}
            filterOptions={filterOptions}
            autoComplete
            renderInput={(params) => (
              <TextField {...params} label={filter?.name} />
            )}
          />
          {/*modal button*/}
          <Button
            variant="contained"
            className={`!shadow-none !self-stretch`}
            color={`info`}
            onClick={() => setOpenModal(true)}
          >
            <img
              src={`/folder.png`}
              width={25}
              height={25}
              className={`invert`}
            />
          </Button>
        </FormControl>
        <div
          onClick={(e) => {
            if (e.target === e.currentTarget) setOpenModal(false);
          }}
          className={
            openModal
              ? `fixed visible opacity-100 top-0 left-0 z-50 bg-black/60 w-full h-[100dvh] flex flex-col items-center justify-center`
              : `fixed invisible opacity-0 top-0 left-0 z-50 bg-black/60 w-full h-[100dvh] flex flex-col items-center justify-center`
          }
        >
          <div
            className={`bg-white max-h-[80%] my-auto overflow-y-scroll max-w-[80%] mx-auto py-10 pl-10 pr-10`}
          >
            <div className={`flex items-center justify-between pb-5`}>
              <h2 className={`text-2xl font-semibold  text-left`}>
                {translate_data?.["search-modal"]?.["default"]?.[
                  "activity_tree"
                ]?.["modal"]?.["title"] ?? "Izaberite opciju"}
              </h2>
              <p
                className={`hover:text-red-500 cursor-pointer text-2xl`}
                onClick={() => setOpenModal(false)}
              >
                x
              </p>
            </div>
            {isFetching ? <Spinner /> : renderAccordions(tree_options)}
          </div>
        </div>
      </>
    </>
  );
};

const AutocompleteInput = ({ onChange, filter, tempSelectedFilters }) => {
  const [search, setSearch] = useState("");

  const val = tempSelectedFilters?.find(
    (f) => f?.filter_code === filter?.filter_code,
  )?.value?.selected?.[0];

  const [selectedOption, setSelectedOption] = useState(val ?? "");

  const { data: options, isLoading } = useQuery({
    queryKey: ["DDL", filter?.id, filter?.api_url, search],
    queryFn: async () => {
      switch (filter?.filter_code) {
        case "drzava":
          return await get(`${filter?.api_url}`).then((res) => res?.payload);
        default:
          return await get(`${filter?.api_url}?search=${search}`).then(
            (res) => res?.payload,
          );
      }
    },
  });

  useEffect(() => {
    if (options && val) {
      if (filter?.filter_code === "adresa") {
        setSelectedOption(options?.find((x) => x?.name === val)?.name);
      } else {
        setSelectedOption(
          options?.find((x) => Number(x?.id) === Number(val))?.name,
        );
      }
    }
  }, []);

  useEffect(() => {
    if (filter?.filter_code === "drzava" && !isLoading) {
      setSelectedOption(options?.[0]?.name);
      onChange({
        target: {
          name: filter?.filter_code,
          value: options[0]?.id,
        },
      });
    }
  }, []);

  const handleInputChange = (event, newInputValue) => {
    setSearch(newInputValue || "");
  };

  const handleOptionChange = (event, newValue) => {
    setSelectedOption(newValue);
    const val = options?.find((x) => x?.name === newValue)?.id;
    onChange({
      target: {
        name: filter?.filter_code,
        value: filter?.filter_code === "adresa" ? newValue : val,
      },
    });
  };

  const filterOptions = (options, params) => {
    const filtered = createFilterOptions()(options, params);
    return filtered.slice(0, 50);
  };

  return (
    <FormControl sx={{ width: "100%", paddingTop: "" }}>
      <Autocomplete
        readOnly={filter?.read_only}
        disabled={filter?.read_only}
        placeholder={filter?.name}
        value={selectedOption}
        onInputChange={handleInputChange}
        onChange={handleOptionChange}
        isOptionEqualToValue={(option, value) => option === value}
        noOptionsText={""}
        options={(options ?? []).map((option) => option?.name)}
        filterOptions={filterOptions}
        autoComplete
        renderInput={(params) => <TextField {...params} label={filter?.name} />}
      />
    </FormControl>
  );
};

const CheckBoxInput = ({
  onChange,
  filter,
  tempSelectedFilters,
  onSubmit,
  translate_data,
}) => {
  return (
    <FormControl sx={{ width: "100%", paddingTop: "" }}>
      {filter?.options?.map((option) => {
        return (
          <FormControlLabel
            className={`!text-black`}
            control={
              <Checkbox
                color={`success`}
                value={option?.prop_name}
                key={option?.key}
                checked={option?.visible}
              />
            }
            label={translate_data?.[option?.prop_name] ?? option.name}
            key={option.id}
            onChange={onChange}
          />
        );
      })}
    </FormControl>
  );
};

const TextInput = ({ onChange, filter, tempSelectedFilters, onSubmit }) => {
  const val = `${
    tempSelectedFilters?.find((f) => f?.filter_code === filter?.filter_code)
      ?.value?.selected?.[0]
  }`;

  const [value, setValue] = useState(val !== "undefined" ? val : "");

  useEffect(() => {
    if (val !== "undefined") {
      setValue(val);
    } else {
      setValue("");
    }
  }, [val]);

  const handleChange = ({ target: { value, name } }) => {
    setValue(value);
    onChange({
      target: {
        name: name,
        value: value,
      },
    });
  };

  return (
    <FormControl sx={{ width: "100%", paddingTop: "" }} onSubmit={onSubmit}>
      <TextField
        value={value}
        label={filter?.name}
        onChange={handleChange}
        name={filter?.filter_code}
      />
    </FormControl>
  );
};

export const SelectInput = ({
  onChange,
  filter,
  tempSelectedFilters,
  onSubmit,
}) => {
  const val = `${
    tempSelectedFilters?.find((f) => f?.filter_code === filter?.filter_code)
      ?.value?.selected
  }`;

  const { data: options, isLoading } = useQuery({
    queryKey: ["DDL", filter?.id, filter?.api_url],
    queryFn: async () => {
      return await get(filter?.api_url).then((res) => res?.payload);
    },
  });

  return (
    <FormControl sx={{ width: "100%", paddingTop: "" }} onSubmit={onSubmit}>
      <InputLabel>{filter?.name}</InputLabel>
      {!isLoading && (
        <Select
          value={
            val !== "undefined" && val !== "null" && val !== null ? val : ""
          }
          onChange={onChange}
          label={filter?.name}
          placeholder={filter?.name}
          name={filter?.filter_code}
        >
          {(options ?? filter?.options).map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};

const RangeSelectInput = ({
  onChange,
  filter,
  tempSelectedFilters,
  onSubmit,
}) => {
  const val = `${
    tempSelectedFilters?.find((f) => f?.filter_code === filter?.filter_code)
      ?.value?.selected
  }`;

  const { data: options, isLoading } = useQuery({
    queryKey: ["DDL", filter?.id, filter?.api_url],
    queryFn: async () => {
      return await get(filter?.api_url).then((res) => res?.payload);
    },
  });

  const [selected, setSelected] = useState({
    first: {
      options: options,
      value: null,
    },
    second: {
      options: options,
      value: null,
    },
  });

  const [tempOptions, setTempOptions] = useState([]);

  const getNewOptions = async (position, value) => {
    return await get(`${filter?.api_url}?${position}=${value}`).then((res) => {
      switch (position) {
        case "from":
          setSelected({
            first: {
              ...selected?.first,
              value: value,
            },
            second: {
              ...selected?.second,
              options: res?.payload,
            },
          });
          break;
        case "to":
          setSelected({
            first: {
              ...selected?.first,
              options: res?.payload,
            },
            second: {
              ...selected?.second,
              value: value,
            },
          });
          break;
        default:
          break;
      }
    });
  };

  const handleFirstChange = (event) => {
    const {
      target: { value },
    } = event;

    setSelected({
      ...selected,
      first: {
        ...selected.first,
        value: value,
      },
    });

    onChange({
      target: {
        name: filter?.filter_code,
        value: `${value},${selected?.second?.value}`,
      },
    });

    const timeout = setTimeout(() => {
      getNewOptions("from", value);
    }, 200);

    return () => clearTimeout(timeout);
  };

  const handleSecondChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelected({
      ...selected,
      second: {
        ...selected.second,
        value: value,
      },
    });

    onChange({
      target: {
        name: filter?.filter_code,
        value: `${selected?.first?.value},${value}`,
      },
    });
    const timeout = setTimeout(() => {
      getNewOptions("to", value);
    }, 200);

    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    if (val !== "undefined" && val !== null && val !== "null") {
      const selected_items = (val ?? ",")?.split(",");
      setSelected({
        first: {
          options: options,
          value: selected_items?.[0],
        },
        second: {
          options: options,
          value: selected_items?.[1],
        },
      });
    } else {
      setSelected({
        first: {
          options: options,
          value: null,
        },
        second: {
          options: options,
          value: null,
        },
      });
    }
  }, []);

  return (
    <div className={`flex items-center mb-auto gap-3`}>
      <FormControl sx={{ width: "100%", paddingTop: "" }} onSubmit={onSubmit}>
        <InputLabel
          id={`
            first-${filter?.filter_code}-label
        `}
        >
          {filter?.name} {filter?.from}
        </InputLabel>
        {!isLoading && (
          <Select
            onMouseEnter={() => {
              if (selected?.first?.value) {
                getNewOptions("from", selected?.first?.value);
              }
            }}
            key={filter?.filter_code}
            value={
              selected?.first?.value !== null ? selected?.first?.value : ""
            }
            onChange={handleFirstChange}
            label={filter?.name}
            placeholder={filter?.name}
            name={filter?.filter_code}
          >
            {(selected?.first?.options?.length > 0
              ? selected?.first?.options
              : options
            ).map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
      -
      <FormControl sx={{ width: "100%", paddingTop: "" }} onSubmit={onSubmit}>
        <InputLabel
          id={`
            second-${filter?.filter_code}-label
        `}
        >
          {filter?.name} {filter?.to}
        </InputLabel>

        {!isLoading && (
          <Select
            onMouseEnter={() => {
              if (selected?.second?.value) {
                getNewOptions("to", selected?.second?.value);
              }
            }}
            key={filter?.filter_code}
            value={
              selected?.second?.value !== null ? selected?.second?.value : ""
            }
            onChange={handleSecondChange}
            label={filter?.name}
            placeholder={filter?.name}
            name={filter?.filter_code}
          >
            {(selected?.second?.options?.length > 0
              ? selected?.second?.options
              : options
            ).map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
      {(selected?.first?.value || selected?.second?.value) && (
        <Typography
          variant={`button`}
          sx={{
            cursor: "pointer",
            "&:hover": {
              color: "red",
            },
          }}
          onClick={(e) => {
            setSelected({
              first: {
                value: null,
                options: options,
              },
              second: {
                value: null,
                options: options,
              },
            });
            onChange({
              target: {
                name: filter?.filter_code,
                value: [],
              },
            });
          }}
        >
          X
        </Typography>
      )}
    </div>
  );
};

const MultiSelectInput = ({
  onChange,
  filter,
  onSubmit,
  tempSelectedFilters,
}) => {
  const [selected, setSelected] = useState([]);
  const { data: options, isLoading } = useQuery({
    queryKey: ["DDL", filter?.id, filter?.api_url],
    queryFn: async () => {
      return await get(filter?.api_url).then((res) => res?.payload);
    },
  });

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const filteredValue = Array.isArray(value)
      ? value.filter((v) => !isNaN(v))
      : isNaN(value)
        ? ""
        : value;

    setSelected(filteredValue);
    onChange({
      target: {
        name: filter?.filter_code,
        value: filteredValue,
      },
    });
  };

  const val = `${
    tempSelectedFilters?.find((f) => f?.filter_code === filter?.filter_code)
      ?.value?.selected
  }`;

  const val_tmp = (val ?? ",")?.split(",");

  useEffect(() => {
    if (val_tmp?.length > 0 && val_tmp?.[0] !== "undefined") {
      const numbers = val_tmp?.map((str) => parseInt(str, 10));
      setSelected(numbers);
    } else {
      setSelected([]);
    }
  }, [val]);

  return (
    <FormControl sx={{ width: "100%", paddingTop: "" }} onSubmit={onSubmit}>
      <InputLabel>{filter?.name}</InputLabel>
      <Select
        defaultValue={[1]}
        multiple
        value={selected}
        onChange={handleChange}
        label={filter?.name}
        placeholder={filter?.name}
        name={filter?.filter_code}
        renderValue={(selected) => {
          if (selected?.length > 0) {
            return selected
              .map((id) => options?.find((o) => o?.id === id)?.name)
              .join(", ");
          }
        }}
      >
        {(options ?? [])?.map((option) => (
          <MenuItem key={option.id} value={option?.id}>
            <Checkbox checked={selected?.includes(option?.id)} />
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const RangeNumberInput = ({
  onChange,
  filter,
  tempSelectedFilters,
  onSubmit,
}) => {
  const val = tempSelectedFilters?.find(
    (x) => x?.filter_code === filter?.filter_code,
  )?.value?.selected;

  const [selected, setSelected] = useState(val ?? []);

  const handleFirstInputChange = (e) => {
    setSelected([e?.target?.value, selected[1]]);
    onChange({
      target: {
        name: filter?.filter_code,
        id: filter?.table_field,
        value: [e?.target?.value, selected[1]],
      },
    });
  };

  const handleSecondInputChange = (e) => {
    setSelected([selected[0], e?.target?.value]);
    onChange({
      target: {
        name: filter?.filter_code,
        id: filter?.table_field,
        value: [selected[0], e?.target?.value],
      },
    });
  };

  return (
    <FormControl sx={{ width: "100%", paddingTop: "" }} onSubmit={onSubmit}>
      <div className={`flex items-center gap-3`}>
        <TextField
          id={filter?.table_field}
          value={selected[0]}
          sx={{ width: "100%" }}
          onChange={handleFirstInputChange}
          label={`${filter?.name} ${filter?.from}`}
          name={filter?.filter_code}
        />
        -
        <TextField
          id={filter?.table_field}
          sx={{ width: "100%" }}
          value={selected[1]}
          onChange={handleSecondInputChange}
          label={`${filter?.name} ${filter?.to}`}
          name={filter?.filter_code}
        />
      </div>
      {filter?.description && (
        <Typography
          sx={{
            marginTop: "0.5rem",
          }}
          variant={`caption`}
        >
          {filter?.description} {filter?.currency}
        </Typography>
      )}
    </FormControl>
  );
};

const DateInput = ({ onChange, filter, onSubmit }) => {
  const handleDateChange = (date) => {
    const day = date["$D"];
    const month = date["$M"] + 1;
    const year = date["$y"];

    const formattedDate = `${year}-${month}-${day}`;
    onChange({
      target: {
        name: filter?.column,
        value: formattedDate,
      },
    });
  };

  return (
    <FormControl sx={{ width: 300, paddingTop: "" }} onSubmit={onSubmit}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]} sx={{ p: 0 }}>
          <DatePicker
            label={filter?.name}
            // localeText={{ from: "Od", to: "Do" }}
            name={filter?.filter_code}
            onChange={handleDateChange}
          />
        </DemoContainer>
      </LocalizationProvider>
    </FormControl>
  );
};

const DateRangeInput = ({
  onChange,
  filter,
  onSubmit,
  tempSelectedFilters,
}) => {
  const [selectedDates, setSelectedDates] = useState([null, null]);

  const [firstValue, setFirstValue] = useState();
  const [secondValue, setSecondValue] = useState();

  const handleFirstInputDateChange = (date) => {
    const day = date["$D"];
    const month = date["$M"] + 1;
    const year = date["$y"];

    const formattedDate = `${year}-${month}-${day}`;
    setSelectedDates([formattedDate, selectedDates[1]]);
  };

  const handleSecondInputDateChange = (date) => {
    const day = date["$D"];
    const month = date["$M"] + 1;
    const year = date["$y"];

    const formattedDate = `${year}-${month}-${day}`;
    setSelectedDates([selectedDates[0], formattedDate]);
  };

  useEffect(() => {
    if (selectedDates[0] || selectedDates[1]) {
      onChange({
        target: {
          name: filter?.filter_code,
          value: [selectedDates[0], selectedDates[1]],
        },
      });
    }
  }, [selectedDates]);

  // const val = tempSelectedFilters?.find(
  //   (x) => x?.filter_code === filter?.filter_code
  // )?.value?.selected?.[0];
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      onSubmit={onSubmit}
      sx={{ paddingTop: "" }}
    >
      <DemoContainer components={["DatePicker"]} sx={{ paddingTop: "" }}>
        <div className={`flex items-center gap-5`}>
          <DatePicker
            label={`${filter?.name} ${filter?.from}`}
            value={firstValue}
            name={filter?.filter_code}
            onChange={(e) => {
              handleFirstInputDateChange(e);
              setFirstValue(e);
            }}
          />
          -
          <DatePicker
            value={secondValue}
            label={`${filter?.name} ${filter?.to}`}
            onChange={(e) => {
              handleSecondInputDateChange(e);
              setSecondValue(e);
            }}
          />
        </div>
      </DemoContainer>
    </LocalizationProvider>
  );
};
