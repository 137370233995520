import { Pagination, PaginationItem, Stack, Typography } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";

const CustomTablePagination = ({ pagination, pageParamName = "page" }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  if (!pagination || pagination.total_pages < 1) {
    return null;
  }

  const showingFrom =
    pagination.items_per_page * (pagination.selected_page - 1) + 1;
  const showingTo = Math.min(
    pagination.items_per_page * pagination.selected_page,
    pagination.total_items
  );

  const handlePageChange = (_, newPage) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set(pageParamName, newPage);
    setSearchParams(newParams);
  };

  return (
    <Stack
      sx={{
        alignItems: "center",
        my: 2,
      }}
    >
      <Typography
        sx={{ color: "rgba(0, 0, 0, 0.63)", pb: "0.5em", fontSize: "1rem" }}
      >
        {`${showingFrom} - ${showingTo} od ${pagination.total_items}`}
      </Typography>

      <Pagination
        count={pagination.total_pages}
        onChange={handlePageChange}
        page={pagination.selected_page}
        color={"primary"}
        variant="outlined"
        shape="rounded"
        siblingCount={7}
      >
        <PaginationItem />
      </Pagination>
    </Stack>
  );
};

export default CustomTablePagination;
