import {
  Close,
  ContactSupport,
  Mail,
  PermContactCalendar,
  Phone,
} from "@material-ui/icons";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useTranslate } from "../../../services/hooks";
import { useQuery } from "@tanstack/react-query";
import { get } from "../../../services/api";

const ContractAdvisor = () => {
  const [isOpen, setIsOpen] = useState(false);

  const theme = useTheme();

  const { data } = useQuery({
    queryKey: ["advisor"],
    queryFn: async () =>
      await get(`portal/profile/contacts`).then((res) => res.payload),
  });

  const { data: translations } = useTranslate("general", "default") || {};

  const { general: { default: advisorTranslations } = {} } = translations || {};

  const handleCloseWindow = () => setIsOpen(false);
  const handleOpenWindow = () => setIsOpen(true);

  const title = advisorTranslations?.advisor?.modal?.title;

  return (
    <>
      {data && (
        <Box
          sx={{
            position: `fixed`,
            right: 12,
            bottom: 20,
          }}
        >
          {isOpen ? (
            <Stack
              bgcolor={theme.palette.primary.main}
              width={`fit-content`}
              color={`white`}
              borderRadius={2}
              p={3}
              gap={2}
            >
              <Close
                onClick={handleCloseWindow}
                style={{
                  position: `absolute`,
                  right: 3,
                  top: 3,
                  width: 30,
                  height: 30,
                  cursor: `pointer`,
                }}
              />
              <h4 className="text-center text-xl font-semibold text-white">
                {title}
              </h4>
              <Grid container justifyContent={`center`}>
                <ContactSupport
                  style={{
                    width: 55,
                    height: 55,
                  }}
                />
              </Grid>
              <Grid container gap={2}>
                <PermContactCalendar />
                <Typography fontWeight={`bolder`}>{data.name}</Typography>
              </Grid>
              <Grid container gap={2}>
                <Phone />
                <Link to={`tel:${data.phone}`}>{data.phone}</Link>
              </Grid>
              <Grid container gap={2}>
                <Mail />
                <Link to={`mailto:${data.email}`}>{data.email}</Link>
              </Grid>
            </Stack>
          ) : (
            <Tooltip title={title} placement={`left`}>
              <IconButton
                onClick={handleOpenWindow}
                sx={{
                  backgroundColor: "white",
                  color: theme.palette.primary.main,
                  ":hover": {
                    backgroundColor: "white",
                  },
                  boxShadow:
                    "0px 3px 3px -2px rgba(0, 0, 0, 0.25), 0px 3px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 8px 0px rgba(0, 0, 0, 0.25)",
                  width: 50,
                  height: 50,
                }}
              >
                <ContactSupport style={{ width: "100%", height: "100%" }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      )}
    </>
  );
};

export default ContractAdvisor;
