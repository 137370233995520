import Home from "../../assets/Icons/3844435_home_house_icon.png";
import Spiderweb from "../../assets/Icons/people.png";
import History from "../../assets/Icons/history.png";
import Limits from "../../assets/Icons/block-user.png";
import Racuni from "../../assets/Icons/bill.png";
import Blockages from "../../assets/Icons/Icons/block.png";
import Scoring from "../../assets/Icons/5-stars.png";
import Bilansi from "../../assets/Icons/Icons/report.png";
import Menice from "../../assets/Icons/invoice.png";
import Izvrsenja from "../../assets/Icons/auction (1).png";
import Zalog from "../../assets/Icons/document.png";
import Lizing from "../../assets/Icons/key.png";
import VozniPark from "../../assets/Icons/protection.png";
import Uvoz from "../../assets/Icons/download.png";
import Izvoz from "../../assets/Icons/export.png";
import Nekretnine from "../../assets/Icons/house.png";
import Dozvole from "../../assets/Icons/enter.png";
import Zabelezbe from "../../assets/Icons/file.png";
import Tenderi from "../../assets/Icons/hand-shake.png";
import Vesti from "../../assets/Icons/news.png";
import Kontakti from "../../assets/Icons/contact.png";
import Chevron from "../../assets/Icons/right-chevron.png";
import { Link, useLocation, useParams } from "react-router-dom";
import BookmarkButton from "../../components/BookmarkButton/BookmarkButton";
import { icons } from "../../lib/icons";

const Sidebar = ({ setOpen, open, company_data, menu_data }) => {
  const { id, page } = useParams();
  const params = useLocation();

  return (
    <>
      <div className={`fixed top-[4.6rem] bottom-[0] left-0  max-md:hidden`}>
        <div
          className={
            open
              ? `bg-[#202831] z-[5] transition-all duration-500 translate-x-0 xl:w-[270px] 3xl:w-[320px] 4xl:w-[350px] h-full flex flex-col`
              : `bg-[#202831] -translate-x-[100%] duration-700 transition-all xl:w-[270px] 3xl:w-[320px] 4xl:w-[350px] h-full flex flex-col`
          }
        >
          <div className={`flex flex-col py-2 relative bg-[#2b343b] pl-6 pr-4`}>
            <p className={`text-white text-base`}>
              {menu_data?.["menu"]?.["default"]?.["header"]?.["company"]?.[
                "label"
              ] ?? "Trenutno gledate profil"}
            </p>
            <div className={`flex items-center gap-1`}>
              <p className={`text-white text-lg font-medium uppercase`}>
                {company_data?.naziv_skraceni}
              </p>
              <BookmarkButton
                id={id}
                tooltipPlacement={`right`}
                savedIcon={icons.save_no_stroke_white}
                unsavedIcon={icons.save_no_stroke_white}
              />
            </div>
            <div
              className={`absolute -right-[0.82rem] ${
                !open && `-right-[2.5rem]`
              } top-1/2 transform -translate-y-1/2 bg-white rounded-full shadow-xl shadow-black cursor-pointer`}
              onClick={() => setOpen(!open)}
            >
              <img
                src={Chevron}
                alt={``}
                width={`23px`}
                className={`p-1 ${open ? "rotate-180" : ""}`}
              />
            </div>
          </div>
          <div
            className={`w-[85%] mx-auto my-5 flex-1 overflow-y-auto customscroll`}
          >
            <div className={`flex flex-col gap-3 h-full overflow-y-auto`}>
              <div
                className={`flex items-center gap-3 hover:font-semibold cursor-pointer`}
              >
                <img src={Home} width={"20"} className={`invert`} />
                <Link
                  to={`/company-profile/basic-data/${id}${
                    params.search ? params.search : ""
                  }`}
                  className={`text-white ${
                    page === "basic-data" && "font-semibold"
                  } text-base`}
                >
                  {menu_data?.["menu"]?.["default"]?.["item"]?.["basic_data"]?.[
                    "label"
                  ] ?? "Osnovni podaci"}
                </Link>
              </div>
              <div
                className={`flex items-center gap-3 hover:font-semibold cursor-pointer`}
              >
                <img src={Spiderweb} width={"20"} className={`invert`} />
                <Link
                  to={`/company-profile/spider-web/${id}${
                    params.search ? params.search : ""
                  }`}
                  className={`text-white ${
                    page === "spider-web" && "font-semibold"
                  } text-base`}
                >
                  {menu_data?.["menu"]?.["default"]?.["item"]?.["spider_net"]?.[
                    "label"
                  ] ?? "Paukova mreža"}
                </Link>
              </div>
              <div
                className={`flex items-center gap-3 hover:font-semibold cursor-pointer`}
              >
                <img src={History} width={"20"} className={`invert`} />
                <Link
                  to={`/company-profile/history/${id}${
                    params.search ? params.search : ""
                  }`}
                  className={`text-white ${
                    page === "history" && "font-semibold"
                  } text-base`}
                >
                  {menu_data?.["menu"]?.["default"]?.["item"]?.["history"]?.[
                    "label"
                  ] ?? "Istorijat"}
                </Link>
              </div>
              <div
                className={`flex items-center gap-3 hover:font-semibold cursor-pointer`}
              >
                <img src={Limits} width={"20"} className={`invert`} />
                <h1 className={`text-white text-base`}>
                  {menu_data?.["menu"]?.["default"]?.["item"]?.[
                    "restrictions"
                  ]?.["label"] ?? "Ograničenja"}
                </h1>
              </div>
              <div
                className={`flex items-center gap-3 hover:font-semibold cursor-pointer`}
              >
                <img src={Blockages} width={"20"} className={``} />
                <h1 className={`text-white text-base`}>
                  {menu_data?.["menu"]?.["default"]?.["item"]?.["blockages"]?.[
                    "label"
                  ] ?? "Blokade"}
                </h1>
              </div>
              <div
                className={`flex items-center gap-3 hover:font-semibold cursor-pointer`}
              >
                <img src={Racuni} width={"20"} className={`invert`} />
                <h1 className={`text-white text-base`}>
                  {menu_data?.["menu"]?.["default"]?.["item"]?.[
                    "current_accounts"
                  ]?.["label"] ?? "Tekući računi"}
                </h1>
              </div>
              <div
                className={`flex items-center gap-3 hover:font-semibold cursor-pointer`}
              >
                <img src={Scoring} width={"20"} className={`invert`} />
                <h1 className={`text-white text-base`}>
                  {menu_data?.["menu"]?.["default"]?.["item"]?.["scoring"]?.[
                    "label"
                  ] ?? "Scoring"}
                </h1>
              </div>
              <div
                className={`flex items-center gap-3 hover:font-semibold cursor-pointer`}
              >
                <img src={Bilansi} width={"20"} className={``} />
                <h1 className={`text-white text-base`}>
                  {menu_data?.["menu"]?.["default"]?.["item"]?.["balances"]?.[
                    "label"
                  ] ?? "Bilansi"}
                </h1>
              </div>
              <div
                className={`flex items-center gap-3 hover:font-semibold cursor-pointer`}
              >
                <img src={Menice} width={"20"} className={`invert`} />
                <h1 className={`text-white text-base`}>
                  {menu_data?.["menu"]?.["default"]?.["item"]?.[
                    "bills_of_exchange"
                  ]?.["label"] ?? "Menice"}
                </h1>
              </div>
              <div
                className={`flex items-center gap-3 hover:font-semibold cursor-pointer`}
              >
                <img src={Izvrsenja} width={"20"} className={`invert`} />
                <h1 className={`text-white text-base`}>
                  {menu_data?.["menu"]?.["default"]?.["item"]?.["executions"]?.[
                    "label"
                  ] ?? "Izvršenja"}
                </h1>
              </div>
              <div
                className={`flex items-center gap-3 hover:font-semibold cursor-pointer`}
              >
                <img src={Izvrsenja} width={"20"} className={`invert`} />
                <h1 className={`text-white text-base`}>
                  {menu_data?.["menu"]?.["default"]?.["item"]?.["trials"]?.[
                    "label"
                  ] ?? "Suđenja"}
                </h1>
              </div>
              <div
                className={`flex items-center gap-3 hover:font-semibold cursor-pointer`}
              >
                <img src={Zalog} width={"20"} className={`invert`} />
                <h1 className={`text-white text-base`}>
                  {menu_data?.["menu"]?.["default"]?.["item"]?.["pledges"]?.[
                    "label"
                  ] ?? "Zalozi"}
                </h1>
              </div>
              <div
                className={`flex items-center gap-3 hover:font-semibold cursor-pointer`}
              >
                <img src={Lizing} width={"20"} className={`invert`} />
                <h1 className={`text-white text-base`}>
                  {menu_data?.["menu"]?.["default"]?.["item"]?.["leasing"]?.[
                    "label"
                  ] ?? "Lizing"}
                </h1>
              </div>
              <div
                className={`flex items-center gap-3 hover:font-semibold cursor-pointer`}
              >
                <img src={VozniPark} width={"20"} className={`invert`} />
                <h1 className={`text-white text-base`}>
                  {menu_data?.["menu"]?.["default"]?.["item"]?.[
                    "vehicle_fleet"
                  ]?.["label"] ?? "Vozni park"}
                </h1>
              </div>
              <div
                className={`flex items-center gap-3 hover:font-semibold cursor-pointer`}
              >
                <img src={Uvoz} width={"20"} className={`invert`} />
                <h1 className={`text-white text-base`}>
                  {menu_data?.["menu"]?.["default"]?.["item"]?.["import"]?.[
                    "label"
                  ] ?? "Uvoz"}
                </h1>
              </div>
              <div
                className={`flex items-center gap-3 hover:font-semibold cursor-pointer`}
              >
                <img src={Izvoz} width={"20"} className={`invert`} />
                <h1 className={`text-white text-base`}>
                  {menu_data?.["menu"]?.["default"]?.["item"]?.["export"]?.[
                    "label"
                  ] ?? "Izvoz"}
                </h1>
              </div>
              <div
                className={`flex items-center gap-3 hover:font-semibold cursor-pointer`}
              >
                <img src={Nekretnine} width={"20"} className={`invert`} />
                <h1 className={`text-white text-base`}>
                  {menu_data?.["menu"]?.["default"]?.["item"]?.[
                    "real_estate"
                  ]?.["label"] ?? "Nekretnine"}
                </h1>
              </div>
              <div
                className={`flex items-center gap-3 hover:font-semibold cursor-pointer`}
              >
                <img src={Dozvole} width={"20"} className={`invert`} />
                <h1 className={`text-white text-base`}>
                  {menu_data?.["menu"]?.["default"]?.["item"]?.[
                    "construction_permits"
                  ]?.["label"] ?? "Građevinske dozvole"}
                </h1>
              </div>
              <div
                className={`flex items-center gap-3 hover:font-semibold cursor-pointer`}
              >
                <img src={Zabelezbe} width={"20"} className={`invert`} />
                <h1 className={`text-white text-base`}>
                  {menu_data?.["menu"]?.["default"]?.["item"]?.["notes"]?.[
                    "label"
                  ] ?? "Zabeležbe"}
                </h1>
              </div>
              <div
                className={`flex items-center gap-3 hover:font-semibold cursor-pointer`}
              >
                <img src={Tenderi} width={"20"} className={`invert`} />
                <h1 className={`text-white text-base`}>
                  {menu_data?.["menu"]?.["default"]?.["item"]?.["tenders"]?.[
                    "label"
                  ] ?? "Tenderi"}
                </h1>
              </div>
              <div
                className={`flex items-center gap-3 hover:font-semibold cursor-pointer`}
              >
                <img src={Vesti} width={"20"} className={`invert`} />
                <h1 className={`text-white text-base`}>
                  {menu_data?.["menu"]?.["default"]?.["item"]?.["news"]?.[
                    "label"
                  ] ?? "Vesti"}
                </h1>
              </div>
              <div
                className={`flex items-center gap-3 hover:font-semibold cursor-pointer`}
              >
                <img src={Kontakti} width={"20"} className={`invert`} />
                <h1 className={`text-white text-base`}>
                  {menu_data?.["menu"]?.["default"]?.["item"]?.["contacts"]?.[
                    "label"
                  ] ?? "Kontakti"}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        onClick={() => setOpenModal({ open: false, message: "" })}
        className={
          openModal?.open
            ? "fixed top-0 left-0 bg-black/50 w-dvw h-dvh z-[500]"
            : "hidden"
        }
      />
      <div
        className={
          openModal?.open
            ? "fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl p-4 z-[501] max-w-[30rem] text-center"
            : "hidden"
        }
      >
        <p>{openModal?.message}</p>
        <div className={"flex items-center gap-5 mt-5 justify-center"}>
          <Button
            variant={"contained"}
            color={"error"}
            onClick={() => {
              setOpenModal({ open: false, message: "" });
            }}
          >
            {modal_translate_data?.["global"]?.["default"]?.["global"]?.[
              "word"
            ]?.["no"] ?? "Ne"}
          </Button>
          <Button
            variant={"contained"}
            color={"success"}
            onClick={() => {
              mutate();
            }}
          >
            {modal_translate_data?.["global"]?.["default"]?.["global"]?.[
              "word"
            ]?.["yes"] ?? "Da"}
          </Button>
        </div>
      </div> */}
    </>
  );
};

export default Sidebar;
