import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import CompanyDaughtersTable from "./CompanyDaughtersTable";
import CompanyDaughtersFilterModal from "./CompanyDaughtersFilterModal";
import {
  fetchCompanyDaughters,
  fetchCompanyDaughtersFilters,
} from "../data/dataAccess";

import "./CompanyDaughtersTable.css";

const CompanyDaughters = ({ companyId }) => {
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [appliedSearch, setAppliedSearch] = useState("");

  const [pendingFilter, setPendingFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [modalOpen, setModalOpen] = useState(false);

  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get("companyDaughtersPage")) || 1;

  const { data: daughtersData = [], isLoading: isLoadingDaughters } = useQuery({
    queryKey: [
      "companyDaughters",
      companyId,
      appliedFilters,
      appliedSearch,
      searchParams,
      page,
    ],
    queryFn: () =>
      fetchCompanyDaughters(
        companyId,
        appliedFilters,
        appliedSearch,
        searchParams,
        page
      ),
  });

  const {
    data: filtersData,
    isLoading: isLoadingFilters,
    refetch: refetchFilters,
  } = useQuery({
    queryKey: ["companyDaughtersFilters"],
    queryFn: fetchCompanyDaughtersFilters,
    enabled: false,
  });

  const formattedData = {
    pagination: daughtersData.pagination,
    items:
      daughtersData?.items?.map((item, index) => ({
        id: index + 1,
        cerka_naziv: item.cerka_naziv || "Nepoznato",
        cerka_status_naziv: item.cerka_status_naziv || "Nepoznato",
        cerka_procenat_vlasnistva: isNaN(
          parseFloat(item.cerka_procenat_vlasnistva)
        )
          ? "-"
          : parseFloat(item.cerka_procenat_vlasnistva).toFixed(2),
        cerka_scoring_ocena: item.cerka_scoring_ocena || "-",
        cerka_scoring_ocena_boja: item.cerka_scoring_ocena_boja || "default",
      })) || [],
  };

  const handleOpenModal = () => {
    setModalOpen(true);
    refetchFilters();
  };

  const handleApplyFilters = () => {
    const mappedFilters = [];

    if (pendingFilter) {
      mappedFilters.push({
        code: "type",
        value: {
          selected: [pendingFilter],
        },
      });
    }

    setAppliedFilters(mappedFilters);
    setAppliedSearch(searchTerm);
    setModalOpen(false);
  };

  const resetFilters = () => {
    setAppliedFilters([]);
    setAppliedSearch("");
    setPendingFilter("");
    setSearchTerm("");
  };

  const activeFiltersText = [
    appliedFilters.length > 0 &&
    filtersData?.find((f) => f.id === appliedFilters[0]?.value?.selected?.[0])
      ?.name
      ? `Udeo: ${
          filtersData.find((f) => f.id === appliedFilters[0].value.selected[0])
            ?.name
        }`
      : null,
    searchTerm ? `Naziv sadrži: "${searchTerm}"` : null,
  ]
    .filter(Boolean)
    .join(" • ");

  return (
    !!daughtersData?.items?.length && (
      <section className="company-daughters py-5">
        <CompanyDaughtersTable
          data={formattedData}
          isLoading={isLoadingDaughters}
          activeFiltersText={activeFiltersText}
          onResetFilters={resetFilters}
          onFilterClick={handleOpenModal}
        />

        <CompanyDaughtersFilterModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          filtersData={filtersData}
          pendingFilter={pendingFilter}
          setPendingFilter={setPendingFilter}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          onApply={handleApplyFilters}
          isLoading={isLoadingFilters}
        />
      </section>
    )
  );
};

export default CompanyDaughters;
